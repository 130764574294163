export class CheckPrivacyResponse {
  constructor(
    public Status: CheckPrivacyStatuses
  ) {}
}

export class ClientPrivacyDetails {
  public surname: string;
  public driversLicence: string;
  public streetName: string;
  public suburb: string;
  public postcode: string;
  public dateOfBirthDay: number;
  public dateOfBirthMonth: number;
  public dateOfBirthYear: number;

  constructor() {
    this.surname = null;
    this.driversLicence = null;
    this.streetName = null;
    this.suburb = null;
    this.postcode = null;
    this.dateOfBirthDay = null;
    this.dateOfBirthMonth = null;
    this.dateOfBirthYear = null;
  }
}

export enum CheckPrivacyStatuses {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  ActivationIdInvalid = 'ActivationIdInvalid',
  VerificationCodeInvalid = 'VerificationCodeInvalid'
}

export enum PrivacyCheckTypes {
  DriversLicence = 'DriversLicence',
  Address = 'Address',
  RequiresContact = 'RequiresContact',
}
