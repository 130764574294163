"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./timeout.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./timeout.component");
var i3 = require("../../brand/brand-details.service");
var styles_TimeoutComponent = [i0.styles];
var RenderType_TimeoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimeoutComponent, data: {} });
exports.RenderType_TimeoutComponent = RenderType_TimeoutComponent;
function View_TimeoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "panel panel-default"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "panel-heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "panel-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Activate your Online Account"])), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "panel-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We're sorry, we are unable to continue with your activation at this time."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["It looks like the server is taking to long to respond, this can be caused by either poor connectivity or an error with our servers."])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please try again later by following the link from your email to restart the activation process."])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["If this problem persists, please contact us on "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [["id", "link-contact"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "tel:", _co.brandDetails.phoneNumber, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "contact ", _co.brandDetails.shortName, ""); _ck(_v, 13, 0, currVal_0, currVal_1); var currVal_2 = _co.brandDetails.phoneNumber; _ck(_v, 14, 0, currVal_2); }); }
exports.View_TimeoutComponent_0 = View_TimeoutComponent_0;
function View_TimeoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_TimeoutComponent_0, RenderType_TimeoutComponent)), i1.ɵdid(1, 114688, null, 0, i2.TimeoutComponent, [i3.BrandDetailsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TimeoutComponent_Host_0 = View_TimeoutComponent_Host_0;
var TimeoutComponentNgFactory = i1.ɵccf("ng-component", i2.TimeoutComponent, View_TimeoutComponent_Host_0, {}, {}, []);
exports.TimeoutComponentNgFactory = TimeoutComponentNgFactory;
