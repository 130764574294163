import { Component } from '@angular/core';
import { BrandDetails } from '../../brand/brand-details.model';
import { BrandDetailsService } from '../../brand/brand-details.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.less' ]
})
export class FooterComponent {
  public brandDetails: BrandDetails;

  constructor(private brandDetailsService: BrandDetailsService) {
  }

  ngOnInit() {
    this.brandDetails = this.brandDetailsService.getBrandDetails();
  }
}
