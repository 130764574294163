"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var send_code_types_1 = require("./send-code.types");
var demo_api_service_1 = require("../../shared/demo-api-service");
var helpers_1 = require("../../helpers");
var DemoSendCodeService = /** @class */ (function () {
    function DemoSendCodeService(api, mobileNumberHandler) {
        this.api = api;
        this.mobileNumberHandler = mobileNumberHandler;
    }
    DemoSendCodeService.prototype.postSendSmsRequest = function (activationShortId, rawMobileNumber) {
        var result = this.api.sendCode(activationShortId, this.mobileNumberHandler.clean(rawMobileNumber));
        return rxjs_1.of(result, rxjs_1.asapScheduler);
    };
    DemoSendCodeService.prototype.getActivationStatus = function (activationShortId) {
        var status = this.api.getActivationStatus(activationShortId);
        return rxjs_1.of(status || send_code_types_1.ActivationStatuses.NotFound, rxjs_1.asapScheduler);
    };
    DemoSendCodeService.prototype.getFirstName = function (activationShortId) {
        var firstName = this.api.getFirstName(activationShortId);
        if (firstName) {
            return rxjs_1.of(firstName, rxjs_1.asapScheduler);
        }
        return rxjs_1.throwError(null, rxjs_1.asapScheduler);
    };
    return DemoSendCodeService;
}());
exports.DemoSendCodeService = DemoSendCodeService;
