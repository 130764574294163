import { Component } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { MatSnackBarRef, SimpleSnackBar, MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '../environments/environment';
import { FormatMobileNumberPipe } from './pipes';
import { DemoApiService } from './shared/demo-api-service';

@Component({
  selector: 'app',
  templateUrl: './demo-app.component.html',
  styleUrls: [ './app.component.less' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ FormatMobileNumberPipe ]
})
export class DemoAppComponent {
  public constructor(
    titleService: Title,
    api: DemoApiService,
    formatMobileNumber: FormatMobileNumberPipe,
    router: Router,
    snackBar: MatSnackBar) {

    titleService.setTitle(environment.appTitle);

    let mobileNumberSnackBar: MatSnackBarRef<SimpleSnackBar>;
    router.events.subscribe(e => {
        if (e instanceof NavigationEnd) {
          const mobileNumber = api.getPhoneNumber(e.url.slice(1));
          if (mobileNumber) {
            mobileNumberSnackBar = snackBar.open(
              `[DEMO] Enter mobile number ${formatMobileNumber.transform(mobileNumber)}`,
              'Copy',
              { verticalPosition: 'top' }
            );
            mobileNumberSnackBar.onAction()
              .subscribe(() => (navigator as any).clipboard.writeText(mobileNumber));
          } else {
            if (mobileNumberSnackBar) {
              mobileNumberSnackBar.dismiss();
            }
          }
        }
      }
    );

  }
}
