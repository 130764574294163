import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ClientGoalsRequest, ClientGoalsResponse } from './client-goals.types';
import { environment } from '../../../environments/environment';

@Injectable()
export class ClientGoalsService {
    constructor (private http: HttpClient) {}

    postGoal(activationShortId: string,
             clientGoalsRequest: ClientGoalsRequest): Observable<ClientGoalsResponse> {
        return this.http.post<ClientGoalsResponse>(`${environment.apiUrl}client-goals/${activationShortId}`, clientGoalsRequest);
    }
}
