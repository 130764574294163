<div class="panel panel-default">
  <div class="panel-heading">
    <h1 class="panel-title">Confirmation Required</h1>
  </div>
  <div class="panel-body">

    <p>You’re almost there! To protect your account, we just need a little more information to confirm it's really you.</p>
    <p>Please contact us on <a id="link-contact" href="tel:{{ brandDetails.phoneNumber }}" title="contact {{ brandDetails.shortName }}"> {{ brandDetails.phoneNumber }}</a> to complete. You may be required to supply some personal details to confirm your identity.</p>

  </div>
</div>
