"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var mobile_number_helper_1 = require("../../helpers/mobile-number.helper");
var environment_1 = require("../../../environments/environment");
var SendCodeService = /** @class */ (function () {
    function SendCodeService(http, mobileNumber) {
        this.http = http;
        this.mobileNumber = mobileNumber;
    }
    SendCodeService.prototype.postSendSmsRequest = function (activationShortId, rawMobileNumber) {
        var cleanMobileNumber = this.mobileNumber.clean(rawMobileNumber);
        return this.http.post(environment_1.environment.apiUrl + "verify-mobile/" + activationShortId + "/send-code", {
            mobileNumber: cleanMobileNumber,
            isResend: false,
        });
    };
    SendCodeService.prototype.getActivationStatus = function (activationShortId) {
        return this.http.get(environment_1.environment.apiUrl + "verify-mobile/" + activationShortId + "/activation-status");
    };
    SendCodeService.prototype.getFirstName = function (activationShortId) {
        return this.http.get(environment_1.environment.apiUrl + "verify-mobile/" + activationShortId + "/first-name", { responseType: 'text' });
    };
    return SendCodeService;
}());
exports.SendCodeService = SendCodeService;
