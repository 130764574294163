import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { pluck } from 'rxjs/operators';

import { ActivationStore } from '../../store/activation-store.service';
import { HeapService } from '../../shared/index';
import { fadeInOut } from '../../animations/fade-in-out.animation';

import { SendCodeService } from './send-code.service';
import { ActivationStatuses } from './send-code.types';
import { SendSmsResults } from './send-code.types';
import { BrandDetailsService } from '../../brand/brand-details.service';
import { BrandDetails } from '../../brand/brand-details.model';

export enum DisplayState {
  Pending = 1,
  Completed = 2,
  IncorrectMobile = 3
}

@Component({
  templateUrl: './send-code.component.html',
  styleUrls: [ './send-code.component.less' ],
  providers: [ ActivationStore, BrandDetailsService ],
  animations: [
    [ fadeInOut ]
  ]
})

export class SendCodeComponent implements OnInit, OnDestroy {

    private paramsSub: any;
    public isDataLoaded: boolean;
    public displayState: DisplayState;
    public brandDetails: BrandDetails;


    private guidPattern: RegExp;
    private activationId: string;
    private firstName: string;

    public formSubmitted: boolean;
    private enteredMobileNumber: string;
    private activationStatuses = ActivationStatuses;

    private onlineAnalyticsTracking: string;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private activationStore: ActivationStore,
                private heapService: HeapService,
                private sendCodeService: SendCodeService,
                private changeDetector: ChangeDetectorRef,
                private brandDetailsServie: BrandDetailsService) {

        this.activationId = null;
        this.guidPattern = /^[a-zA-Z0-9_-]{22}$/;

        this.activationStore.changes
            .pipe(pluck('activationId'))
            .subscribe((activationId: string) => this.activationId = activationId);

        this.isDataLoaded = false;
        this.displayState = DisplayState.Pending;

        this.formSubmitted = false;
        this.onlineAnalyticsTracking = '?utm_source=liberty&utm_medium=website&utm_content=existingbtn&utm_campaign=activate';
    }

    ngOnInit() {
      this.extractActivationIdFromRoute();
      this.brandDetails = this.brandDetailsServie.getBrandDetails();
    }

    ngOnDestroy() {
      if (this.paramsSub) {
        this.paramsSub.unsubscribe();
      }
    }

    private extractActivationIdFromRoute(): void {
      let _routeActivationId;

      this.paramsSub = this.route.params.subscribe(params => {
        _routeActivationId = params['id'];
      });

      // valid GUID
      if (this.guidPattern.test(_routeActivationId)) {
        const currentState = this.activationStore.getState();
        this.activationStore.setState(Object.assign({}, currentState, { activationId: _routeActivationId }));
        this.getActivationStatus();
      } else {
        this.addEventToHeap('Activation - Invalid GUID');
        this.router.navigate(['/register']);
      }
    }

    private getActivationStatus(): void {

      this.sendCodeService.getActivationStatus(this.activationId)
        .subscribe(activationStatus => {
          switch (activationStatus) {
            case this.activationStatuses.Pending:
              this.getCustomerDetails();
              this.addEventToHeap('Activation - Started');
              break;

            case this.activationStatuses.Completed:
              this.addEventToHeap('Activation - Account - Already Completed');
              this.displayState = DisplayState.Completed;
              break;

            case this.activationStatuses.Locked:
              this.addEventToHeap('Activation - Account - Locked');
              this.router.navigate(['/error']);
              break;

            case this.activationStatuses.RequiresContact:
              this.addEventToHeap('Activation - Account - Requires Contact');
              this.router.navigate(['/contact']);
              break;

            case this.activationStatuses.NotFound:
            default:
              this.addEventToHeap('Activation - Account - Not Found');
              this.router.navigate(['/error']);
              break;
          }

          this.isDataLoaded = true;
        },
        error => {
          this.addEventToHeap('Server Timeout');
          this.router.navigate(['/timeout']);
        });

    }

    private getCustomerDetails(): void {
      this.sendCodeService.getFirstName(this.activationId)
        .subscribe(firstNameResult => {
          this.firstName = firstNameResult;

          const currentState = this.activationStore.getState();
          this.activationStore.setState(Object.assign({}, currentState, {
            firstName: this.firstName
          }));
        },
        error => {
          this.addEventToHeap('Server Timeout');
          this.router.navigate(['/timeout']);
        });
    }

    public redirectToOnlineSite(): void {
      this.router.ngOnDestroy();
      window.location.href = this.brandDetails.onlineSiteUrl + this.onlineAnalyticsTracking;
    }

    public onSubmit(): void {
      this.formSubmitted = true;
      this.changeDetector.detectChanges();

      this.sendCodeService.postSendSmsRequest(this.activationId, this.enteredMobileNumber)
        .subscribe(sendSmsResult => {

          switch (sendSmsResult) {
            case SendSmsResults.Success:
              const currentState = this.activationStore.getState();
              this.activationStore.setState(Object.assign({}, currentState, {
                  mobileNumber: this.enteredMobileNumber
                }));
              this.router.navigate(['/verify']);
              break;

            case SendSmsResults.IncorrectMobileNumber:
              this.displayState = DisplayState.IncorrectMobile;
              this.addEventToHeap('Activation - Account - Different Mobile');
              this.formSubmitted = false;
              break;

            default:
              this.addEventToHeap('Server Error');
              this.router.navigate(['/error']);
              break;
          }

        },
        error => {
          this.addEventToHeap('Server Timeout');
          this.router.navigate(['/timeout']);
        });
    }

    public cancelConfirm(): void {
      this.displayState = DisplayState.Pending;
    }

    private addEventToHeap(event: string): void {
      this.heapService.addEvent(event);
    }

 }
