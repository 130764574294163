"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PasteStopPropagation = /** @class */ (function () {
    function PasteStopPropagation() {
    }
    PasteStopPropagation.prototype.onPaste = function (event) {
        event.preventDefault();
    };
    return PasteStopPropagation;
}());
exports.PasteStopPropagation = PasteStopPropagation;
