import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[paste-stop-propagation]'
})

export class PasteStopPropagation {
  @HostListener('paste', ['$event'])
  onPaste(event: any) {
    event.preventDefault();
  }
}
