"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../../environments/environment");
function getHotjar() {
    return hj;
}
var HotjarService = /** @class */ (function () {
    function HotjarService(document) {
        this.document = document;
    }
    Object.defineProperty(HotjarService.prototype, "hotjar", {
        get: function () {
            return getHotjar();
        },
        enumerable: true,
        configurable: true
    });
    HotjarService.prototype.setHotJarConfig = function () {
        // check required
        if (!environment_1.environment.hotjar.include && !environment_1.environment.hotjar.hotjarId) {
            return;
        }
        // check loaded
        if (this.isHotjarLoaded()) {
            return;
        }
        this.loadHotjar(environment_1.environment.hotjar.hotjarId);
    };
    HotjarService.prototype.isHotjarLoaded = function () {
        try {
            return hj.scriptLoaded;
        }
        catch (e) {
            if (environment_1.environment.debugMode)
                console.log('hotjar is offline / not loaded');
        }
    };
    HotjarService.prototype.addTag = function (tag) {
        var tags = [tag];
        this.addTags(tags);
    };
    HotjarService.prototype.addTags = function (tags) {
        this.sendTagToHotjar(tags);
        this.logToConsole(tags);
    };
    HotjarService.prototype.loadHotjar = function (hotjarId) {
        try {
            var load = function (h, o, t, j, a, r) {
                h.hj = h.hj || (function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    (h.hj.q = h.hj.q || []).push(args);
                });
                h._hjSettings = { hjid: hotjarId, hjsv: 6 };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script');
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            };
            load(window, this.document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=', null, null);
            if (environment_1.environment.debugMode) {
                console.log('[ Hotjar ] Has been configured');
            }
        }
        catch (ex) {
            if (environment_1.environment.debugMode) {
                console.error('[ Hotjar ] Error configuring');
                console.error(ex);
            }
        }
    };
    HotjarService.prototype.sendTagToHotjar = function (tags) {
        if (environment_1.environment.production && this.isHotjarLoaded())
            hj('tagRecording', tags);
    };
    HotjarService.prototype.logToConsole = function (tags) {
        if (environment_1.environment.debugMode)
            console.log('hotjar tag: ' + tags);
    };
    return HotjarService;
}());
exports.HotjarService = HotjarService;
var HotjarServiceStub = /** @class */ (function () {
    function HotjarServiceStub() {
    }
    HotjarServiceStub.prototype.hotjar = function () { };
    ;
    HotjarServiceStub.prototype.setHotJarConfig = function () { };
    ;
    HotjarServiceStub.prototype.isHotjarLoaded = function () { };
    ;
    HotjarServiceStub.prototype.addTag = function (tag) { };
    ;
    HotjarServiceStub.prototype.addTags = function (tags) { };
    ;
    return HotjarServiceStub;
}());
exports.HotjarServiceStub = HotjarServiceStub;
