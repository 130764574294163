"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./header.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory");
var i3 = require("@angular/material/toolbar");
var i4 = require("@angular/cdk/platform");
var i5 = require("@angular/common");
var i6 = require("@angular/router");
var i7 = require("./demo-header.component");
var styles_DemoHeaderComponent = [i0.styles];
var RenderType_DemoHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DemoHeaderComponent, data: {} });
exports.RenderType_DemoHeaderComponent = RenderType_DemoHeaderComponent;
function View_DemoHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "header"], ["id", "js-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "mat-toolbar", [["class", "mat-toolbar"], ["color", "accent"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(2, 4243456, null, 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], { color: [0, "color"] }, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(4, 0, null, 1, 6, "mat-toolbar-row", [["class", "mat-toolbar-row"]], null, null, null, null, null)), i1.ɵdid(5, 16384, [[1, 4]], 0, i3.MatToolbarRow, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "a", [["style", "color: white"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(9, 1), (_l()(), i1.ɵted(-1, null, ["Demo"])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "header-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "img", [["alt", "liberty financial"], ["class", "img-responsive"], ["src", "../../../assets/images/logo-liberty-financial.png"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 2, 0, currVal_2); var currVal_5 = _ck(_v, 9, 0, "/"); _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 2)._toolbarRows.length === 0); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 8).target; var currVal_4 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_3, currVal_4); }); }
exports.View_DemoHeaderComponent_0 = View_DemoHeaderComponent_0;
function View_DemoHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "demo-header", [], null, null, null, View_DemoHeaderComponent_0, RenderType_DemoHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i7.DemoHeaderComponent, [], null, null)], null, null); }
exports.View_DemoHeaderComponent_Host_0 = View_DemoHeaderComponent_Host_0;
var DemoHeaderComponentNgFactory = i1.ɵccf("demo-header", i7.DemoHeaderComponent, View_DemoHeaderComponent_Host_0, {}, {}, []);
exports.DemoHeaderComponentNgFactory = DemoHeaderComponentNgFactory;
