export class ValidateVerificationCodeResult {
    constructor(
        public ValidateResult: ValidateResults
    ) {}
}

export enum ValidateResults {
    ValidVerificationCode = 'ValidVerificationCode',
    InvalidVerificationCode = 'InvalidVerificationCode',
    InvalidActivationId = 'InvalidActivationId',
    PrivacyCheckRequired = 'PrivacyCheckRequired',
}

export enum SendSmsResults {
    Success = 'Success',
    IncorrectMobileNumber = 'IncorrectMobileNumber',
    Error = 'Error',
}
