import { Injectable } from '@angular/core';

import { of, Observable, asapScheduler } from 'rxjs';

import { SetupAccountService } from './setup-account.service';
import { ActivateAccountResponse, SecurityQuestions, SecurityQuestionSelection } from './setup-account.types';
import { DemoApiService } from '../../shared/demo-api-service';

type ISetupAccountService = { [P in keyof SetupAccountService]: SetupAccountService[P] };

@Injectable()
export class DemoSetupAccountService implements ISetupAccountService {
  constructor(private api: DemoApiService) {
  }

  activateIdentityAccount(
    activationShortId: string,
    verificationCode: string,
    password: string,
    securityQuestion: SecurityQuestions,
    securityAnswer: string): Observable<ActivateAccountResponse> {
      const response = this.api.activateIdentityAccount(
        activationShortId,
        verificationCode,
        password,
        securityQuestion,
        securityAnswer);
        return of(response, asapScheduler);
  }

  getSecurityQuestions(): Observable<SecurityQuestionSelection[]> {
    return of(this.api.getSecurityQuestions(), asapScheduler);
  }
}
