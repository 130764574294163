import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SendSmsResults } from './send-code.types';
import { ActivationStatuses } from './send-code.types';
import { MobileNumberHandler } from '../../helpers/mobile-number.helper';
import { environment } from '../../../environments/environment';

@Injectable()
export class SendCodeService {

    constructor (private http: HttpClient,
                 private mobileNumber: MobileNumberHandler) {}

    postSendSmsRequest(activationShortId: string, rawMobileNumber: string): Observable<SendSmsResults> {
        const cleanMobileNumber = this.mobileNumber.clean(rawMobileNumber);
        return this.http.post<SendSmsResults>(
            `${environment.apiUrl}verify-mobile/${activationShortId}/send-code`,
            {
                mobileNumber: cleanMobileNumber,
                isResend: false,
            }
        );
    }

    getActivationStatus(activationShortId: string): Observable<ActivationStatuses> {
        return this.http.get<ActivationStatuses>(
            `${environment.apiUrl}verify-mobile/${activationShortId}/activation-status`
        );
    }

    getFirstName(activationShortId: string): Observable<string> {
        return this.http.get(
            `${environment.apiUrl}verify-mobile/${activationShortId}/first-name`,
            { responseType: 'text' }
        );
    }
}
