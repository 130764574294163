<div class="panel panel-default">
  <div class="panel-heading">
    <h1 class="panel-title">Demo site</h1>
  </div>
  <div class="panel-body">
    <p>
      This is a demo version of <a href="https://{{ brandDetails.domainName }}">{{ brandDetails.domainName }}</a>, where customers
      can register or activate their online accounts.
    </p>
    <div>
      <mat-form-field>
        <mat-select [(value)]="action">
          <mat-option value="Register">Register</mat-option>
          <mat-option value="Activate">Activate</mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container [ngSwitch]="action">
        <mat-form-field *ngSwitchCase="'Register'">
          <mat-label>Outcome</mat-label>
          <mat-select [(value)]="registerOutcome">
            <mat-option value="Registered">Success</mat-option>
            <mat-option value="MoreDetailsRequired">More details required</mat-option>
            <mat-option value="OnlineAccountAlreadyExists">Online account already exists</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngSwitchCase="'Activate'">
          <mat-label>Privacy check</mat-label>
          <mat-select [(value)]="privacyCheck">
            <mat-option value="None">None</mat-option>
            <mat-option value="DriversLicence">Drivers licence</mat-option>
            <mat-option value="Address">Address</mat-option>
            <mat-option value="RequiresContact">Requires contact</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <button mat-flat-button (click)="go()">Go</button>
      <button mat-button (click)="reset()" *ngIf="canReset$ | async">Reset demo</button>
    </div>
  </div>
</div>
