import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ActivateAccountResponse, SecurityQuestions, SecurityQuestionSelection } from './setup-account.types';
import { environment } from '../../../environments/environment';

@Injectable()
export class SetupAccountService {
    constructor(private http: HttpClient) { }

    activateIdentityAccount(
        activationShortId: string,
        verificationCode: string,
        password: string,
        securityQuestion: SecurityQuestions,
        securityAnswer: string
    ): Observable<ActivateAccountResponse> {
        return this.http.post<ActivateAccountResponse>(
            `${environment.apiUrl}activate-account/b2c/${activationShortId}`,
            {
                verificationCode,
                password,
                securityQuestion,
                securityAnswer
            }
        );
    }

    getSecurityQuestions(): Observable<SecurityQuestionSelection[]> {
        return this.http.get<SecurityQuestionSelection[]>(
            `${environment.apiUrl}activate-account/security-questions`
        );
    }
}
