import { AbstractControl, ValidatorFn } from '@angular/forms';

export function NoWhiteSpaceValidator(): ValidatorFn {

  return (c: AbstractControl): { [key: string]: any } => {

    let isWhitespace = (c.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'noWhiteSpace': 'value is only whitespace' };

  };
}
