﻿import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { pluck } from 'rxjs/operators';
import { BrandDetails } from '../../brand/brand-details.model';
import { BrandDetailsService } from '../../brand/brand-details.service';

import { ActivationStore } from '../../store/activation-store.service';
import { ClientGoalsService } from './client-goals.service';
import { ClientGoalsRequest, ClientGoalsResponse } from './client-goals.types';

@Component({
  templateUrl: './client-goals.component.html',
  styleUrls: [ './client-goals.component.less' ],
  providers: [ActivationStore]
})
export class ClientGoalsComponent implements OnInit {

  public activationId: string;
  public verificationCode: string;
  public formSubmitted: boolean;
  public enteredGoal: string;
  public brandDetails: BrandDetails;

  constructor(private router: Router,
              private activationStore: ActivationStore,
              private clientGoalsService: ClientGoalsService,
              private changeDetector: ChangeDetectorRef,
              private brandDetailsService: BrandDetailsService) {

    this.activationStore.changes
      .pipe(pluck('activationId'))
      .subscribe((activationId: string) => this.activationId = activationId);

    this.activationStore.changes
      .pipe(pluck('verificationCode'))
      .subscribe((verificationCode: string) => this.verificationCode = verificationCode);

    this.enteredGoal = '';
    this.formSubmitted = false;
  }

  ngOnInit() {

    if (!this.activationId || !this.verificationCode) {
      this.router.navigate(['/error']);
      };
    this.brandDetails = this.brandDetailsService.getBrandDetails();
  }

  onSubmit() {
    this.formSubmitted = true;
    this.changeDetector.detectChanges();

    let clientGoalsRequest = new ClientGoalsRequest(this.verificationCode, this.enteredGoal);

    this.clientGoalsService.postGoal(this.activationId, clientGoalsRequest)
      .subscribe((clientGoalsResponse: ClientGoalsResponse) => {
          console.log(clientGoalsResponse.Success);

          if (clientGoalsResponse.Success === true) {
            this.router.navigate(['/complete']);
          } else {
            this.router.navigate(['/error']);
          }
        },
        error => {
          this.router.navigate(['/timeout']);
        });
  }
}
