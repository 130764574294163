﻿export class ClientGoalsRequest {
    constructor(
        public verificationCode: string,
        public goal: string
    ) { }
}

export class ClientGoalsResponse {
    constructor(
        public Success: boolean
    ) {}
}
