"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../../environments/environment");
var applicationinsights_web_1 = require("@microsoft/applicationinsights-web");
var i0 = require("@angular/core");
var ApplicationInsightsService = /** @class */ (function () {
    function ApplicationInsightsService() {
        this.appInsights = null;
        this.appInsights = new applicationinsights_web_1.ApplicationInsights({
            config: {
                connectionString: environment_1.environment.applicationInsights.connectionString,
                enableAutoRouteTracking: true,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                autoTrackPageVisitTime: true,
            }
        });
    }
    ApplicationInsightsService.prototype.load = function () {
        if (environment_1.environment.applicationInsights.connectionString) {
            this.appInsights.loadAppInsights();
            this.appInsights.addTelemetryInitializer(function (envelope) {
                envelope.tags = envelope.tags || [];
                envelope.tags.push({ 'ai.cloud.role': 'Customer Onboarding UI' });
            });
            this.logTrace('app-insights-loaded'); // Initial trace will capture the user's details (i.e. Browser, True IP, App Version...)
        }
    };
    ApplicationInsightsService.prototype.logPageView = function (name, url) {
        this.appInsights.trackPageView({
            name: name,
            uri: url
        });
    };
    ApplicationInsightsService.prototype.logEvent = function (name, properties) {
        this.appInsights.trackEvent({ name: name }, properties);
    };
    ApplicationInsightsService.prototype.logMetric = function (name, average, properties) {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    };
    ApplicationInsightsService.prototype.logException = function (exception, customProperties, severityLevel) {
        if (severityLevel === void 0) { severityLevel = applicationinsights_web_1.SeverityLevel.Error; }
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel }, customProperties);
    };
    ApplicationInsightsService.prototype.logTrace = function (message, properties) {
        this.appInsights.trackTrace({ message: message }, properties);
    };
    ApplicationInsightsService.ngInjectableDef = i0.defineInjectable({ factory: function ApplicationInsightsService_Factory() { return new ApplicationInsightsService(); }, token: ApplicationInsightsService, providedIn: "root" });
    return ApplicationInsightsService;
}());
exports.ApplicationInsightsService = ApplicationInsightsService;
var ApplicationInsightsServiceStub = /** @class */ (function () {
    function ApplicationInsightsServiceStub() {
    }
    ApplicationInsightsServiceStub.prototype.load = function () { };
    ApplicationInsightsServiceStub.prototype.logPageView = function () { };
    ApplicationInsightsServiceStub.prototype.logEvent = function () { };
    ApplicationInsightsServiceStub.prototype.logMetric = function () { };
    ApplicationInsightsServiceStub.prototype.logException = function () { };
    ApplicationInsightsServiceStub.prototype.logTrace = function () { };
    return ApplicationInsightsServiceStub;
}());
exports.ApplicationInsightsServiceStub = ApplicationInsightsServiceStub;
