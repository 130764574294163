import {Component, OnChanges, Input, SimpleChange} from '@angular/core';

@Component({
  selector: 'password-strength-bar',
  templateUrl: './password-strength-bar.component.html',
  styleUrls: [ './password-strength-bar.component.less' ]
})

export class PasswordStrengthBar implements OnChanges {

  // input field to check
  @Input() passwordToCheck: string;

  // label text
  @Input() showStrengthLabel: boolean;
  @Input() strengthLabel: string;

  // strength bars
  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;
  bar4: string;

  // strength bar colours
  private barDefaultColour = '#fff';
  private barStrengthColours = ['#d9534f', '#f0ad4e', '#fcd15e', '#8cc63e', '#68c63e'];

  // strength value
  private static measureStrength(p: string) {
      let force = 0;
      const regex = /[$-/:-?{-~!"^_`\[\]]/g;

      const lowerLetters = /[a-z]+/.test(p);
      const upperLetters = /[A-Z]+/.test(p);
      const numbers = /[0-9]+/.test(p);
      const symbols = regex.test(p);

      const flags = [lowerLetters, upperLetters, numbers, symbols];

      let passedMatches = 0;
      for (let flag of flags) {
          passedMatches += flag === true ? 1 : 0;
      }

      force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
      force += passedMatches * 10;

      // penality (short password)
      force = (p.length <= 6) ? Math.min(force, 10) : force;

      // penality (poor variety of characters)
      force = (passedMatches === 1) ? Math.min(force, 10) : force;
      force = (passedMatches === 2) ? Math.min(force, 20) : force;
      force = (passedMatches === 3) ? Math.min(force, 40) : force;

      return force;
  }

  // initialise defaults
  constructor() {
    this.showStrengthLabel = true;
    this.strengthLabel = 'Password Strength';
  }

  // identify index
  private getColor(s: number) {
      let idx = 0;
      if (s <= 10) {
          idx = 0;
      } else if (s <= 20) {
          idx = 1;
      } else if (s <= 30) {
          idx = 2;
      } else if (s <= 40) {
          idx = 3;
      } else {
          idx = 4;
      }
      return {
          idx: idx + 1,
          col: this.barStrengthColours[idx]
      };
  }

  // identify colour
  getStrengthIndexAndColor(password: string) {
      return this.getColor(PasswordStrengthBar.measureStrength(password));
  }

  // watcher
  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
      let password = changes['passwordToCheck'].currentValue;
      this.setBarColors(5, this.barDefaultColour);
      if (password) {
          let c = this.getStrengthIndexAndColor(password);
          this.setBarColors(c.idx, c.col);
      }
  }

  // colourise strength bar
  private setBarColors(count: number, col: string) {
      for (let n = 0; n < count; n++) {
          this['bar' + n] = col;
      }
  }
}
