import { AbstractControl, ValidatorFn } from '@angular/forms';

export type BasicMask = (string | RegExp)[];
export type Mask = BasicMask | ((val: string) => BasicMask);

export abstract class PhoneNumberHelper {

  constructor(public pattern: RegExp,
              public placeholder: string,
              public mask: BasicMask) { }

  /**
   * Remove all whitespace, dashes, and braces.
   */
  public clean(num: string | null): string | null {
    if (num)
      return num.replace(/[\s()\-]/g, '');
  }

  /**
   * Clean and test if a mobile number is valid.
   */
  public validate(num: string): boolean {
    return this.pattern.test(this.clean(num));
  }

  /**
   * Clean and format a mobile number. If invalid, return null.
   */
  public abstract format(num: string): string | null;

  public getValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return this.validate(control.value) ? null : { phoneNumberValidator: 'Phone number is invalid' };
    };
  }

}
