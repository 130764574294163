import { Component } from '@angular/core';

@Component({
  selector: 'demo-header',
  templateUrl: './demo-header.component.html',
  styleUrls: [ './header.component.less' ],
})
export class DemoHeaderComponent {
  constructor() {
  }
}
