import { NgModule } from '@angular/core';

// common
import { FooterComponent } from './common/footer/footer.component';

// directives
import { EqualValidator, NoWhiteSpaceDirective } from './directives/validators/index';
import { ClickStopPropagation, CutStopPropagation, CopyStopPropagation, PasteStopPropagation } from './directives/events/index';

// pipes
import { FormatMobileNumberPipe } from './pipes/index';

// components
import { PasswordStrengthBar } from './components/index';

// pages
import { SendCodeComponent } from './pages/send/send-code.component';
import { VerifyCodeComponent } from './pages/verify/verify-code.component';
import { ConfirmAccountComponent } from './pages/confirm/confirm-account.component';
import { SetupAccountComponent } from './pages/setup/setup-account.component';
import { ClientGoalsComponent } from './pages/client-goals/client-goals.component';
import { CompletedComponent } from './pages/completed/completed.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ErrorComponent } from './pages/error/error.component';
import { TimeoutComponent } from './pages/timeout/timeout.component';
import { RegisterComponent } from './pages/register/register.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    TextMaskModule,
    TooltipModule,
    RouterModule,
  ],
  declarations: [
    FooterComponent,
    SendCodeComponent,
    VerifyCodeComponent,
    ConfirmAccountComponent,
    SetupAccountComponent,
    ClientGoalsComponent,
    CompletedComponent,
    ContactComponent,
    ErrorComponent,
    TimeoutComponent,
    RegisterComponent,
    EqualValidator,
    NoWhiteSpaceDirective,
    ClickStopPropagation,
    CutStopPropagation,
    CopyStopPropagation,
    PasteStopPropagation,
    FormatMobileNumberPipe,
    PasswordStrengthBar
  ],
  exports: [
    FooterComponent,
    SendCodeComponent,
    VerifyCodeComponent,
    ConfirmAccountComponent,
    SetupAccountComponent,
    ClientGoalsComponent,
    CompletedComponent,
    ContactComponent,
    ErrorComponent,
    TimeoutComponent,
    RegisterComponent,
    EqualValidator,
    NoWhiteSpaceDirective,
    ClickStopPropagation,
    CutStopPropagation,
    CopyStopPropagation,
    PasteStopPropagation,
    FormatMobileNumberPipe,
    PasswordStrengthBar
  ]
})
export class SharedModule { }
