"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var brand_1 = require("../app/brand/brand");
exports.environment = {
    envName: 'demo',
    production: false,
    debugMode: false,
    demo: true,
    apiUrl: 'http://localhost:5001/Onboarding.API/',
    appTitle: '[DEMO] Liberty - Online Account Activation',
    resendShowAfterSeconds: 5,
    heapAnalytics: {
        include: false,
        heapId: null
    },
    hotjar: {
        include: false,
        hotjarId: null
    },
    sentry: {
        dsn: null
    },
    applicationInsights: {
        connectionString: 'InstrumentationKey=ac4eda0e-33cd-42db-a89a-7d3a584f332b;IngestionEndpoint=https://australiasoutheast-0.in.applicationinsights.azure.com/;LiveEndpoint=https://australiasoutheast.livediagnostics.monitor.azure.com/;ApplicationId=0311c02e-a617-4c43-8d2d-5e6ac42f7cea'
    },
    overrideBrand: brand_1.Brand.LibertyAu
};
