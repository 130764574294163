import { Directive } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

import { NoWhiteSpaceValidator } from './no-whitespace.validator';

@Directive({
  selector: '[noWhiteSpace]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: NoWhiteSpaceDirective, multi: true }
  ]
})

export class NoWhiteSpaceDirective implements Validator {

  private ValidatorResult = NoWhiteSpaceValidator();

  validate(c: AbstractControl): { [key: string]: any } {
    return this.ValidatorResult(c);
  }
}
