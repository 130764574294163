"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ClientGoalsRequest = /** @class */ (function () {
    function ClientGoalsRequest(verificationCode, goal) {
        this.verificationCode = verificationCode;
        this.goal = goal;
    }
    return ClientGoalsRequest;
}());
exports.ClientGoalsRequest = ClientGoalsRequest;
var ClientGoalsResponse = /** @class */ (function () {
    function ClientGoalsResponse(Success) {
        this.Success = Success;
    }
    return ClientGoalsResponse;
}());
exports.ClientGoalsResponse = ClientGoalsResponse;
