<div class="panel panel-default">
  <div class="panel-heading">
    <h1 class="panel-title">Verify your details</h1>
  </div>
  <div class="panel-body">

    <!-- activation / confirm -->
    <div *ngIf="privacyCheckTypeLoaded">

      <p>We just need to confirm a few last details {{firstName}}. Please ensure that your personal details match those provided during your application.</p>

      <!-- form / confirm -->
      <form id="form-confirm-account" class="form-confirm-account form-horizontal" role="form"
        (ngSubmit)="onSubmit()" #confirmAccountForm="ngForm" novalidate>

        <fieldset [disabled]="formSubmitted">

          <!-- input / surname -->
          <div class="form-group" [ngClass]="{ 'has-error' : surname.errors && surname.touched }">

            <label for="surname" class="control-label col-sm-5">Enter your surname</label>
            <div class="col-sm-6">

              <input type="text" name="surname" id="surname" class="form-control"
                [(ngModel)]="enteredPrivacyDetails.surname"
                #surname="ngModel"
                placeholder="your surname"
                autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                required aria-required="true"
                noWhiteSpace
                maxlength="100"
                pattern="^[a-zA-Z\s\-']{1,100}$">

              <div class="help-block" for="surname" *ngIf="surname.errors && surname.touched">
                <span *ngIf="surname.errors?.required || surname.errors?.noWhiteSpace">Please enter your surname</span>
                <span *ngIf="surname.errors?.pattern && !(surname.errors?.required || surname.errors?.noWhiteSpace)">Please enter a valid surname</span>
              </div>

            </div>
          </div>
          <!-- END: input / surname -->

          <!-- input / date of birth -->
          <div class="form-group" [ngClass]="{ 'has-error' : ((dobDay.errors && dobDay.touched) || (dobMonth.errors && dobMonth.touched) || (dobYear.errors && dobYear.touched)) }">
            <label for="dateOfBirth" class="control-label col-sm-5">Select your date of birth</label>

            <div class="col-sm-6">
              <div class="row">
                <div class="col-xs-3 col-sm-4">

                  <!-- dob / day -->
                  <div class="form-group-select">
                    <select name="dobDay" id="dobDay" class="form-control"
                      [(ngModel)]="enteredPrivacyDetails.dateOfBirthDay"
                      #dobDay="ngModel"
                      required aria-required="true"
                      data-hj-masked>
                      <option [ngValue]="null" selected disabled="disabled">Day</option>
                      <option *ngFor="let day of calendarDays | slice:0:numberOfDays; let i=index" [ngValue]="day">{{ day }}</option>
                    </select>
                  </div>
                  <!-- END: dob / day -->

                </div>
                <div class="col-xs-5 col-sm-4 offset-pl-0">

                  <!-- dob / month -->
                  <div class="form-group-select">
                    <select name="dobMonth" id="dobMonth" class="form-control"
                      [(ngModel)]="enteredPrivacyDetails.dateOfBirthMonth"
                      #dobMonth="ngModel"
                      required aria-required="true"
                      data-hj-masked>
                      <option [ngValue]="null" selected disabled="disabled">Month</option>
                      <option *ngFor="let month of calendarMonths" [ngValue]="month.id">{{ month.name }}</option>
                    </select>
                  </div>
                  <!-- END: dob / month -->

                </div>
                <div class="col-xs-4 offset-pl-0">

                  <!-- dob / year -->
                  <div class="form-group-select">
                    <select name="dobYear" id="dobYear" class="form-control"
                      [(ngModel)]="enteredPrivacyDetails.dateOfBirthYear"
                      #dobYear="ngModel"
                      required aria-required="true"
                      data-hj-masked>
                      <option [ngValue]="null" selected disabled="disabled">Year</option>
                      <option *ngFor="let year of calendarYears" [ngValue]="year">{{ year }}</option>
                    </select>
                  </div>
                  <!-- END: dob / year -->

                </div>
              </div>

              <!-- dob / help messages -->
              <div class="help-block" *ngIf="dobDay.errors || dobMonth.errors || dobYear.errors">
                <span *ngIf="dobDay.errors?.required || dobMonth.errors?.required || dobYear.errors?.required">Please select your date of birth</span>
              </div>
              <!-- END: dob / help messages -->

            </div>
          </div>
          <!-- END: input / date of birth -->

          <!-- input / drivers licence -->
          <div *ngIf="hasDriversLicence">
            <div class="form-group" [ngClass]="{ 'has-error' : driversLicence.errors && driversLicence.touched }">
              <label for="driversLicence" class="control-label col-sm-5">Enter your driver’s licence</label>
              <div class="col-sm-6">

                <input type="text" name="driversLicence" id="driversLicence" class="form-control"
                  [(ngModel)]="enteredPrivacyDetails.driversLicence"
                  #driversLicence="ngModel"
                  placeholder="your licence number"
                  autocomplete="off" autocorrect="off" autocapitalize="off"
                  required aria-required="true"
                  noWhiteSpace
                  minlength="6"
                  maxlength="9"
                  pattern="^[a-zA-Z0-9\-]{6,9}$">

                <div class="help-block" for="driversLicence" *ngIf="driversLicence.errors && driversLicence.touched">
                  <span *ngIf="driversLicence.errors?.required || driversLicence.errors?.noWhiteSpace">Please enter your licence number</span>
                  <span *ngIf="driversLicence.errors?.pattern && !(driversLicence.errors?.required || driversLicence.errors?.noWhiteSpace)">Please enter a valid licence</span>
                </div>

              </div>
            </div>
          </div>
          <!-- END: input / drivers licence -->

          <!-- no licence / address required -->
          <div *ngIf="!hasDriversLicence">

            <!-- input / street -->
            <div class="form-group" [ngClass]="{ 'has-error' : streetName.errors && streetName.touched }">

              <label for="streetName" class="control-label col-sm-5">Enter your street name</label>
              <div class="col-sm-6">

                <input type="text" name="streetName" id="streetName" class="form-control"
                  [(ngModel)]="enteredPrivacyDetails.streetName"
                  #streetName="ngModel"
                  placeholder="your street name"
                  autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                  required aria-required="true"
                  noWhiteSpace
                  maxlength="100"
                  pattern="^[a-zA-Z\s\-']{1,100}$">

                <div class="help-block" for="streetName" *ngIf="streetName.errors && streetName.touched">
                  <span *ngIf="streetName.errors?.required || streetName.errors?.noWhiteSpace">Please enter your street name</span>
                  <span *ngIf="streetName.errors?.pattern && !(streetName.errors?.required || streetName.errors?.noWhiteSpace)">Please enter a valid street name</span>
                </div>

              </div>
            </div>
            <!-- END: input / street -->

            <!-- input / suburb -->
            <div class="form-group" [ngClass]="{ 'has-error' : suburb.errors && suburb.touched }">

              <label for="suburb" class="control-label col-sm-5">Enter your suburb</label>
              <div class="col-sm-6">

                <input type="text" name="suburb" id="suburb" class="form-control"
                  [(ngModel)]="enteredPrivacyDetails.suburb"
                  #suburb="ngModel"
                  placeholder="your suburb"
                  autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                  required aria-required="true"
                  noWhiteSpace
                  maxlength="100"
                  pattern="^[a-zA-Z\s\-']{1,100}$">

                <div class="help-block" for="suburb" *ngIf="suburb.errors && suburb.touched">
                  <span *ngIf="suburb.errors?.required || suburb.errors?.noWhiteSpace">Please enter your suburb</span>
                  <span *ngIf="suburb.errors?.pattern && !(suburb.errors?.required || suburb.errors?.noWhiteSpace)">Please enter a valid suburb</span>
                </div>

              </div>
            </div>
            <!-- END: input / suburb -->

          </div>
          <!-- END: no licence / address required -->

          <!-- input / postcode -->
          <div class="form-group" [ngClass]="{ 'has-error' : postcode.errors && postcode.touched }">
            <label for="postcode" class="control-label col-sm-5">Enter your postcode</label>
            <div class="col-sm-6">

              <input type="text" name="postcode" id="postcode" class="form-control"
                [(ngModel)]="enteredPrivacyDetails.postcode"
                #postcode="ngModel"
                placeholder="your postcode"
                autocomplete="off" autocorrect="off" autocapitalize="off"
                required aria-required="true"
                noWhiteSpace
                maxlength="4"
                pattern="^[0-9]{4}$">

              <div class="help-block" for="postcode" *ngIf="postcode.errors && postcode.touched">
                <span *ngIf="postcode.errors?.required || postcode.errors?.noWhiteSpace">Please enter your postcode</span>
                <span *ngIf="postcode.errors?.pattern && !(postcode.errors?.required || postcode.errors?.noWhiteSpace)">Please enter a valid postcode</span>
              </div>

            </div>
          </div>
          <!-- END: input / postcode -->

          <!-- btn / verify -->
          <div class="form-group">
            <div class="col-sm-6 col-sm-offset-5">
              <button type="submit" id="form-btn-submit" class="btn btn-block btn-primary fx-fade-color"
                [disabled]="!confirmAccountForm.valid"
                [ngClass]="{ 'loading' : formSubmitted }">
                  <span class="loader loader-spinner"></span>
                  <span class="btn-label">Verify Details</span>
              </button>
            </div>
          </div>
          <!-- END: btn / verify -->

        </fieldset>
      </form>
      <!-- END: form / confirm -->

    </div>
    <!-- END: activation / confirm -->

  </div>
</div>
