"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PhoneNumberHelper = /** @class */ (function () {
    function PhoneNumberHelper(pattern, placeholder, mask) {
        this.pattern = pattern;
        this.placeholder = placeholder;
        this.mask = mask;
    }
    /**
     * Remove all whitespace, dashes, and braces.
     */
    PhoneNumberHelper.prototype.clean = function (num) {
        if (num)
            return num.replace(/[\s()\-]/g, '');
    };
    /**
     * Clean and test if a mobile number is valid.
     */
    PhoneNumberHelper.prototype.validate = function (num) {
        return this.pattern.test(this.clean(num));
    };
    PhoneNumberHelper.prototype.getValidator = function () {
        var _this = this;
        return function (control) {
            return _this.validate(control.value) ? null : { phoneNumberValidator: 'Phone number is invalid' };
        };
    };
    return PhoneNumberHelper;
}());
exports.PhoneNumberHelper = PhoneNumberHelper;
