"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var demo_api_service_1 = require("../../shared/demo-api-service");
var helpers_1 = require("../../helpers");
var DemoVerifyCodeService = /** @class */ (function () {
    function DemoVerifyCodeService(api, mobileNumberHandler) {
        this.api = api;
        this.mobileNumberHandler = mobileNumberHandler;
    }
    DemoVerifyCodeService.prototype.validateVerificationCode = function (activationShortId, verificationCode) {
        var result = this.api.validateVerificationCode(activationShortId, verificationCode);
        return rxjs_1.of(result, rxjs_1.asapScheduler);
    };
    DemoVerifyCodeService.prototype.resendVerificationCode = function (activationShortId, rawMobileNumber) {
        var result = this.api.sendCode(activationShortId, this.mobileNumberHandler.clean(rawMobileNumber));
        return rxjs_1.of(result, rxjs_1.asapScheduler);
    };
    return DemoVerifyCodeService;
}());
exports.DemoVerifyCodeService = DemoVerifyCodeService;
