"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var snack_bar_1 = require("@angular/material/snack-bar");
var rxjs_1 = require("rxjs");
var send_code_types_1 = require("../pages/send/send-code.types");
var verify_code_types_1 = require("../pages/verify/verify-code.types");
var setup_account_types_1 = require("../pages/setup/setup-account.types");
var client_goals_types_1 = require("../pages/client-goals/client-goals.types");
var register_types_1 = require("../pages/register/register.types");
var confirm_account_types_1 = require("../pages/confirm/confirm-account.types");
var brand_details_service_1 = require("../brand/brand-details.service");
var brand_1 = require("../brand/brand");
var i0 = require("@angular/core");
var i1 = require("@angular/material/snack-bar");
var i2 = require("../brand/brand-details.service");
var deepEqual = require('deep-equal');
var DemoApiService = /** @class */ (function () {
    function DemoApiService(snackbar, brandDetailsService) {
        this.snackbar = snackbar;
        this.brandDetailsService = brandDetailsService;
        this.localStorageKey = 'demo_activations';
        this._isDirty$ = new rxjs_1.BehaviorSubject(false);
        this._registerOutcome = register_types_1.RegisterOutcomes.Registered;
        this.activations = JSON.parse(localStorage.getItem(this.localStorageKey)) || this.getDemoActivations();
        this.commit();
    }
    Object.defineProperty(DemoApiService.prototype, "isDirty$", {
        get: function () {
            return this._isDirty$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoApiService.prototype, "privacyCheck", {
        get: function () {
            return this._privacyCheck;
        },
        set: function (value) {
            this._privacyCheck = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoApiService.prototype, "registerOutcome", {
        get: function () {
            return this._registerOutcome;
        },
        set: function (value) {
            this._registerOutcome = value;
        },
        enumerable: true,
        configurable: true
    });
    DemoApiService.prototype.reset = function () {
        this.activations = this.getDemoActivations();
        this.commit();
    };
    DemoApiService.prototype.getDemoActivations = function () {
        var brand = this.brandDetailsService.getBrandDetails().brand;
        switch (brand) {
            case brand_1.Brand.LibertyAu:
                return {
                    'IXi1asfcrk65OVFoer66LA': {
                        firstName: 'Tim',
                        phoneNumber: '0431123456',
                        status: send_code_types_1.ActivationStatuses.Pending,
                        code: null,
                    }
                };
            case brand_1.Brand.LibFinNz:
                return {
                    'IXi1asfcrk65OVFoer66LA': {
                        firstName: 'Melinda',
                        phoneNumber: '02112345678',
                        status: send_code_types_1.ActivationStatuses.Pending,
                        code: null,
                    }
                };
        }
    };
    DemoApiService.prototype.getActivationStatus = function (id) {
        var activation = this.activations[id];
        if (activation) {
            return activation.status;
        }
        return null;
    };
    DemoApiService.prototype.getFirstName = function (id) {
        var activation = this.activations[id];
        if (activation) {
            return activation.firstName;
        }
        return null;
    };
    DemoApiService.prototype.getPhoneNumber = function (id) {
        var activation = this.activations[id];
        if (activation) {
            return activation.phoneNumber;
        }
        return null;
    };
    DemoApiService.prototype.validateVerificationCode = function (id, verificationCode) {
        var activation = this.activations[id];
        if (!activation) {
            return new verify_code_types_1.ValidateVerificationCodeResult(verify_code_types_1.ValidateResults.InvalidActivationId);
        }
        if (activation.code !== verificationCode) {
            return new verify_code_types_1.ValidateVerificationCodeResult(verify_code_types_1.ValidateResults.InvalidVerificationCode);
        }
        if (this.privacyCheck) {
            return new verify_code_types_1.ValidateVerificationCodeResult(verify_code_types_1.ValidateResults.PrivacyCheckRequired);
        }
        return new verify_code_types_1.ValidateVerificationCodeResult(verify_code_types_1.ValidateResults.ValidVerificationCode);
    };
    DemoApiService.prototype.sendCode = function (id, phoneNumber) {
        if (this.getActivationStatus(id) !== send_code_types_1.ActivationStatuses.Pending) {
            return send_code_types_1.SendSmsResults.Error;
        }
        var expectedPhoneNumber = this.getPhoneNumber(id);
        if (!expectedPhoneNumber) {
            return send_code_types_1.SendSmsResults.Error;
        }
        if (phoneNumber !== expectedPhoneNumber) {
            return send_code_types_1.SendSmsResults.IncorrectMobileNumber;
        }
        var code = this.getRandomInt(0, 1000000).toString().padStart(6, '0');
        this.snackbar.open("[DEMO] Your activation code is " + code, 'Copy', { verticalPosition: 'top' })
            .onAction()
            .subscribe(function () { return navigator.clipboard.writeText(code); });
        this.activations[id].code = code;
        this.commit();
        return send_code_types_1.SendSmsResults.Success;
    };
    DemoApiService.prototype.postRegister = function (registerDetails) {
        return new register_types_1.RegisterResponse(this.registerOutcome);
    };
    DemoApiService.prototype.activateIdentityAccount = function (id, verificationCode, password, securityQuestion, securityAnswer) {
        if (this.getActivationStatus(id) !== send_code_types_1.ActivationStatuses.Pending) {
            return new setup_account_types_1.ActivateAccountResponse(setup_account_types_1.ActivateAccountResponseStatuses.ActivationIdInvalid, null);
        }
        var activation = this.activations[id];
        if (activation.code !== verificationCode) {
            return new setup_account_types_1.ActivateAccountResponse(setup_account_types_1.ActivateAccountResponseStatuses.VerificationCodeInvalid, null);
        }
        activation.status = send_code_types_1.ActivationStatuses.Completed;
        this.commit();
        var logonName = 'test@test.com';
        return new setup_account_types_1.ActivateAccountResponse(setup_account_types_1.ActivateAccountResponseStatuses.Activated, logonName);
    };
    DemoApiService.prototype.postGoal = function (id, request) {
        if (!this.activations[id]) {
            return new client_goals_types_1.ClientGoalsResponse(false);
        }
        return new client_goals_types_1.ClientGoalsResponse(true);
    };
    DemoApiService.prototype.postPrivacyCheck = function (id, verificationCode, privacyDetails) {
        return new confirm_account_types_1.CheckPrivacyResponse(confirm_account_types_1.CheckPrivacyStatuses.Accepted);
    };
    DemoApiService.prototype.getPrivateCheckType = function (id) {
        return this._privacyCheck;
    };
    DemoApiService.prototype.getSecurityQuestions = function () {
        return [
            {
                SecurityQuestion: setup_account_types_1.SecurityQuestions.MothersMaidenName,
                Text: 'Your Mother\'s Maiden Name?'
            },
            {
                SecurityQuestion: setup_account_types_1.SecurityQuestions.AnniversaryDate,
                Text: 'Your Anniversary Date [dd/mm/yy]?'
            },
            {
                SecurityQuestion: setup_account_types_1.SecurityQuestions.PetsName,
                Text: 'Your Pet\'s Name?'
            },
            {
                SecurityQuestion: setup_account_types_1.SecurityQuestions.BestFriendsName,
                Text: 'Your Best Friend’s Name?'
            },
            {
                SecurityQuestion: setup_account_types_1.SecurityQuestions.FavouriteMovie,
                Text: 'Your Favourite Movie?'
            },
            {
                SecurityQuestion: setup_account_types_1.SecurityQuestions.FavouriteColour,
                Text: 'Your Favourite Colour?'
            },
            {
                SecurityQuestion: setup_account_types_1.SecurityQuestions.FathersName,
                Text: 'Your Father\'s Name?'
            },
            {
                SecurityQuestion: setup_account_types_1.SecurityQuestions.CityOfBirth,
                Text: 'Your City of Birth?'
            },
        ];
    };
    DemoApiService.prototype.commit = function () {
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.activations));
        this._isDirty$.next(!deepEqual(this.getDemoActivations(), this.activations));
    };
    DemoApiService.prototype.getRandomInt = function (min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive
    };
    DemoApiService.ngInjectableDef = i0.defineInjectable({ factory: function DemoApiService_Factory() { return new DemoApiService(i0.inject(i1.MatSnackBar), i0.inject(i2.BrandDetailsService)); }, token: DemoApiService, providedIn: "root" });
    return DemoApiService;
}());
exports.DemoApiService = DemoApiService;
