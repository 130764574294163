import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { pluck } from 'rxjs/operators';

import { ActivationStore } from '../../store/activation-store.service';
import { BrandDetailsService } from '../../brand/brand-details.service';
import { BrandDetails } from '../../brand/brand-details.model';

@Component({
  templateUrl: './completed.component.html',
  styleUrls: [ './completed.component.less' ],
  providers: [ActivationStore]
})
export class CompletedComponent implements OnInit {

  public firstName: string;
  public logonName: string;
  public brandDetails: BrandDetails;
  private onlineAnalyticsTracking: string;

  constructor(private activationStore: ActivationStore,
              private router: Router,
              private brandDetailsService: BrandDetailsService, ) {

    this.activationStore.changes
      .pipe(pluck('firstName'))
      .subscribe((firstName: string) => this.firstName = firstName);

    this.activationStore.changes
      .pipe(pluck('logonName'))
      .subscribe((logonName: string) => this.logonName = logonName);

    this.onlineAnalyticsTracking = '?utm_source=liberty&utm_medium=website&utm_content=activatedbtn&utm_campaign=activate';

  }

  ngOnInit() {
    if (!this.logonName) {
      this.router.navigate(['/error']);
    }

    // get brand details per brand
    this.brandDetails = this.brandDetailsService.getBrandDetails();
  }

  redirectToOnlineSite() {
    this.router.ngOnDestroy();
    window.open(this.brandDetails.onlineSiteUrl + this.onlineAnalyticsTracking);
  }
}
