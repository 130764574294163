﻿import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { BrowserModule }  from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule }    from '@angular/common/http';
import { FormsModule }   from '@angular/forms';

import { DemoAppComponent } from './demo-app.component';
import { AppRoutingModule } from './demo-app-routing.module';

// 3rd party
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TextMaskModule } from 'angular2-text-mask';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

// common
import { DemoHeaderComponent } from './common/header/demo-header.component';

// stores
import { ActivationStore } from './store/activation-store.service';

// helpers
import { MobileNumberHandler } from './helpers/index';

// shared
import { HeapService, HotjarService, SentryService } from './shared/index';

// pages
import { DemoHomeComponent } from './pages/demo-home/demo-home.component';

// services
import { SendCodeService } from './pages/send/send-code.service';
import { VerifyCodeService } from './pages/verify/verify-code.service';
import { ConfirmAccountService } from './pages/confirm/confirm-account.service';
import { SetupAccountService } from './pages/setup/setup-account.service';
import { ClientGoalsService } from './pages/client-goals/client-goals.service';
import { RegisterService } from './pages/register/register.service';
import { BrandDetailsService } from './brand/brand-details.service';


// demo services
import { DemoSendCodeService } from './pages/send/demo-send-code.service';
import { DemoVerifyCodeService } from './pages/verify/demo-verify-code.service';
import { DemoConfirmAccountService } from './pages/confirm/demo-confirm-account.service';
import { DemoSetupAccountService } from './pages/setup/demo-setup-account.service';
import { DemoClientGoalsService } from './pages/client-goals/demo-client-goals.service';
import { DemoRegisterService } from './pages/register/demo-register.service';

// http interceptors
import { httpInterceptorProviders } from './http-interceptors';

// error handlers
import { getErrorHandler } from './error-handlers';
import { SharedModule } from './shared.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    TextMaskModule,
    TooltipModule.forRoot(),
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatSnackBarModule,
    MatToolbarModule,
  ],
  declarations: [
    DemoAppComponent,
    DemoHeaderComponent,
    DemoHomeComponent,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'en-AU'
    },
    SentryService,
    {
      provide: ErrorHandler,
      useFactory: getErrorHandler,
      deps: [SentryService]
    },
    ActivationStore,
    HeapService,
    HotjarService,
    { provide: SendCodeService, useClass: DemoSendCodeService },
    { provide: VerifyCodeService, useClass: DemoVerifyCodeService },
    { provide: ConfirmAccountService, useClass: DemoConfirmAccountService },
    { provide: SetupAccountService, useClass: DemoSetupAccountService },
    { provide: ClientGoalsService, useClass: DemoClientGoalsService },
    MobileNumberHandler,
    { provide: RegisterService, useClass: DemoRegisterService },
    { provide: BrandDetailsService, useClass: BrandDetailsService },
    httpInterceptorProviders,
  ],
  bootstrap: [ DemoAppComponent ]
})
export class DemoAppModule { }
