﻿<div class="panel panel-default">
  <div class="panel-heading">
    <h1 class="panel-title">Getting to know you</h1>
  </div>
  <div class="panel-body">
    
    <p>At {{brandDetails.shortName}} we like to dream big, what do your financial dreams look&nbsp;like?</p>

    <!-- form / goal -->
    <form id="form-client-goals" class="form-client-goals form-horizontal" role="form"
      (ngSubmit)="onSubmit()" #clientGoalsForm="ngForm" novalidate>

      <fieldset [disabled]="formSubmitted">

        <!-- textarea / goal -->
        <div class="form-group">

          <div class="col-xs-12">
            <textarea name="goal" id="goal" class="form-control no-resize"
                      [(ngModel)]="enteredGoal"
                      #goal="ngModel"
                      placeholder="your financial dreams..."
                      autocomplete="off" autocorrect="off" autocapitalize="off"
                      maxlength="500"
                      rows="5">
            </textarea>
          </div>
        </div>
        <!-- END: input / goal -->

        <!-- btn / complete -->
        <div class="form-group">
          <div class="col-sm-5 col-sm-offset-7">
            <button type="submit" id="form-btn-submit" class="btn btn-block btn-primary fx-fade-color"
              [ngClass]="{ 'loading' : formSubmitted }">
                <span class="loader loader-spinner"></span>
                <span class="btn-label">Complete Activation</span>
            </button>
          </div>
        </div>
        <!-- END: btn / complete -->

      </fieldset>
    </form>
    <!-- END: form / goal -->

  </div>
</div>
