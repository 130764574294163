"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var Sentry = require("@sentry/browser");
var SentryErrorHandler = /** @class */ (function () {
    function SentryErrorHandler() {
    }
    SentryErrorHandler.prototype.handleError = function (error) {
        Sentry.captureException(error.originalError || error);
    };
    return SentryErrorHandler;
}());
exports.SentryErrorHandler = SentryErrorHandler;
function getErrorHandler(sentry) {
    if (sentry.isEnabled) {
        sentry.init();
        return new SentryErrorHandler();
    }
    return new core_1.ErrorHandler();
}
exports.getErrorHandler = getErrorHandler;
