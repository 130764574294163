"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var activation_store_service_1 = require("../../store/activation-store.service");
var setup_account_service_1 = require("./setup-account.service");
var setup_account_types_1 = require("./setup-account.types");
var brand_details_service_1 = require("../../brand/brand-details.service");
var SetupAccountComponent = /** @class */ (function () {
    function SetupAccountComponent(router, activationStore, setupAccountService, changeDetector, brandDetailsService) {
        var _this = this;
        this.router = router;
        this.activationStore = activationStore;
        this.setupAccountService = setupAccountService;
        this.changeDetector = changeDetector;
        this.brandDetailsService = brandDetailsService;
        this.activationStore.changes
            .pipe(operators_1.pluck('activationId'))
            .subscribe(function (activationId) { return _this.activationId = activationId; });
        this.activationStore.changes
            .pipe(operators_1.pluck('verificationCode'))
            .subscribe(function (verificationCode) { return _this.verificationCode = verificationCode; });
        this.formSubmitted = false;
    }
    SetupAccountComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.activationId || !this.verificationCode) {
            this.router.navigate(['/error']);
        }
        else {
            this.setupAccountService.getSecurityQuestions().subscribe(function (securityQuestions) {
                _this.securityQuestions = securityQuestions;
            });
            // set default
            this.selectedSecurityQuestion = null;
        }
        this.brandDetails = this.brandDetailsService.getBrandDetails();
    };
    SetupAccountComponent.prototype.onSubmit = function () {
        var _this = this;
        this.formSubmitted = true;
        this.changeDetector.detectChanges();
        this.enteredSecurityAnswer = this.enteredSecurityAnswer.trim();
        this.setupAccountService.activateIdentityAccount(this.activationId, this.verificationCode, this.enteredPassword, this.selectedSecurityQuestion, this.enteredSecurityAnswer)
            .subscribe(function (activateAccountResult) {
            if (activateAccountResult.Status === setup_account_types_1.ActivateAccountResponseStatuses.Activated) {
                var currentState = _this.activationStore.getState();
                _this.activationStore.setState(Object.assign({}, currentState, { logonName: activateAccountResult.LogonName }));
                _this.router.navigate(['/goal']);
            }
            else {
                _this.router.navigate(['/error']);
            }
        }, function (error) {
            _this.router.navigate(['/timeout']);
        });
    };
    return SetupAccountComponent;
}());
exports.SetupAccountComponent = SetupAccountComponent;
