"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var environment_1 = require("../../environments/environment");
var brand_1 = require("../brand/brand");
var brand_details_data_1 = require("./brand-details.data");
var BrandDetailsService = /** @class */ (function () {
    function BrandDetailsService(document) {
        this.document = document;
    }
    BrandDetailsService.prototype.getBrandDetails = function () {
        var e_1, _a;
        var brand = brand_1.Brand.LibertyAu;
        if (environment_1.environment.overrideBrand)
            brand = environment_1.environment.overrideBrand;
        var details = brand_details_data_1.detailsByBrand.get(brand);
        if (!details) {
            throw new Error("Unrecognised brand " + brand);
        }
        try {
            for (var _b = tslib_1.__values(brand_details_data_1.detailsByBrand.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var detailByBrand = _c.value;
                if (this.document.location.hostname.endsWith(detailByBrand.hostName)) {
                    return detailByBrand;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return brand_details_data_1.defaultBrandDetails;
    };
    return BrandDetailsService;
}());
exports.BrandDetailsService = BrandDetailsService;
var BrandDetailsServiceAUStub = /** @class */ (function () {
    function BrandDetailsServiceAUStub() {
    }
    BrandDetailsServiceAUStub.prototype.getBrandDetails = function (brand) { return brand_details_data_1.detailsByBrand.get(brand); };
    return BrandDetailsServiceAUStub;
}());
exports.BrandDetailsServiceAUStub = BrandDetailsServiceAUStub;
var BrandDetailsServiceNZStub = /** @class */ (function () {
    function BrandDetailsServiceNZStub() {
    }
    BrandDetailsServiceNZStub.prototype.getBrandDetails = function (brand) { return brand_details_data_1.detailsByBrand.get(brand); };
    return BrandDetailsServiceNZStub;
}());
exports.BrandDetailsServiceNZStub = BrandDetailsServiceNZStub;
