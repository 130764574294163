export class RegisterDetails {
  public accountNumber: number;
  public surname: string;
  public dateOfBirthDay: number;
  public dateOfBirthMonth: number;
  public dateOfBirthYear: number;

  constructor() {
    this.accountNumber = null;
    this.surname = null;
    this.dateOfBirthDay = null;
    this.dateOfBirthMonth = null;
    this.dateOfBirthYear = null;
  }
}

export class RegisterResponse {
  constructor(
    public Outcome: RegisterOutcomes
  ) {}
}

export enum RegisterOutcomes {
  Registered = 'Registered',
  MoreDetailsRequired = 'MoreDetailsRequired',
  OnlineAccountAlreadyExists = 'OnlineAccountAlreadyExists'
}
