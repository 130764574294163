"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var demo_api_service_1 = require("../../shared/demo-api-service");
var DemoConfirmAccountService = /** @class */ (function () {
    function DemoConfirmAccountService(api) {
        this.api = api;
    }
    DemoConfirmAccountService.prototype.getPrivacyCheckType = function (activationShortId) {
        var response = this.api.getPrivateCheckType(activationShortId);
        return rxjs_1.of(response, rxjs_1.asapScheduler);
    };
    DemoConfirmAccountService.prototype.postPrivacyCheck = function (activationShortId, verificationCode, privacyDetails) {
        var response = this.api.postPrivacyCheck(activationShortId, verificationCode, privacyDetails);
        return rxjs_1.of(response, rxjs_1.asapScheduler);
    };
    return DemoConfirmAccountService;
}());
exports.DemoConfirmAccountService = DemoConfirmAccountService;
