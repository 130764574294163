import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';

declare var hj: any;

function getHotjar (): any {
  return hj;
}

@Injectable()
export class HotjarService {

  constructor(
    @Inject(DOCUMENT) private document: Document) {
  }

  get hotjar(): any {
    return getHotjar();
  }

  public setHotJarConfig(): void {

    // check required
    if (!environment.hotjar.include && !environment.hotjar.hotjarId) {
      return;
    }

    // check loaded
    if (this.isHotjarLoaded()) {
      return;
    }

    this.loadHotjar(environment.hotjar.hotjarId);
  }

  public isHotjarLoaded(): boolean {
    try {
      return hj.scriptLoaded;
    } catch (e) {
      if (environment.debugMode)
        console.log('hotjar is offline / not loaded');
    }
  }

  public addTag(tag: string) {
    let tags: Array<string> = [tag];
    this.addTags(tags);
  }

  public addTags(tags: Array<string>) {
    this.sendTagToHotjar(tags);
    this.logToConsole(tags);
  }

  private loadHotjar(hotjarId: any): void {
    try {
      const load = (h: any, o: any, t: any, j: any, a: any, r: any) => {
        h.hj = h.hj || ((...args: any[]) => {
          (h.hj.q = h.hj.q || []).push(args);
        });

        h._hjSettings = {hjid: hotjarId, hjsv: 6};
        a = o.getElementsByTagName('head')[0];

        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;

        a.appendChild(r);
      };

      load(window, this.document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=', null, null);

      if (environment.debugMode) {
        console.log('[ Hotjar ] Has been configured');
      }
    } catch (ex) {
      if (environment.debugMode) {
        console.error('[ Hotjar ] Error configuring');
        console.error(ex);
      }
    }
  }

  private sendTagToHotjar(tags: Array<string>) {
    if (environment.production && this.isHotjarLoaded())
      hj('tagRecording', tags);
  }

  private logToConsole(tags: Array<string>) {
    if (environment.debugMode)
      console.log('hotjar tag: ' + tags);
  }
}

@Injectable()
export class HotjarServiceStub {
  hotjar() { };
  setHotJarConfig() { };
  isHotjarLoaded() { };
  addTag(tag: string) { };
  addTags(tags: Array<string>) { };
}
