import { Component } from '@angular/core';
import { BrandDetails } from '../../brand/brand-details.model';
import { BrandDetailsService } from '../../brand/brand-details.service';

@Component({
  templateUrl: './error.component.html',
  styleUrls: [ './error.component.less' ]
})
export class ErrorComponent {
  public brandDetails: BrandDetails;

  constructor(private bandDetailsService: BrandDetailsService) {}

  ngOnInit() {
    // get brand details per brand
    this.brandDetails = this.bandDetailsService.getBrandDetails();
  }
}
