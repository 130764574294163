"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var snack_bar_1 = require("@angular/material/snack-bar");
var environment_1 = require("../environments/environment");
var pipes_1 = require("./pipes");
var demo_api_service_1 = require("./shared/demo-api-service");
var DemoAppComponent = /** @class */ (function () {
    function DemoAppComponent(titleService, api, formatMobileNumber, router, snackBar) {
        titleService.setTitle(environment_1.environment.appTitle);
        var mobileNumberSnackBar;
        router.events.subscribe(function (e) {
            if (e instanceof router_1.NavigationEnd) {
                var mobileNumber_1 = api.getPhoneNumber(e.url.slice(1));
                if (mobileNumber_1) {
                    mobileNumberSnackBar = snackBar.open("[DEMO] Enter mobile number " + formatMobileNumber.transform(mobileNumber_1), 'Copy', { verticalPosition: 'top' });
                    mobileNumberSnackBar.onAction()
                        .subscribe(function () { return navigator.clipboard.writeText(mobileNumber_1); });
                }
                else {
                    if (mobileNumberSnackBar) {
                        mobileNumberSnackBar.dismiss();
                    }
                }
            }
        });
    }
    return DemoAppComponent;
}());
exports.DemoAppComponent = DemoAppComponent;
