"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".password-strength[_ngcontent-%COMP%]{width:100%;font-size:1.4rem;line-height:1}.password-strength.strength-left[_ngcontent-%COMP%]{text-align:left}.password-strength.strength-right[_ngcontent-%COMP%]{text-align:right}.password-strength[_ngcontent-%COMP%]   .strength-label[_ngcontent-%COMP%]{display:inline;margin:0;padding:0 5px 0 0;color:#888;font-size:.6em;text-transform:uppercase}.password-strength[_ngcontent-%COMP%]   .strength-bar[_ngcontent-%COMP%]{display:inline;list-style:none;margin:0;padding:0;vertical-align:1px}.password-strength[_ngcontent-%COMP%]   .strength-bar[_ngcontent-%COMP%]   .strength-bar-item[_ngcontent-%COMP%]{display:inline-block;width:30px;height:3px;margin-right:1px;background:#fff;border-radius:0}.password-strength[_ngcontent-%COMP%]   .strength-bar[_ngcontent-%COMP%]   .strength-bar-item[_ngcontent-%COMP%]:last{margin:0!important}"];
exports.styles = styles;
