<div id="sendCode" class="send-code">

  <!-- loading -->
  <div *ngIf="!isDataLoaded" class="loader-container offset-mt-50">
    <div class="loader loader-spinner loader-spinner-secondary loader-sm"></div>
  </div>
  <!-- END: loading -->

  <!-- content -->
  <div *ngIf="isDataLoaded">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h1 class="panel-title">Activate your Online Account</h1>
      </div>
      <div class="panel-body">

        <!-- send states -->
        <div [ngSwitch]="displayState">

          <!-- send / request -->
          <div *ngSwitchCase="1">

            <p>Welcome {{firstName}},</p>
            <p>To get started, please enter your preferred mobile number below and we’ll send you a one-off activation code by text message.</p>

            <!-- form / send -->
            <form id="form-send-sms" class="form-send-sms form-horizontal" role="form"
               (ngSubmit)="onSubmit(false)" #sendSmsForm="ngForm" novalidate>

              <fieldset [disabled]="formSubmitted">

                <!-- input / mobile# -->
                <div class="form-group" [ngClass]="{ 'has-error' : mobileNumber.errors && mobileNumber.touched }">

                  <label for="mobileNumber" class="control-label col-sm-5">Enter your mobile number</label>
                  <div class="col-sm-5">
                    <input type="text" name="mobileNumber" id="mobileNumber" class="form-control"
                      [(ngModel)]="enteredMobileNumber"
                      #mobileNumber="ngModel"
                      [textMask]="{ mask: brandDetails.mobileNumberHelper.mask, guide: false }"
                      [placeholder]="brandDetails.mobileNumberHelper.placeholder"
                      autocomplete="off" autocorrect="off" autocapitalize="off"
                      required aria-required="true"
                      pattern="04[\d\s]{10}|02[\d\s]{6,11}">

                    <div class="help-block" for="mobileNumber" *ngIf="mobileNumber.errors && mobileNumber.touched">
                      <span *ngIf="mobileNumber.errors?.required">Please enter your mobile number.</span>
                      <span *ngIf="mobileNumber.errors?.pattern || mobileNumber.errors?.minlength">Please enter a valid mobile number.</span>
                    </div>

                  </div>
                </div>
                <!-- END: input / mobile# -->

                <!-- btn / send -->
                <div class="form-group">
                  <div class="col-sm-5 col-sm-offset-5">
                    <button type="submit" id="form-btn-submit" class="btn btn-block btn-primary fx-fade-color"
                      [disabled]="!sendSmsForm.valid"
                      [ngClass]="{ 'loading' : formSubmitted }">
                        <span class="loader loader-spinner"></span>
                        <span class="btn-label">Send Activation Code</span>
                    </button>
                  </div>
                </div>
                <!-- END: btn / send -->

              </fieldset>
            </form>
            <!-- END: form / send -->

          </div>
          <!-- END: send / request -->

          <!-- send / complete -->
          <div *ngSwitchCase="2">

            <p>Your online account has already been successfully activated. To access, click the link below.</p>
            <button type="button" id="btn-redirect-online" class="btn btn-primary offset-mt-10 fx-fade-color"
              (click)="redirectToOnlineSite();">
                <span class="btn-label">Access your Online Account</span>
            </button>

          </div>
          <!-- END: send / complete -->

          <!-- send / incorrect mobile -->
          <div *ngSwitchCase="3">

            <p>We are unable to activate your account online. Please call us on {{ brandDetails.phoneNumber }} for assistance.</p>

            <!-- btns -->
            <form id="form-confirm-override" class="form-confirm-override form-horizontal" role="form"
               (ngSubmit)="onSubmit(true)" #confirmOverrideForm="ngForm">

              <fieldset [disabled]="formSubmitted">
                <div class="form-group">

                  <!-- btn / cancel -->
                  <div class="col-sm-5 col-sm-offset-5 col-sm-pull-5">
                    <button type="button" id="form-btn-cancel" class="btn btn-block btn-tertiary fx-fade-color"
                      (click)="cancelConfirm();">
                        <span class="btn-label">Cancel</span>
                    </button>
                  </div>
                  <!-- END: btn / cancel -->

                </div>
              </fieldset>
            </form>
            <!-- END: btns -->

          </div>
          <!-- END: send / incorrect mobile -->

        </div>
        <!-- END: send state -->

      </div>
    </div>
  </div>
  <!-- END: content -->

</div>
