"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var LogErrorInterceptor = /** @class */ (function () {
    function LogErrorInterceptor() {
    }
    LogErrorInterceptor.prototype.intercept = function (req, next) {
        return next.handle(req).pipe(operators_1.tap(function (event) { }, function (error) {
            if (error instanceof http_1.HttpErrorResponse) {
                var errMsg = error.status + " - " + error.statusText + ", " + (JSON.stringify(error.error) || '') + ", " + (error.message || '');
                console.error(errMsg);
            }
        }));
    };
    return LogErrorInterceptor;
}());
exports.LogErrorInterceptor = LogErrorInterceptor;
