"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("../../../environments/environment");
var RegisterService = /** @class */ (function () {
    function RegisterService(http) {
        this.http = http;
    }
    RegisterService.prototype.postRegister = function (registerDetails) {
        return this.http.post(environment_1.environment.apiUrl + "register", registerDetails);
    };
    return RegisterService;
}());
exports.RegisterService = RegisterService;
