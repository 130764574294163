"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./password-strength-bar.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./password-strength-bar.component");
var styles_PasswordStrengthBar = [i0.styles];
var RenderType_PasswordStrengthBar = i1.ɵcrt({ encapsulation: 0, styles: styles_PasswordStrengthBar, data: {} });
exports.RenderType_PasswordStrengthBar = RenderType_PasswordStrengthBar;
function View_PasswordStrengthBar_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "strength-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.strengthLabel; _ck(_v, 1, 0, currVal_0); }); }
function View_PasswordStrengthBar_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["passwordStrength", 1]], null, 8, "div", [["class", "password-strength"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PasswordStrengthBar_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "ul", [["class", "strength-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "li", [["class", "strength-bar-item"]], [[4, "background-color", null]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "li", [["class", "strength-bar-item"]], [[4, "background-color", null]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "li", [["class", "strength-bar-item"]], [[4, "background-color", null]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "li", [["class", "strength-bar-item"]], [[4, "background-color", null]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "li", [["class", "strength-bar-item"]], [[4, "background-color", null]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showStrengthLabel; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.bar0; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.bar1; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.bar2; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.bar3; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.bar4; _ck(_v, 8, 0, currVal_5); }); }
exports.View_PasswordStrengthBar_0 = View_PasswordStrengthBar_0;
function View_PasswordStrengthBar_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "password-strength-bar", [], null, null, null, View_PasswordStrengthBar_0, RenderType_PasswordStrengthBar)), i1.ɵdid(1, 573440, null, 0, i3.PasswordStrengthBar, [], null, null)], null, null); }
exports.View_PasswordStrengthBar_Host_0 = View_PasswordStrengthBar_Host_0;
var PasswordStrengthBarNgFactory = i1.ɵccf("password-strength-bar", i3.PasswordStrengthBar, View_PasswordStrengthBar_Host_0, { passwordToCheck: "passwordToCheck", showStrengthLabel: "showStrengthLabel", strengthLabel: "strengthLabel" }, {}, []);
exports.PasswordStrengthBarNgFactory = PasswordStrengthBarNgFactory;
