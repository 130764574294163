import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ValidateVerificationCodeResult } from './verify-code.types';
import { SendSmsResults } from './verify-code.types';
import { MobileNumberHandler } from '../../helpers/mobile-number.helper';
import { environment } from '../../../environments/environment';

@Injectable()
export class VerifyCodeService {

    constructor (private http: HttpClient,
                 private mobileNumber: MobileNumberHandler) {}

    validateVerificationCode(activationShortId: string, verificationCode: string): Observable<ValidateVerificationCodeResult> {
        return this.http.post<ValidateVerificationCodeResult>(
            `${environment.apiUrl}validate-code/${activationShortId}`,
            {
                verificationCode
            }
        );
    }

    resendVerificationCode(
        activationShortId: string,
        rawMobileNumber: string,
    ): Observable<SendSmsResults> {
        const cleanMobileNumber = this.mobileNumber.clean(rawMobileNumber);
        return this.http.post<SendSmsResults>(
            `${environment.apiUrl}verify-mobile/${activationShortId}/send-code`,
            {
                mobileNumber: cleanMobileNumber,
                isResend: true,
            }
        );
    }
}
