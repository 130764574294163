"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_helper_1 = require("./base.helper");
var MobileNumberHandler = /** @class */ (function () {
    function MobileNumberHandler() {
    }
    // clean
    MobileNumberHandler.prototype.clean = function (mobileNumber) {
        return mobileNumber.trim().replace(/\s+/g, '');
    };
    // validate
    MobileNumberHandler.prototype.validate = function (rawMobileNumber) {
        var mobileNumber = this.clean(rawMobileNumber);
        return mobileNumber.length === 10;
    };
    return MobileNumberHandler;
}());
exports.MobileNumberHandler = MobileNumberHandler;
var AuMobileNumberHelper = /** @class */ (function (_super) {
    tslib_1.__extends(AuMobileNumberHelper, _super);
    function AuMobileNumberHelper() {
        return _super.call(this, /^(?:\+?61|0|)4(?<freeDigits>\d{8})$/, '04XX XXX XXX', [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]) || this;
    }
    AuMobileNumberHelper.prototype.format = function (num) {
        var match = this.clean(num).match(this.pattern);
        if (!match)
            return null;
        var freeDigits = match.groups.freeDigits;
        var d01 = freeDigits.slice(0, 2);
        var d234 = freeDigits.slice(2, 5);
        var d567 = freeDigits.slice(5);
        return "04" + d01 + " " + d234 + " " + d567;
    };
    AuMobileNumberHelper.prototype.getMask = function () {
        var tail = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
        return function (val) {
            if (/^\+/.test(val)) { // International with plus
                return tslib_1.__spread(['+', /\d/, /\d/, ' '], tail);
            }
            else if (/^6/.test(val)) { // International without plus
                return tslib_1.__spread([/\d/, /\d/, ' '], tail);
            }
            else if (/^4/.test(val)) { // National number without trunk
                return tail;
            }
            else { // Default to national number with trunk
                return tslib_1.__spread([/\d/], tail);
            }
        };
    };
    return AuMobileNumberHelper;
}(base_helper_1.PhoneNumberHelper));
exports.AuMobileNumberHelper = AuMobileNumberHelper;
var NzMobileNumberHelper = /** @class */ (function (_super) {
    tslib_1.__extends(NzMobileNumberHelper, _super);
    function NzMobileNumberHelper() {
        return _super.call(this, /^(?:\+?64|0|)2(?<freeDigits>\d{6,11})$/, '02X XXXX XXXX', [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]) || this;
    }
    NzMobileNumberHelper.prototype.format = function (num) {
        var match = this.clean(num).match(this.pattern);
        if (!match)
            return null;
        var freeDigits = match.groups.freeDigits;
        var d0 = freeDigits.slice(0, 1);
        if (freeDigits.length < 9) {
            var d123 = freeDigits.slice(1, 4);
            var d456opt7 = freeDigits.slice(4);
            return "02" + d0 + " " + d123 + " " + d456opt7;
        }
        else {
            var d1234 = freeDigits.slice(1, 5);
            var d5678 = freeDigits.slice(5);
            return "02" + d0 + " " + d1234 + " " + d5678;
        }
    };
    NzMobileNumberHelper.prototype.getMask = function () {
        var _this = this;
        // For NZ phone numbers with 9 digits after the area code, split into 4+4 instead of 3+5.
        var shortTail = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/];
        var longTail = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
        return function (val) {
            var freeDigits;
            if (_this.clean(val).match(_this.pattern)) {
                if (_this.clean(val).match(_this.pattern).groups) {
                    freeDigits = _this.clean(val).match(_this.pattern).groups.freeDigits;
                }
            }
            var tail = freeDigits && freeDigits.length >= 9 ? longTail : shortTail;
            if (/^\+/.test(val)) { // International with plus
                return tslib_1.__spread(['+', /\d/, /\d/, ' '], tail);
            }
            else if (/^6/.test(val)) { // International without plus
                return tslib_1.__spread([/\d/, /\d/, ' '], tail);
            }
            else if (/^2/.test(val)) { // National number without trunk
                return tail;
            }
            else { // Default to national number with trunk
                return tslib_1.__spread([/\d/], tail);
            }
        };
    };
    return NzMobileNumberHelper;
}(base_helper_1.PhoneNumberHelper));
exports.NzMobileNumberHelper = NzMobileNumberHelper;
