import { Brand } from './brand';
import { BrandDetails, CountryBrandDetails } from './brand-details.model';
import { environment } from '../../environments/environment';
import { AuMobileNumberHelper, NzMobileNumberHelper } from '../helpers/mobile-number.helper';


const auBrandDetails: CountryBrandDetails = {
  mobileNumberHelper: new AuMobileNumberHelper,
}

const nzBrandDetails: CountryBrandDetails = {
  mobileNumberHelper: new NzMobileNumberHelper,
}

export const libertyAuBrandDetails: BrandDetails = {
  ...auBrandDetails,

  brand: Brand.LibertyAu,
  phoneNumber: '13 11 33',
  name: 'Liberty Financial Pty Ltd',
  url: 'logo-liberty-financial.png',
  onlineSiteUrl: environment.demo ? 'https://demo-online.liberty.com.au' : 'https://online.liberty.com.au',
  domainName: 'activate.liberty.com.au',
  hostName: 'liberty.com.au',
  shortName: 'Liberty',

  // footer details
  footer: `All rights reserved |
                      Liberty Financial Pty Ltd |
                      ABN 55 077 248 983 |
                      Australian Credit Licence 286596 |
                      Liberty Network Services Pty Ltd |
                      ABN 65 151 158 628 |
                      Australian Credit Licence 408042`
};

export const libertyNzBrandDetails: BrandDetails = {
  ...nzBrandDetails,

  brand: Brand.LibFinNz,
  phoneNumber: '0800 003 391',
  name: 'Liberty Financial Pty Ltd',
  url: 'logo-liberty-financial.png',
  onlineSiteUrl: environment.demo ? 'https://demo-online.libfin.co.nz' : 'https://online.libfin.co.nz',
  domainName: 'activate.libfin.co.nz',
  hostName: 'libfin.co.nz',
  shortName: 'Liberty',

  // footer details
  footer: `Copyright © ${new Date().getFullYear()} All rights reserved | Liberty Financial is a registered trademark.`
};

export const detailsByBrand = new Map<Brand, BrandDetails>();
for (const details of [libertyAuBrandDetails, libertyNzBrandDetails]) {
  detailsByBrand.set(details.brand, details);
}

export const defaultBrandDetails = libertyNzBrandDetails;
