"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var activation_store_service_1 = require("../../store/activation-store.service");
var index_1 = require("../../shared/index");
var send_code_service_1 = require("./send-code.service");
var send_code_types_1 = require("./send-code.types");
var send_code_types_2 = require("./send-code.types");
var brand_details_service_1 = require("../../brand/brand-details.service");
var DisplayState;
(function (DisplayState) {
    DisplayState[DisplayState["Pending"] = 1] = "Pending";
    DisplayState[DisplayState["Completed"] = 2] = "Completed";
    DisplayState[DisplayState["IncorrectMobile"] = 3] = "IncorrectMobile";
})(DisplayState = exports.DisplayState || (exports.DisplayState = {}));
var SendCodeComponent = /** @class */ (function () {
    function SendCodeComponent(route, router, activationStore, heapService, sendCodeService, changeDetector, brandDetailsServie) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.activationStore = activationStore;
        this.heapService = heapService;
        this.sendCodeService = sendCodeService;
        this.changeDetector = changeDetector;
        this.brandDetailsServie = brandDetailsServie;
        this.activationStatuses = send_code_types_1.ActivationStatuses;
        this.activationId = null;
        this.guidPattern = /^[a-zA-Z0-9_-]{22}$/;
        this.activationStore.changes
            .pipe(operators_1.pluck('activationId'))
            .subscribe(function (activationId) { return _this.activationId = activationId; });
        this.isDataLoaded = false;
        this.displayState = DisplayState.Pending;
        this.formSubmitted = false;
        this.onlineAnalyticsTracking = '?utm_source=liberty&utm_medium=website&utm_content=existingbtn&utm_campaign=activate';
    }
    SendCodeComponent.prototype.ngOnInit = function () {
        this.extractActivationIdFromRoute();
        this.brandDetails = this.brandDetailsServie.getBrandDetails();
    };
    SendCodeComponent.prototype.ngOnDestroy = function () {
        if (this.paramsSub) {
            this.paramsSub.unsubscribe();
        }
    };
    SendCodeComponent.prototype.extractActivationIdFromRoute = function () {
        var _routeActivationId;
        this.paramsSub = this.route.params.subscribe(function (params) {
            _routeActivationId = params['id'];
        });
        // valid GUID
        if (this.guidPattern.test(_routeActivationId)) {
            var currentState = this.activationStore.getState();
            this.activationStore.setState(Object.assign({}, currentState, { activationId: _routeActivationId }));
            this.getActivationStatus();
        }
        else {
            this.addEventToHeap('Activation - Invalid GUID');
            this.router.navigate(['/register']);
        }
    };
    SendCodeComponent.prototype.getActivationStatus = function () {
        var _this = this;
        this.sendCodeService.getActivationStatus(this.activationId)
            .subscribe(function (activationStatus) {
            switch (activationStatus) {
                case _this.activationStatuses.Pending:
                    _this.getCustomerDetails();
                    _this.addEventToHeap('Activation - Started');
                    break;
                case _this.activationStatuses.Completed:
                    _this.addEventToHeap('Activation - Account - Already Completed');
                    _this.displayState = DisplayState.Completed;
                    break;
                case _this.activationStatuses.Locked:
                    _this.addEventToHeap('Activation - Account - Locked');
                    _this.router.navigate(['/error']);
                    break;
                case _this.activationStatuses.RequiresContact:
                    _this.addEventToHeap('Activation - Account - Requires Contact');
                    _this.router.navigate(['/contact']);
                    break;
                case _this.activationStatuses.NotFound:
                default:
                    _this.addEventToHeap('Activation - Account - Not Found');
                    _this.router.navigate(['/error']);
                    break;
            }
            _this.isDataLoaded = true;
        }, function (error) {
            _this.addEventToHeap('Server Timeout');
            _this.router.navigate(['/timeout']);
        });
    };
    SendCodeComponent.prototype.getCustomerDetails = function () {
        var _this = this;
        this.sendCodeService.getFirstName(this.activationId)
            .subscribe(function (firstNameResult) {
            _this.firstName = firstNameResult;
            var currentState = _this.activationStore.getState();
            _this.activationStore.setState(Object.assign({}, currentState, {
                firstName: _this.firstName
            }));
        }, function (error) {
            _this.addEventToHeap('Server Timeout');
            _this.router.navigate(['/timeout']);
        });
    };
    SendCodeComponent.prototype.redirectToOnlineSite = function () {
        this.router.ngOnDestroy();
        window.location.href = this.brandDetails.onlineSiteUrl + this.onlineAnalyticsTracking;
    };
    SendCodeComponent.prototype.onSubmit = function () {
        var _this = this;
        this.formSubmitted = true;
        this.changeDetector.detectChanges();
        this.sendCodeService.postSendSmsRequest(this.activationId, this.enteredMobileNumber)
            .subscribe(function (sendSmsResult) {
            switch (sendSmsResult) {
                case send_code_types_2.SendSmsResults.Success:
                    var currentState = _this.activationStore.getState();
                    _this.activationStore.setState(Object.assign({}, currentState, {
                        mobileNumber: _this.enteredMobileNumber
                    }));
                    _this.router.navigate(['/verify']);
                    break;
                case send_code_types_2.SendSmsResults.IncorrectMobileNumber:
                    _this.displayState = DisplayState.IncorrectMobile;
                    _this.addEventToHeap('Activation - Account - Different Mobile');
                    _this.formSubmitted = false;
                    break;
                default:
                    _this.addEventToHeap('Server Error');
                    _this.router.navigate(['/error']);
                    break;
            }
        }, function (error) {
            _this.addEventToHeap('Server Timeout');
            _this.router.navigate(['/timeout']);
        });
    };
    SendCodeComponent.prototype.cancelConfirm = function () {
        this.displayState = DisplayState.Pending;
    };
    SendCodeComponent.prototype.addEventToHeap = function (event) {
        this.heapService.addEvent(event);
    };
    return SendCodeComponent;
}());
exports.SendCodeComponent = SendCodeComponent;
