import { Injectable } from '@angular/core';

import { Observable, asapScheduler, of } from 'rxjs';

import { ClientGoalsService } from './client-goals.service';
import { ClientGoalsRequest, ClientGoalsResponse } from './client-goals.types';
import { DemoApiService } from '../../shared/demo-api-service';

type IClientGoalsService = { [P in keyof ClientGoalsService]: ClientGoalsService[P] };

@Injectable()
export class DemoClientGoalsService implements IClientGoalsService {
  constructor(private api: DemoApiService) {
  }

  postGoal(activationShortId: string, request: ClientGoalsRequest): Observable<ClientGoalsResponse> {
    const response = this.api.postGoal(activationShortId, request);
    return of(response, asapScheduler);
  }
}
