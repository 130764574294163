import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { RegisterDetails, RegisterResponse } from './register.types';
import { environment } from '../../../environments/environment';

@Injectable()
export class RegisterService {

  constructor(private http: HttpClient) {}

  postRegister(registerDetails: RegisterDetails): Observable<RegisterResponse> {
    return this.http.post<RegisterResponse>(`${environment.apiUrl}register`, registerDetails);
  }
}
