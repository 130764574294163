"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RegisterDetails = /** @class */ (function () {
    function RegisterDetails() {
        this.accountNumber = null;
        this.surname = null;
        this.dateOfBirthDay = null;
        this.dateOfBirthMonth = null;
        this.dateOfBirthYear = null;
    }
    return RegisterDetails;
}());
exports.RegisterDetails = RegisterDetails;
var RegisterResponse = /** @class */ (function () {
    function RegisterResponse(Outcome) {
        this.Outcome = Outcome;
    }
    return RegisterResponse;
}());
exports.RegisterResponse = RegisterResponse;
var RegisterOutcomes;
(function (RegisterOutcomes) {
    RegisterOutcomes["Registered"] = "Registered";
    RegisterOutcomes["MoreDetailsRequired"] = "MoreDetailsRequired";
    RegisterOutcomes["OnlineAccountAlreadyExists"] = "OnlineAccountAlreadyExists";
})(RegisterOutcomes = exports.RegisterOutcomes || (exports.RegisterOutcomes = {}));
