"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var Sentry = require("@sentry/browser");
var noop_interceptor_1 = require("./noop-interceptor");
var SentryErrorInterceptor = /** @class */ (function () {
    function SentryErrorInterceptor() {
    }
    SentryErrorInterceptor.prototype.intercept = function (req, next) {
        return next.handle(req).pipe(operators_1.tap(function (event) { }, function (error) {
            Sentry.withScope(function (scope) {
                scope.setExtra('url', req.url);
                Sentry.captureException(error.error);
            });
        }));
    };
    return SentryErrorInterceptor;
}());
exports.SentryErrorInterceptor = SentryErrorInterceptor;
function getSentryErrorInterceptor(sentry) {
    if (sentry.isEnabled) {
        sentry.init();
        return new SentryErrorInterceptor();
    }
    return new noop_interceptor_1.NoopInterceptor();
}
exports.getSentryErrorInterceptor = getSentryErrorInterceptor;
