"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var brand_details_service_1 = require("../../brand/brand-details.service");
var FooterComponent = /** @class */ (function () {
    function FooterComponent(brandDetailsService) {
        this.brandDetailsService = brandDetailsService;
    }
    FooterComponent.prototype.ngOnInit = function () {
        this.brandDetails = this.brandDetailsService.getBrandDetails();
    };
    return FooterComponent;
}());
exports.FooterComponent = FooterComponent;
