"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var demo_api_service_1 = require("../../shared/demo-api-service");
var DemoSetupAccountService = /** @class */ (function () {
    function DemoSetupAccountService(api) {
        this.api = api;
    }
    DemoSetupAccountService.prototype.activateIdentityAccount = function (activationShortId, verificationCode, password, securityQuestion, securityAnswer) {
        var response = this.api.activateIdentityAccount(activationShortId, verificationCode, password, securityQuestion, securityAnswer);
        return rxjs_1.of(response, rxjs_1.asapScheduler);
    };
    DemoSetupAccountService.prototype.getSecurityQuestions = function () {
        return rxjs_1.of(this.api.getSecurityQuestions(), rxjs_1.asapScheduler);
    };
    return DemoSetupAccountService;
}());
exports.DemoSetupAccountService = DemoSetupAccountService;
