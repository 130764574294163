"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/** Pass untouched request through to the next request handler. */
var NoopInterceptor = /** @class */ (function () {
    function NoopInterceptor() {
    }
    NoopInterceptor.prototype.intercept = function (req, next) {
        return next.handle(req);
    };
    return NoopInterceptor;
}());
exports.NoopInterceptor = NoopInterceptor;
