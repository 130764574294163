import { Injectable } from '@angular/core';

import { Observable, asapScheduler, of } from 'rxjs';

import { VerifyCodeService } from './verify-code.service';
import { ValidateVerificationCodeResult, SendSmsResults } from './verify-code.types';
import { DemoApiService } from '../../shared/demo-api-service';
import { MobileNumberHandler } from '../../helpers';

type IVerifyCodeService = { [P in keyof VerifyCodeService]: VerifyCodeService[P] };

@Injectable()
export class DemoVerifyCodeService implements IVerifyCodeService {
  constructor(private api: DemoApiService, private mobileNumberHandler: MobileNumberHandler) {
  }

  validateVerificationCode(activationShortId: string, verificationCode: string): Observable<ValidateVerificationCodeResult> {
    const result = this.api.validateVerificationCode(activationShortId, verificationCode);
    return of(result, asapScheduler);
  }

  resendVerificationCode(activationShortId: string, rawMobileNumber: string): Observable<SendSmsResults> {
    const result = this.api.sendCode(activationShortId, this.mobileNumberHandler.clean(rawMobileNumber));
    return of(result, asapScheduler);
  }
}
