export class MaskedMobileResult {
    constructor(
        public hasError: boolean,
        public maskedMobile: string,
    ) {}
}

export enum SendSmsResults {
    Success = 'Success',
    IncorrectMobileNumber = 'IncorrectMobileNumber',
    Error = 'Error',
}

export enum ActivationStatuses {
    Pending = 'Pending',
    Completed = 'Completed',
    NotFound = 'NotFound',
    Locked = 'Locked',
    RequiresContact = 'RequiresContact',
}
