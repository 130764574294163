import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, BehaviorSubject } from 'rxjs';

import { ActivationStatuses, SendSmsResults } from '../pages/send/send-code.types';
import { ValidateVerificationCodeResult, ValidateResults } from '../pages/verify/verify-code.types';
import {
  ActivateAccountResponse,
  ActivateAccountResponseStatuses,
  SecurityQuestionSelection,
  SecurityQuestions,
} from '../pages/setup/setup-account.types';
import { ClientGoalsRequest, ClientGoalsResponse } from '../pages/client-goals/client-goals.types';
import { RegisterResponse, RegisterDetails, RegisterOutcomes } from '../pages/register/register.types';
import {
  CheckPrivacyResponse,
  CheckPrivacyStatuses,
  ClientPrivacyDetails,
  PrivacyCheckTypes,
} from '../pages/confirm/confirm-account.types';
import { BrandDetailsService } from '../brand/brand-details.service';
import { Brand } from '../brand/brand';

let deepEqual = require('deep-equal');

interface Activation {
  status: ActivationStatuses;
  firstName: string;
  phoneNumber: string;
  code: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class DemoApiService {
  private readonly localStorageKey = 'demo_activations';

  private readonly _isDirty$ = new BehaviorSubject<boolean>(false);

  private activations: { [id: string]: Activation };

  private _privacyCheck: PrivacyCheckTypes | null;

  private _registerOutcome: RegisterOutcomes = RegisterOutcomes.Registered;

  constructor(private snackbar: MatSnackBar, private brandDetailsService: BrandDetailsService) {
    this.activations = JSON.parse(localStorage.getItem(this.localStorageKey)) || this.getDemoActivations();
    this.commit();
  }

  get isDirty$(): Observable<boolean> {
    return this._isDirty$;
  }

  get privacyCheck(): PrivacyCheckTypes | null {
    return this._privacyCheck;
  }
  set privacyCheck(value: PrivacyCheckTypes | null) {
    this._privacyCheck = value;
  }

  get registerOutcome(): RegisterOutcomes {
    return this._registerOutcome;
  }
  set registerOutcome(value: RegisterOutcomes) {
    this._registerOutcome = value;
  }

  reset(): void {
    this.activations = this.getDemoActivations();
    this.commit();
  }

  private getDemoActivations() {
    let brand = this.brandDetailsService.getBrandDetails().brand;

    switch (brand) {
      case Brand.LibertyAu:
        return {
            'IXi1asfcrk65OVFoer66LA': {
              firstName: 'Tim',
              phoneNumber: '0431123456',
              status: ActivationStatuses.Pending,
              code: null as string,
            }
          };
      case Brand.LibFinNz:
        return {
          'IXi1asfcrk65OVFoer66LA': {
            firstName: 'Melinda',
            phoneNumber: '02112345678',
            status: ActivationStatuses.Pending,
            code: null as string,
          }
        };
    }
  }

  getActivationStatus(id: string): ActivationStatuses | null {
    const activation = this.activations[id];
    if (activation) {
      return activation.status;
    }

    return null;
  }

  getFirstName(id: string): string | null {
    const activation = this.activations[id];
    if (activation) {
      return activation.firstName;
    }

    return null;
  }

  getPhoneNumber(id: string): string | null {
    const activation = this.activations[id];
    if (activation) {
      return activation.phoneNumber;
    }

    return null;
  }

  validateVerificationCode(id: string, verificationCode: string): ValidateVerificationCodeResult {
    const activation = this.activations[id];
    if (!activation) {
      return new ValidateVerificationCodeResult(ValidateResults.InvalidActivationId);
    }

    if (activation.code !== verificationCode) {
      return new ValidateVerificationCodeResult(ValidateResults.InvalidVerificationCode);
    }

    if (this.privacyCheck) {
      return new ValidateVerificationCodeResult(ValidateResults.PrivacyCheckRequired);
    }

    return new ValidateVerificationCodeResult(ValidateResults.ValidVerificationCode);
  }

  sendCode(id: string, phoneNumber: string): SendSmsResults {
    if (this.getActivationStatus(id) !== ActivationStatuses.Pending) {
      return SendSmsResults.Error;
    }

    const expectedPhoneNumber = this.getPhoneNumber(id);
    if (!expectedPhoneNumber) {
      return SendSmsResults.Error;
    }

    if (phoneNumber !== expectedPhoneNumber) {
      return SendSmsResults.IncorrectMobileNumber;
    }

    const code = this.getRandomInt(0, 1000000).toString().padStart(6, '0');

    this.snackbar.open(
      `[DEMO] Your activation code is ${code}`,
      'Copy',
      { verticalPosition: 'top' }
      )
      .onAction()
      .subscribe(() => (navigator as any).clipboard.writeText(code));

    this.activations[id].code = code;

    this.commit();
    return SendSmsResults.Success;
  }

  postRegister(registerDetails: RegisterDetails): RegisterResponse {
    return new RegisterResponse(this.registerOutcome);
  }

  activateIdentityAccount(
      id: string,
      verificationCode: string,
      password: string,
      securityQuestion: SecurityQuestions,
      securityAnswer: string): ActivateAccountResponse {
    if (this.getActivationStatus(id) !== ActivationStatuses.Pending) {
      return new ActivateAccountResponse(ActivateAccountResponseStatuses.ActivationIdInvalid, null);
    }

    const activation = this.activations[id];
    if (activation.code !== verificationCode) {
      return new ActivateAccountResponse(ActivateAccountResponseStatuses.VerificationCodeInvalid, null);
    }

    activation.status = ActivationStatuses.Completed;
    this.commit();

    const logonName = 'test@test.com';
    return new ActivateAccountResponse(ActivateAccountResponseStatuses.Activated, logonName);
  }


  postGoal(id: string, request: ClientGoalsRequest): ClientGoalsResponse {
    if (!this.activations[id]) {
      return new ClientGoalsResponse(false);
    }

    return new ClientGoalsResponse(true);
  }

  postPrivacyCheck(id: string, verificationCode: string, privacyDetails: ClientPrivacyDetails): CheckPrivacyResponse {
    return new CheckPrivacyResponse(CheckPrivacyStatuses.Accepted);
  }

  getPrivateCheckType(id: string): PrivacyCheckTypes {
    return this._privacyCheck;
  }

  getSecurityQuestions(): SecurityQuestionSelection[] {
    return [
      {
        SecurityQuestion: SecurityQuestions.MothersMaidenName,
        Text: 'Your Mother\'s Maiden Name?'
      },
      {
        SecurityQuestion: SecurityQuestions.AnniversaryDate,
        Text: 'Your Anniversary Date [dd/mm/yy]?'
      },
      {
        SecurityQuestion: SecurityQuestions.PetsName,
        Text: 'Your Pet\'s Name?'
      },
      {
        SecurityQuestion: SecurityQuestions.BestFriendsName,
        Text: 'Your Best Friend’s Name?'
      },
      {
        SecurityQuestion: SecurityQuestions.FavouriteMovie,
        Text: 'Your Favourite Movie?'
      },
      {
        SecurityQuestion: SecurityQuestions.FavouriteColour,
        Text: 'Your Favourite Colour?'
      },
      {
        SecurityQuestion: SecurityQuestions.FathersName,
        Text: 'Your Father\'s Name?'
      },
      {
        SecurityQuestion: SecurityQuestions.CityOfBirth,
        Text: 'Your City of Birth?'
      },
    ];
  }

  commit(): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.activations));
    this._isDirty$.next(!deepEqual(this.getDemoActivations(), this.activations));
  }

  private getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive
  }
}
