"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var mobile_number_helper_1 = require("../../helpers/mobile-number.helper");
var environment_1 = require("../../../environments/environment");
var VerifyCodeService = /** @class */ (function () {
    function VerifyCodeService(http, mobileNumber) {
        this.http = http;
        this.mobileNumber = mobileNumber;
    }
    VerifyCodeService.prototype.validateVerificationCode = function (activationShortId, verificationCode) {
        return this.http.post(environment_1.environment.apiUrl + "validate-code/" + activationShortId, {
            verificationCode: verificationCode
        });
    };
    VerifyCodeService.prototype.resendVerificationCode = function (activationShortId, rawMobileNumber) {
        var cleanMobileNumber = this.mobileNumber.clean(rawMobileNumber);
        return this.http.post(environment_1.environment.apiUrl + "verify-mobile/" + activationShortId + "/send-code", {
            mobileNumber: cleanMobileNumber,
            isResend: true,
        });
    };
    return VerifyCodeService;
}());
exports.VerifyCodeService = VerifyCodeService;
