"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("../../../environments/environment");
var ConfirmAccountService = /** @class */ (function () {
    function ConfirmAccountService(http) {
        this.http = http;
    }
    ConfirmAccountService.prototype.getPrivacyCheckType = function (activationShortId) {
        return this.http.get(environment_1.environment.apiUrl + "check-privacy/" + activationShortId + "/type");
    };
    ConfirmAccountService.prototype.postPrivacyCheck = function (activationShortId, verificationCode, privacyDetails) {
        return this.http.post(environment_1.environment.apiUrl + "check-privacy/" + activationShortId, {
            VerificationCode: verificationCode,
            PrivacyDetails: privacyDetails
        });
    };
    return ConfirmAccountService;
}());
exports.ConfirmAccountService = ConfirmAccountService;
