"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var e_1, _a;
var brand_1 = require("./brand");
var environment_1 = require("../../environments/environment");
var mobile_number_helper_1 = require("../helpers/mobile-number.helper");
var auBrandDetails = {
    mobileNumberHelper: new mobile_number_helper_1.AuMobileNumberHelper,
};
var nzBrandDetails = {
    mobileNumberHelper: new mobile_number_helper_1.NzMobileNumberHelper,
};
exports.libertyAuBrandDetails = tslib_1.__assign({}, auBrandDetails, { brand: brand_1.Brand.LibertyAu, phoneNumber: '13 11 33', name: 'Liberty Financial Pty Ltd', url: 'logo-liberty-financial.png', onlineSiteUrl: environment_1.environment.demo ? 'https://demo-online.liberty.com.au' : 'https://online.liberty.com.au', domainName: 'activate.liberty.com.au', hostName: 'liberty.com.au', shortName: 'Liberty', 
    // footer details
    footer: "All rights reserved |\n                      Liberty Financial Pty Ltd |\n                      ABN 55 077 248 983 |\n                      Australian Credit Licence 286596 |\n                      Liberty Network Services Pty Ltd |\n                      ABN 65 151 158 628 |\n                      Australian Credit Licence 408042" });
exports.libertyNzBrandDetails = tslib_1.__assign({}, nzBrandDetails, { brand: brand_1.Brand.LibFinNz, phoneNumber: '0800 003 391', name: 'Liberty Financial Pty Ltd', url: 'logo-liberty-financial.png', onlineSiteUrl: environment_1.environment.demo ? 'https://demo-online.libfin.co.nz' : 'https://online.libfin.co.nz', domainName: 'activate.libfin.co.nz', hostName: 'libfin.co.nz', shortName: 'Liberty', 
    // footer details
    footer: "Copyright \u00A9 " + new Date().getFullYear() + " All rights reserved | Liberty Financial is a registered trademark." });
exports.detailsByBrand = new Map();
try {
    for (var _b = tslib_1.__values([exports.libertyAuBrandDetails, exports.libertyNzBrandDetails]), _c = _b.next(); !_c.done; _c = _b.next()) {
        var details = _c.value;
        exports.detailsByBrand.set(details.brand, details);
    }
}
catch (e_1_1) { e_1 = { error: e_1_1 }; }
finally {
    try {
        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
    }
    finally { if (e_1) throw e_1.error; }
}
exports.defaultBrandDetails = exports.libertyNzBrandDetails;
