import { Injectable } from '@angular/core';

import { Observable, of, asapScheduler } from 'rxjs';

import { RegisterService } from './register.service';
import { RegisterDetails, RegisterResponse } from './register.types';
import { DemoApiService } from '../../shared/demo-api-service';

type IRegisterService = { [P in keyof RegisterService]: RegisterService[P] }

@Injectable()
export class DemoRegisterService implements IRegisterService {
  constructor(private api: DemoApiService) {
  }

  postRegister(registerDetails: RegisterDetails): Observable<RegisterResponse> {
    const response = this.api.postRegister(registerDetails);
    return of(response, asapScheduler);
  }
}
