"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var brand_details_service_1 = require("../../brand/brand-details.service");
var demo_api_service_1 = require("../../shared/demo-api-service");
var confirm_account_types_1 = require("../confirm/confirm-account.types");
var register_types_1 = require("../register/register.types");
var Action;
(function (Action) {
    Action["Register"] = "Register";
    Action["Activate"] = "Activate";
})(Action || (Action = {}));
var DemoHomeComponent = /** @class */ (function () {
    function DemoHomeComponent(router, api, brandDetailsService) {
        this.router = router;
        this.api = api;
        this.brandDetailsService = brandDetailsService;
        this.action = Action.Activate;
        this.canReset$ = api.isDirty$;
    }
    DemoHomeComponent.prototype.ngOnInit = function () {
        this.brandDetails = this.brandDetailsService.getBrandDetails();
    };
    Object.defineProperty(DemoHomeComponent.prototype, "privacyCheck", {
        get: function () {
            return this.api.privacyCheck ? confirm_account_types_1.PrivacyCheckTypes[this.api.privacyCheck] : 'None';
        },
        set: function (value) {
            this.api.privacyCheck = value === 'None' ? null : confirm_account_types_1.PrivacyCheckTypes[value];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoHomeComponent.prototype, "registerOutcome", {
        get: function () {
            return register_types_1.RegisterOutcomes[this.api.registerOutcome];
        },
        set: function (value) {
            this.api.registerOutcome = register_types_1.RegisterOutcomes[value];
        },
        enumerable: true,
        configurable: true
    });
    DemoHomeComponent.prototype.go = function () {
        switch (this.action) {
            case Action.Activate:
                this.router.navigate(['/IXi1asfcrk65OVFoer66LA']);
                return;
            case Action.Register:
                this.router.navigate(['/register']);
                return;
        }
    };
    DemoHomeComponent.prototype.reset = function () {
        this.api.reset();
    };
    return DemoHomeComponent;
}());
exports.DemoHomeComponent = DemoHomeComponent;
