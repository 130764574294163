import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { pluck } from 'rxjs/operators';

import { ActivationStore } from '../../store/activation-store.service';
import { ConfirmAccountService } from './confirm-account.service';

import { CheckPrivacyStatuses } from './confirm-account.types';
import { PrivacyCheckTypes } from './confirm-account.types';
import { ClientPrivacyDetails } from './confirm-account.types';

@Component({
  templateUrl: './confirm-account.component.html',
  styleUrls: [ './confirm-account.component.less' ],
  providers: [ActivationStore]
})
export class ConfirmAccountComponent implements OnInit {

  private activationId: string;
  public verificationCode: string;

  public formSubmitted: boolean;
  public firstName: string;
  public hasDriversLicence: boolean;
  public enteredPrivacyDetails: ClientPrivacyDetails;
  public privacyCheckTypeLoaded: boolean;

  private minimumAge: number;
  private maximumAge: number;
  private numberOfDays: number;

  public calendarDays: Array<number>;
  public calendarMonths: Array<{ id: number, name: string }>;
  public calendarYears: Array<number>;

  constructor(private router: Router,
              private activationStore: ActivationStore,
              private confirmAccountService: ConfirmAccountService,
              private changeDetector: ChangeDetectorRef) {

    this.activationStore.changes
      .pipe(pluck('activationId'))
      .subscribe((activationId: string) => this.activationId = activationId);

    this.activationStore.changes
      .pipe(pluck('verificationCode'))
      .subscribe((verificationCode: string) => this.verificationCode = verificationCode);

    this.activationStore.changes
      .pipe(pluck('firstName'))
      .subscribe((firstName: string) => this.firstName = firstName);

    this.formSubmitted = false;
    this.hasDriversLicence = true;
    this.privacyCheckTypeLoaded = false;

    this.minimumAge = 18;
    this.maximumAge = 110;
    this.numberOfDays = 31;
    this.enteredPrivacyDetails = new ClientPrivacyDetails();
  }

  ngOnInit() {

    if (!this.verificationCode || !this.activationId) {
      this.router.navigate(['/error']);
    } else {

      this.confirmAccountService.getPrivacyCheckType(this.activationId)
        .subscribe(privacyCheckType => {
          switch (privacyCheckType) {
            case PrivacyCheckTypes.DriversLicence:
              this.hasDriversLicence = true;
              break;
            case PrivacyCheckTypes.Address:
              this.hasDriversLicence = false;
              break;
            case PrivacyCheckTypes.RequiresContact:
              this.router.navigate(['/contact']);
              break;
            default:
              this.router.navigate(['/error']);
              break;
          }

          this.privacyCheckTypeLoaded = true;
      });

      this.calendarDays = this.calculateDays();
      this.calendarMonths = [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' }
      ];

      this.calendarYears = this.calculateYears();
    }
  }

  onSubmit() {
    this.formSubmitted = true;
    this.changeDetector.detectChanges();

    this.confirmAccountService.postPrivacyCheck(this.activationId, this.verificationCode, this.enteredPrivacyDetails)
      .subscribe(response => {
        if (response.Status === CheckPrivacyStatuses.Accepted) {
          this.router.navigate(['/setup']);
        } else if (response.Status === CheckPrivacyStatuses.Rejected) {
          this.router.navigate(['/contact']);
        } else {
          this.router.navigate(['/error']);
        }
      },
      error => {
        this.router.navigate(['/timeout']);
      });
  }

  private calculateDays() {
    let days = [];
    for (let i = 1; i <= this.numberOfDays; i++) {
      days.push(i);
    }
    return days;
  }

  public calculateYears() {

    let years = [];
    let date = new Date();
    let initialYear = date.getFullYear() - this.maximumAge;
    let endingYear = date.getFullYear() - this.minimumAge;

    for (let i = endingYear; i >= initialYear; i--) {
      years.push(i);
    }
    return years;
  }

}
