"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var brand_details_service_1 = require("../brand/brand-details.service");
var FormatMobileNumberPipe = /** @class */ (function () {
    function FormatMobileNumberPipe(brandDetailsService) {
        this.brandDetailsService = brandDetailsService;
    }
    FormatMobileNumberPipe.prototype.transform = function (value) {
        if (!value) {
            return value;
        }
        return this.brandDetailsService.getBrandDetails().mobileNumberHelper.format(value);
    };
    return FormatMobileNumberPipe;
}());
exports.FormatMobileNumberPipe = FormatMobileNumberPipe;
