import { Component } from '@angular/core';
import { BrandDetails } from '../../brand/brand-details.model';
import { BrandDetailsService } from '../../brand/brand-details.service';
@Component({
  templateUrl: './contact.component.html',
  styleUrls: [ './contact.component.less' ]
})


export class ContactComponent {
  public brandDetails: BrandDetails;

  constructor(private brandDetailsService: BrandDetailsService,
             ) {}

  ngOnInit() {
    // get brand details per brand
    this.brandDetails = this.brandDetailsService.getBrandDetails();
  }
}
