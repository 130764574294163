import { Injectable } from '@angular/core';

import { Observable, asapScheduler, of } from 'rxjs';

import { ConfirmAccountService } from './confirm-account.service';
import { DemoApiService } from '../../shared/demo-api-service';
import { PrivacyCheckTypes, ClientPrivacyDetails, CheckPrivacyResponse } from './confirm-account.types';

type IConfirmAccountService = { [P in keyof ConfirmAccountService]: ConfirmAccountService[P] };

@Injectable()
export class DemoConfirmAccountService implements IConfirmAccountService {
  constructor(private api: DemoApiService) {
  }

  getPrivacyCheckType(activationShortId: string): Observable<PrivacyCheckTypes> {
    const response = this.api.getPrivateCheckType(activationShortId);
    return of(response, asapScheduler);
  }

  postPrivacyCheck(
      activationShortId: string,
      verificationCode: string,
      privacyDetails: ClientPrivacyDetails): Observable<CheckPrivacyResponse> {
    const response = this.api.postPrivacyCheck(activationShortId, verificationCode, privacyDetails);
    return of(response, asapScheduler);
  }
}
