import { Injectable } from '@angular/core';

import { Observable, of, asapScheduler, throwError } from 'rxjs';

import { SendCodeService } from './send-code.service';
import { SendSmsResults, ActivationStatuses } from './send-code.types';
import { DemoApiService } from '../../shared/demo-api-service';
import { MobileNumberHandler } from '../../helpers';

type ISendCodeService = { [P in keyof SendCodeService]: SendCodeService[P] };

@Injectable()
export class DemoSendCodeService implements ISendCodeService {
  constructor(private api: DemoApiService, private mobileNumberHandler: MobileNumberHandler) {
  }

  postSendSmsRequest(activationShortId: string, rawMobileNumber: string): Observable<SendSmsResults> {
    const result = this.api.sendCode(activationShortId, this.mobileNumberHandler.clean(rawMobileNumber));
    return of(result, asapScheduler);
  }

  getActivationStatus(activationShortId: string): Observable<ActivationStatuses> {
    const status = this.api.getActivationStatus(activationShortId);
    return of(status || ActivationStatuses.NotFound, asapScheduler);
  }

  getFirstName(activationShortId: string): Observable<string> {
    const firstName = this.api.getFirstName(activationShortId);
    if (firstName) {
      return of(firstName, asapScheduler);
    }

    return throwError(null, asapScheduler);
  }
}
