"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./app.component.less.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./common/header/demo-header.component.ngfactory");
var i3 = require("./common/header/demo-header.component");
var i4 = require("@angular/router");
var i5 = require("./common/footer/footer.component.ngfactory");
var i6 = require("./common/footer/footer.component");
var i7 = require("./brand/brand-details.service");
var i8 = require("./pipes/format-mobile-number.pipe");
var i9 = require("./demo-app.component");
var i10 = require("@angular/platform-browser");
var i11 = require("./shared/demo-api-service");
var i12 = require("@angular/material/snack-bar");
var styles_DemoAppComponent = [i0.styles];
var RenderType_DemoAppComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DemoAppComponent, data: {} });
exports.RenderType_DemoAppComponent = RenderType_DemoAppComponent;
function View_DemoAppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "demo-header", [], null, null, null, i2.View_DemoHeaderComponent_0, i2.RenderType_DemoHeaderComponent)), i1.ɵdid(2, 49152, null, 0, i3.DemoHeaderComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "content"], ["id", "js-content-pane"], ["role", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "footer", [], null, null, null, i5.View_FooterComponent_0, i5.RenderType_FooterComponent)), i1.ɵdid(10, 114688, null, 0, i6.FooterComponent, [i7.BrandDetailsService], null, null)], function (_ck, _v) { _ck(_v, 8, 0); _ck(_v, 10, 0); }, null); }
exports.View_DemoAppComponent_0 = View_DemoAppComponent_0;
function View_DemoAppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app", [], null, null, null, View_DemoAppComponent_0, RenderType_DemoAppComponent)), i1.ɵprd(512, null, i8.FormatMobileNumberPipe, i8.FormatMobileNumberPipe, [i7.BrandDetailsService]), i1.ɵdid(2, 49152, null, 0, i9.DemoAppComponent, [i10.Title, i11.DemoApiService, i8.FormatMobileNumberPipe, i4.Router, i12.MatSnackBar], null, null)], null, null); }
exports.View_DemoAppComponent_Host_0 = View_DemoAppComponent_Host_0;
var DemoAppComponentNgFactory = i1.ɵccf("app", i9.DemoAppComponent, View_DemoAppComponent_Host_0, {}, {}, []);
exports.DemoAppComponentNgFactory = DemoAppComponentNgFactory;
