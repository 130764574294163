"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var state = {
    activationId: '',
    firstName: '',
    mobileNumber: '',
    verificationCode: '',
    logonName: ''
};
var store = new rxjs_1.BehaviorSubject(state);
var ActivationStore = /** @class */ (function () {
    function ActivationStore() {
        this.store = store;
        this.changes = store.asObservable()
            .pipe(operators_1.distinctUntilChanged());
    }
    ActivationStore.prototype.getState = function () {
        return this.store.value;
    };
    ActivationStore.prototype.setState = function (newState) {
        this.store.next(newState);
    };
    return ActivationStore;
}());
exports.ActivationStore = ActivationStore;
