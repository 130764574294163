import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApplicationInsights, SeverityLevel, ICustomProperties } from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService {
  private appInsights: ApplicationInsights | null = null;
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.applicationInsights.connectionString,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        autoTrackPageVisitTime: true,
      }
    });
  }

  load(): void {
    if (environment.applicationInsights.connectionString) {
      this.appInsights.loadAppInsights();

      this.appInsights.addTelemetryInitializer((envelope: any) => {
        envelope.tags = envelope.tags || [];
        envelope.tags.push({ 'ai.cloud.role': 'Customer Onboarding UI' });
      });

      this.logTrace('app-insights-loaded'); // Initial trace will capture the user's details (i.e. Browser, True IP, App Version...)
    }
  }

  logPageView(name?: string, url?: string): void {
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, customProperties?: ICustomProperties, severityLevel: number = SeverityLevel.Error): void {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel }, customProperties);
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}

@Injectable()
export class ApplicationInsightsServiceStub {
  load(): void {}
  logPageView(): void { }
  logEvent(): void { }
  logMetric(): void { }
  logException(): void {}
  logTrace(): void {}
}
