"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var environment_1 = require("../../../environments/environment");
function getHeap() {
    return heap;
}
var HeapService = /** @class */ (function () {
    function HeapService(document) {
        this.document = document;
    }
    Object.defineProperty(HeapService.prototype, "heap", {
        get: function () {
            return getHeap();
        },
        enumerable: true,
        configurable: true
    });
    HeapService.prototype.setHeapConfig = function () {
        // check required
        if (!environment_1.environment.heapAnalytics.include && !environment_1.environment.heapAnalytics.heapId) {
            return;
        }
        // check loaded
        if (this.isHeapLoaded()) {
            return;
        }
        this.loadHeap(environment_1.environment.heapAnalytics.heapId);
    };
    HeapService.prototype.isHeapLoaded = function () {
        try {
            return heap.loaded;
        }
        catch (e) {
            if (environment_1.environment.debugMode)
                console.log('heap is offline / not loaded');
        }
    };
    HeapService.prototype.setUserIdentity = function (identity) {
        if (environment_1.environment.production && this.isHeapLoaded())
            heap.identify(identity);
        if (environment_1.environment.debugMode)
            console.log('heap id: ' + identity);
    };
    HeapService.prototype.setUserProperties = function (userProperties) {
        if (environment_1.environment.production && this.isHeapLoaded())
            heap.addUserProperties(userProperties);
        if (environment_1.environment.debugMode)
            console.log('heap id properties: ' + userProperties);
    };
    HeapService.prototype.addEvent = function (eventName) {
        if (environment_1.environment.production && this.isHeapLoaded())
            heap.track(eventName);
        if (environment_1.environment.debugMode)
            console.log('heap event: ' + eventName);
    };
    HeapService.prototype.addEventWithProperties = function (eventName, eventProperties) {
        if (environment_1.environment.production && this.isHeapLoaded())
            heap.track(eventName, eventProperties);
        if (environment_1.environment.debugMode)
            console.log('heap event: ' + eventName + ' | properties: ' + eventProperties);
    };
    HeapService.prototype.addEventProperties = function (eventProperties) {
        if (environment_1.environment.production && this.isHeapLoaded())
            heap.addEventProperties(eventProperties);
        if (environment_1.environment.debugMode)
            console.log('heap event properties: ' + eventProperties);
    };
    HeapService.prototype.removeEventProperty = function (eventName) {
        if (environment_1.environment.production && this.isHeapLoaded())
            heap.removeEventProperty(eventName);
        if (environment_1.environment.debugMode)
            console.log('heap remove: ' + eventName);
    };
    HeapService.prototype.clearEvents = function () {
        if (environment_1.environment.production && this.isHeapLoaded())
            heap.clearHeapEvent();
        if (environment_1.environment.debugMode)
            console.log('heap clear all');
    };
    HeapService.prototype.loadHeap = function (heapId) {
        var _this = this;
        try {
            var w_1 = window;
            w_1.heap = w_1.heap || [], heap.load = function (e, t) {
                var e_1, _a;
                w_1.heap.appid = e, w_1.heap.config = t = t || {};
                var a = _this.document.createElement('script');
                a.type = 'text/javascript', a.async = !0, a.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js';
                var n = _this.document.getElementsByTagName('script')[0];
                n.parentNode.insertBefore(a, n);
                var o = function (f) {
                    return function () {
                        var args = [];
                        for (var _i = 0; _i < arguments.length; _i++) {
                            args[_i] = arguments[_i];
                        }
                        heap.push([f].concat(Array.prototype.slice.call(args, 0)));
                    };
                };
                var p = [
                    'addEventProperties',
                    'addUserProperties',
                    'clearEventProperties',
                    'identify',
                    'resetIdentity',
                    'removeEventProperty',
                    'setEventProperties',
                    'track',
                    'unsetEventProperty'
                ];
                try {
                    for (var p_1 = tslib_1.__values(p), p_1_1 = p_1.next(); !p_1_1.done; p_1_1 = p_1.next()) {
                        var g = p_1_1.value;
                        heap[g] = o(g);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (p_1_1 && !p_1_1.done && (_a = p_1.return)) _a.call(p_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            };
            heap.load(heapId);
            if (environment_1.environment.debugMode) {
                console.log('[ Heap ] Has been configured');
            }
        }
        catch (ex) {
            if (environment_1.environment.debugMode) {
                console.error('[ Heap ] Error configuring');
                console.error(ex);
            }
        }
    };
    return HeapService;
}());
exports.HeapService = HeapService;
var HeapServiceStub = /** @class */ (function () {
    function HeapServiceStub() {
    }
    HeapServiceStub.prototype.heap = function () { };
    ;
    HeapServiceStub.prototype.setHeapConfig = function () { };
    ;
    HeapServiceStub.prototype.isHeapLoaded = function () { return true; };
    ;
    HeapServiceStub.prototype.setUserIdentity = function (identity) { };
    ;
    HeapServiceStub.prototype.setUserProperties = function (userProperties) { };
    ;
    HeapServiceStub.prototype.addEvent = function (eventName) { };
    ;
    HeapServiceStub.prototype.addEventWithProperties = function (eventName, eventProperties) { };
    ;
    HeapServiceStub.prototype.addEventProperties = function (eventProperties) { };
    ;
    HeapServiceStub.prototype.removeEventProperty = function (eventName) { };
    ;
    HeapServiceStub.prototype.clearEvents = function () { };
    ;
    return HeapServiceStub;
}());
exports.HeapServiceStub = HeapServiceStub;
