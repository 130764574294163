﻿import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { pluck } from 'rxjs/operators';

import { ActivationStore } from '../../store/activation-store.service';
import { SetupAccountService } from './setup-account.service';
import { SecurityQuestions } from './setup-account.types';
import { ActivateAccountResponseStatuses } from './setup-account.types';
import { ActivateAccountResponse } from './setup-account.types';
import { SecurityQuestionSelection } from './setup-account.types';
import { BrandDetails } from '../../brand/brand-details.model';
import { BrandDetailsService } from '../../brand/brand-details.service';

@Component({
  templateUrl: './setup-account.component.html',
  styleUrls: [ './setup-account.component.less' ],
  providers: [ActivationStore]
})
export class SetupAccountComponent implements OnInit {

  public activationId: string;
  public verificationCode: string;
  public formSubmitted: boolean;
  public enteredPassword: string;
  public enteredConfirmPassword: string;
  public selectedSecurityQuestion: SecurityQuestions;
  public enteredSecurityAnswer: string;
  public brandDetails: BrandDetails;

  public securityQuestions: SecurityQuestionSelection[];

  constructor(private router: Router,
              private activationStore: ActivationStore,
              private setupAccountService: SetupAccountService,
              private changeDetector: ChangeDetectorRef,
              private brandDetailsService: BrandDetailsService) {

    this.activationStore.changes
      .pipe(pluck('activationId'))
      .subscribe((activationId: string) => this.activationId = activationId);

    this.activationStore.changes
      .pipe(pluck('verificationCode'))
      .subscribe((verificationCode: string) => this.verificationCode = verificationCode);

    this.formSubmitted = false;
  }

  ngOnInit() {

    if (!this.activationId || !this.verificationCode) {
      this.router.navigate(['/error']);
    } else {
      this.setupAccountService.getSecurityQuestions().subscribe(securityQuestions => {
        this.securityQuestions = securityQuestions;
      });

      // set default
      this.selectedSecurityQuestion = null;
    }

    this.brandDetails = this.brandDetailsService.getBrandDetails();
  }

  onSubmit() {
    this.formSubmitted = true;
    this.changeDetector.detectChanges();
    this.enteredSecurityAnswer = this.enteredSecurityAnswer.trim();

    this.setupAccountService.activateIdentityAccount(this.activationId, this.verificationCode,
      this.enteredPassword, this.selectedSecurityQuestion, this.enteredSecurityAnswer)
      .subscribe((activateAccountResult: ActivateAccountResponse) => {
        if (activateAccountResult.Status === ActivateAccountResponseStatuses.Activated) {
            const currentState = this.activationStore.getState();
            this.activationStore.setState(Object.assign({}, currentState, { logonName: activateAccountResult.LogonName }));
            this.router.navigate(['/goal']);
        } else {
            this.router.navigate(['/error']);
        }
      },
      error => {
        this.router.navigate(['/timeout']);
      });
  }
}
