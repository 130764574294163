"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("hammerjs");
var core_1 = require("@angular/core");
// css app imports
require("./assets/css/styles.css");
var __NgCli_bootstrap_1 = require("./app/demo-app.module.ngfactory");
var __NgCli_bootstrap_2 = require("@angular/platform-browser");
// enable production mode unless running locally or test
if (process.env.NODE_ENV === 'production') {
    core_1.enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.DemoAppModuleNgFactory);
