import {Pipe, PipeTransform} from '@angular/core';
import { BrandDetailsService } from '../brand/brand-details.service';

@Pipe({
  name: 'formatMobileNumber'
})

export class FormatMobileNumberPipe implements PipeTransform {

  constructor(private brandDetailsService: BrandDetailsService) { }

  transform (value: any): any {
    if (!value) {
      return value;
    }

    return this.brandDetailsService.getBrandDetails().mobileNumberHelper.format(value);
  }
}
