"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// error handlers
var error_handlers_1 = require("./error-handlers");
var ɵ0 = error_handlers_1.getErrorHandler;
exports.ɵ0 = ɵ0;
var DemoAppModule = /** @class */ (function () {
    function DemoAppModule() {
    }
    return DemoAppModule;
}());
exports.DemoAppModule = DemoAppModule;
