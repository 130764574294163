"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var register_service_1 = require("./register.service");
var register_types_1 = require("./register.types");
var heap_service_1 = require("../../shared/analytics/heap.service");
var brand_details_service_1 = require("../../brand/brand-details.service");
var DisplayState;
(function (DisplayState) {
    DisplayState[DisplayState["EnterDetails"] = 1] = "EnterDetails";
    DisplayState[DisplayState["Registered"] = 2] = "Registered";
    DisplayState[DisplayState["MoreDetailsRequired"] = 3] = "MoreDetailsRequired";
    DisplayState[DisplayState["OnlineAccountAlreadyExists"] = 4] = "OnlineAccountAlreadyExists";
})(DisplayState = exports.DisplayState || (exports.DisplayState = {}));
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(router, registerService, changeDetector, heapService, brandDetailsService) {
        this.router = router;
        this.registerService = registerService;
        this.changeDetector = changeDetector;
        this.heapService = heapService;
        this.brandDetailsService = brandDetailsService;
        this.formSubmitted = false;
        this.minimumAge = 18;
        this.maximumAge = 110;
        this.numberOfDays = 31;
        this.registerDetails = new register_types_1.RegisterDetails();
        this.displayState = DisplayState.EnterDetails;
        this.onlineAnalyticsTracking = '?utm_source=liberty&utm_medium=website&utm_content=existingbtn&utm_campaign=activate';
    }
    RegisterComponent.prototype.ngOnInit = function () {
        this.calendarDays = this.calculateDays();
        this.calendarMonths = [
            { id: 1, name: 'January' },
            { id: 2, name: 'February' },
            { id: 3, name: 'March' },
            { id: 4, name: 'April' },
            { id: 5, name: 'May' },
            { id: 6, name: 'June' },
            { id: 7, name: 'July' },
            { id: 8, name: 'August' },
            { id: 9, name: 'September' },
            { id: 10, name: 'October' },
            { id: 11, name: 'November' },
            { id: 12, name: 'December' }
        ];
        this.calendarYears = this.calculateYears();
        // get brand Details
        this.brandDetails = this.brandDetailsService.getBrandDetails();
    };
    RegisterComponent.prototype.onSubmit = function () {
        var _this = this;
        this.formSubmitted = true;
        this.changeDetector.detectChanges();
        this.registerService.postRegister(this.registerDetails)
            .subscribe(function (response) {
            switch (response.Outcome) {
                case register_types_1.RegisterOutcomes.Registered:
                    _this.addEventToHeap('Activation - Register - Email Sent');
                    _this.displayState = DisplayState.Registered;
                    break;
                case register_types_1.RegisterOutcomes.MoreDetailsRequired:
                    _this.addEventToHeap('Activation - Register - Requires Contact');
                    _this.displayState = DisplayState.MoreDetailsRequired;
                    break;
                case register_types_1.RegisterOutcomes.OnlineAccountAlreadyExists:
                    _this.addEventToHeap('Activation - Account - Already Completed');
                    _this.displayState = DisplayState.OnlineAccountAlreadyExists;
                    break;
                default:
                    _this.addEventToHeap('Activation - Register - Unknown Outcome');
                    _this.router.navigate(['/error']);
                    break;
            }
        }, function (error) {
            _this.router.navigate(['/timeout']);
        });
    };
    RegisterComponent.prototype.calculateDays = function () {
        var days = [];
        for (var i = 1; i <= this.numberOfDays; i++) {
            days.push(i);
        }
        return days;
    };
    RegisterComponent.prototype.calculateYears = function () {
        var years = [];
        var date = new Date();
        var initialYear = date.getFullYear() - this.maximumAge;
        var endingYear = date.getFullYear() - this.minimumAge;
        for (var i = endingYear; i >= initialYear; i--) {
            years.push(i);
        }
        return years;
    };
    RegisterComponent.prototype.redirectToOnlineSite = function () {
        window.location.href = this.brandDetails.onlineSiteUrl + this.onlineAnalyticsTracking;
    };
    RegisterComponent.prototype.addEventToHeap = function (event) {
        this.heapService.addEvent(event);
    };
    return RegisterComponent;
}());
exports.RegisterComponent = RegisterComponent;
