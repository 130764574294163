import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse
} from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable()
export class LogErrorInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap(
        event => {},
        error => {
          if (error instanceof HttpErrorResponse) {
            const errMsg = `${error.status} - ${error.statusText}, ${JSON.stringify(error.error) || ''}, ${error.message || ''}`;

            console.error(errMsg);
          }
        }
      )
    );
  }
}
