import { ErrorHandler, Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';

import { SentryService } from '../shared';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error: any): void {
    Sentry.captureException(error.originalError || error);
  }
}

export function getErrorHandler(sentry: SentryService): ErrorHandler {
  if (sentry.isEnabled) {
    sentry.init();
    return new SentryErrorHandler();
  }

  return new ErrorHandler();
}
