"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".input-group[_ngcontent-%COMP%]   .form-control[_ngcontent-%COMP%]:first-child{border-right:0}.password-helper[_ngcontent-%COMP%]{color:#a2a2a2}.password-helper[_ngcontent-%COMP%]   .icon[_ngcontent-%COMP%]:before{padding:0;font-size:18px}"];
exports.styles = styles;
