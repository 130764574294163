import { NgModule }              from '@angular/core';
import { RouterModule, Routes }  from '@angular/router';

import { SendCodeComponent }   from './pages/send/send-code.component';
import { VerifyCodeComponent }   from './pages/verify/verify-code.component';
import { ConfirmAccountComponent }   from './pages/confirm/confirm-account.component';
import { SetupAccountComponent } from './pages/setup/setup-account.component';
import { ClientGoalsComponent } from './pages/client-goals/client-goals.component';
import { CompletedComponent }   from './pages/completed/completed.component';
import { ContactComponent }   from './pages/contact/contact.component';
import { ErrorComponent }   from './pages/error/error.component';
import { TimeoutComponent }   from './pages/timeout/timeout.component';
import { RegisterComponent } from './pages/register/register.component';
import { DemoHomeComponent } from './pages/demo-home/demo-home.component';
import { environment } from '../environments/environment';

const appRoutes: Routes = [
  { path: '', component: DemoHomeComponent, pathMatch: 'full' },
  { path: 'error', component: ErrorComponent },
  { path: 'timeout', component: TimeoutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'verify', component: VerifyCodeComponent },
  { path: 'confirm', component: ConfirmAccountComponent },
  { path: 'setup', component: SetupAccountComponent },
  { path: 'goal', component: ClientGoalsComponent },
  { path: 'complete', component: CompletedComponent },
  { path: 'register', component: RegisterComponent },
  { path: ':id', component: SendCodeComponent },
  { path: '**', redirectTo: 'error' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { enableTracing: environment.debugMode } )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
