<div class="password-strength" #passwordStrength>
  <div *ngIf="showStrengthLabel" class="strength-label">{{ strengthLabel }}</div>
  <ul class="strength-bar">
    <li class="strength-bar-item" [style.background-color]="bar0"></li>
    <li class="strength-bar-item" [style.background-color]="bar1"></li>
    <li class="strength-bar-item" [style.background-color]="bar2"></li>
    <li class="strength-bar-item" [style.background-color]="bar3"></li>
    <li class="strength-bar-item" [style.background-color]="bar4"></li>
  </ul>
</div>
