import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,

} from '@angular/common/http';

import { tap } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';

import { NoopInterceptor } from './noop-interceptor';
import { SentryService } from '../shared';

@Injectable()
export class SentryErrorInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap(
        event => { },
        error => {
          Sentry.withScope(scope => {
            scope.setExtra('url', req.url);
            Sentry.captureException(error.error);
          });
        }
      )
    );
  }
}

export function getSentryErrorInterceptor(sentry: SentryService) {
  if (sentry.isEnabled) {
    sentry.init();
    return new SentryErrorInterceptor();
  }

  return new NoopInterceptor();
}
