import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';

declare var heap: any;

function getHeap (): any {
  return heap;
}

@Injectable()
export class HeapService {

  constructor(
    @Inject(DOCUMENT) private document: Document) {
  }

  get heap(): any {
    return getHeap();
  }

  public setHeapConfig(): void {

    // check required
    if (!environment.heapAnalytics.include && !environment.heapAnalytics.heapId) {
      return;
    }

    // check loaded
    if (this.isHeapLoaded()) {
      return;
    }

    this.loadHeap(environment.heapAnalytics.heapId);
  }

  public isHeapLoaded(): boolean {
    try {
      return heap.loaded;
    } catch (e) {
      if (environment.debugMode)
        console.log('heap is offline / not loaded');
    }
  }

  public setUserIdentity(identity: string): void {
    if (environment.production && this.isHeapLoaded())
      heap.identify(identity);

    if (environment.debugMode)
      console.log('heap id: ' + identity);
  }

  public setUserProperties(userProperties: string): void {
    if (environment.production && this.isHeapLoaded())
      heap.addUserProperties(userProperties);

    if (environment.debugMode)
      console.log('heap id properties: ' + userProperties);
  }

  public addEvent(eventName: string): void {
    if (environment.production && this.isHeapLoaded())
      heap.track(eventName);

    if (environment.debugMode)
      console.log('heap event: ' + eventName);
  }

  public addEventWithProperties(eventName: string, eventProperties: string): void {
    if (environment.production && this.isHeapLoaded())
      heap.track(eventName, eventProperties);

    if (environment.debugMode)
      console.log('heap event: ' + eventName + ' | properties: ' + eventProperties);
  }

  public addEventProperties(eventProperties: string): void {
    if (environment.production && this.isHeapLoaded())
      heap.addEventProperties(eventProperties);

    if (environment.debugMode)
      console.log('heap event properties: ' + eventProperties);
  }

  public removeEventProperty(eventName: string): void {
    if (environment.production && this.isHeapLoaded())
      heap.removeEventProperty(eventName);

    if (environment.debugMode)
      console.log('heap remove: ' + eventName);
  }

  public clearEvents(): void {
    if (environment.production && this.isHeapLoaded())
      heap.clearHeapEvent();

    if (environment.debugMode)
      console.log('heap clear all');
  }

  private loadHeap(heapId: any): void {
    try {
      const w = (window as any);
      w.heap = w.heap || [], heap.load = (e: any, t: any) => {
        w.heap.appid = e, w.heap.config = t = t || {};

        const a = this.document.createElement('script');
        a.type = 'text/javascript', a.async = !0, a.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js';

        const n = this.document.getElementsByTagName('script')[0];
        n.parentNode.insertBefore(a, n);

        const o = (f: any) => {
          return (...args: any[]) => {
            heap.push([f].concat(Array.prototype.slice.call(args, 0)));
          };
        };
        const p = [
          'addEventProperties',
          'addUserProperties',
          'clearEventProperties',
          'identify',
          'resetIdentity',
          'removeEventProperty',
          'setEventProperties',
          'track',
          'unsetEventProperty'];

        for (const g of p) {
          heap[g] = o(g);
        }
      };

      heap.load(heapId);

      if (environment.debugMode) {
        console.log('[ Heap ] Has been configured');
      }
    } catch (ex) {
      if (environment.debugMode) {
        console.error('[ Heap ] Error configuring');
        console.error(ex);
      }
    }
  }
}

@Injectable()
export class HeapServiceStub {
  heap() { };
  setHeapConfig() { };
  isHeapLoaded() { return true; };
  setUserIdentity(identity: string) {};
  setUserProperties(userProperties: string) {};
  addEvent(eventName: string) {};
  addEventWithProperties(eventName: string, eventProperties: string) {};
  addEventProperties(eventProperties: string) {};
  removeEventProperty(eventName: string) {};
  clearEvents() {};
}
