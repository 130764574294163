<div class="panel panel-default">
  <div class="panel-heading">
    <h1 class="panel-title">Activate your Online Account</h1>
  </div>
  <div class="panel-body">

    <p>We're sorry, we are unable to continue with your activation at this time.</p>
    <p>It looks like the server is taking to long to respond, this can be caused by either poor connectivity or an error with our servers.</p>
    <p>Please try again later by following the link from your email to restart the activation process.</p>
    <p>If this problem persists, please contact us on <a id="link-contact" href="tel:{{ brandDetails.phoneNumber }}" title="contact {{ brandDetails.shortName }}">{{ brandDetails.phoneNumber }}</a>.</p>

  </div>
</div>
