import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { RegisterService } from './register.service';
import { RegisterDetails, RegisterOutcomes } from './register.types';
import { HeapService } from '../../shared/analytics/heap.service';
import { BrandDetailsService } from '../../brand/brand-details.service';
import { BrandDetails } from '../../brand/brand-details.model';

export enum DisplayState {
  EnterDetails = 1,
  Registered = 2,
  MoreDetailsRequired = 3,
  OnlineAccountAlreadyExists = 4
}

@Component({
  templateUrl: './register.component.html',
  styleUrls: [ './register.component.less' ],
})
export class RegisterComponent implements OnInit {

  public formSubmitted: boolean;
  public registerDetails: RegisterDetails;
  public displayState: DisplayState;

  private minimumAge: number;
  private maximumAge: number;
  private numberOfDays: number;
  public brandDetails: BrandDetails;

  public calendarDays: Array<number>;
  public calendarMonths: Array<{ id: number, name: string }>;
  public calendarYears: Array<number>;

  private onlineAnalyticsTracking: string;

  constructor(private router: Router,
              private registerService: RegisterService,
              private changeDetector: ChangeDetectorRef,
              private heapService: HeapService,
              private brandDetailsService: BrandDetailsService) {

    this.formSubmitted = false;

    this.minimumAge = 18;
    this.maximumAge = 110;
    this.numberOfDays = 31;
    this.registerDetails = new RegisterDetails();

    this.displayState = DisplayState.EnterDetails;

    this.onlineAnalyticsTracking = '?utm_source=liberty&utm_medium=website&utm_content=existingbtn&utm_campaign=activate';
  }

  ngOnInit() {

      this.calendarDays = this.calculateDays();
      this.calendarMonths = [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' }
      ];

      this.calendarYears = this.calculateYears();

      // get brand Details
      this.brandDetails = this.brandDetailsService.getBrandDetails();
    }

  onSubmit() {
    this.formSubmitted = true;
    this.changeDetector.detectChanges();

    this.registerService.postRegister(this.registerDetails)
      .subscribe(response => {
        switch (response.Outcome) {
          case RegisterOutcomes.Registered:
            this.addEventToHeap('Activation - Register - Email Sent');
            this.displayState = DisplayState.Registered;
            break;

          case RegisterOutcomes.MoreDetailsRequired:
            this.addEventToHeap('Activation - Register - Requires Contact');
            this.displayState = DisplayState.MoreDetailsRequired;
            break;

          case RegisterOutcomes.OnlineAccountAlreadyExists:
            this.addEventToHeap('Activation - Account - Already Completed');
            this.displayState = DisplayState.OnlineAccountAlreadyExists;
            break;

          default:
            this.addEventToHeap('Activation - Register - Unknown Outcome');
            this.router.navigate(['/error']);
            break;
        }
      },
      error => {
        this.router.navigate(['/timeout']);
      });
  }

  private calculateDays() {
    let days = [];
    for (let i = 1; i <= this.numberOfDays; i++) {
      days.push(i);
    }
    return days;
  }

  public calculateYears() {

    let years = [];
    let date = new Date();
    let initialYear = date.getFullYear() - this.maximumAge;
    let endingYear = date.getFullYear() - this.minimumAge;

    for (let i = endingYear; i >= initialYear; i--) {
      years.push(i);
    }
    return years;
  }

  public redirectToOnlineSite(): void {
    window.location.href = this.brandDetails.onlineSiteUrl + this.onlineAnalyticsTracking;
  }

  private addEventToHeap(event: string): void {
    this.heapService.addEvent(event);
  }
}
