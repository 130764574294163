<div class="panel panel-default">
  <div class="panel-heading">
    <h1 class="panel-title">Register your online account</h1>
  </div>
  <div class="panel-body">

    <!-- register states -->
    <div [ngSwitch]="displayState">

      <!-- register / start -->
      <div *ngSwitchCase="1">

        <p>To set up your online account, we just need a few details to get started.</p>
        <p>Your loan account number is located on your loan agreement or at the top of your statement. If you're unsure of your loan account number contact our team on <a id="link-contact" href="tel:{{brandDetails.phoneNumber}}" title="contact {{ brandDetails.shortName }}">{{brandDetails.phoneNumber}}</a> for assistance.</p>

        <!-- form / register -->
        <form id="form-register-account" class="form-register-account form-horizontal" role="form"
              (ngSubmit)="onSubmit()" #registerAccountForm="ngForm" novalidate>

          <fieldset [disabled]="formSubmitted">

            <!-- input / account number -->
            <div class="form-group" [ngClass]="{ 'has-error' : accountNumber.errors && accountNumber.touched }">

              <label for="accountNumber" class="control-label col-sm-5">Enter your loan number</label>
              <div class="col-sm-6">

                <input type="text" name="accountNumber" id="accountNumber" class="form-control"
                       [(ngModel)]="registerDetails.accountNumber"
                       #accountNumber="ngModel"
                       placeholder="your loan number"
                       autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                       required aria-required="true"
                       noWhiteSpace
                       maxlength="9"
                       pattern="^[0-9]{1,9}$">

                <div class="help-block" for="accountNumber" *ngIf="accountNumber.errors && accountNumber.touched">
                  <span *ngIf="accountNumber.errors?.required || accountNumber.errors?.noWhiteSpace">Please enter your loan number</span>
                  <span *ngIf="accountNumber.errors?.pattern && !(accountNumber.errors?.required || accountNumber.errors?.noWhiteSpace)">Please enter a valid loan number</span>
                </div>

              </div>
            </div>
            <!-- END: input / account number -->

            <!-- input / surname -->
            <div class="form-group" [ngClass]="{ 'has-error' : surname.errors && surname.touched }">

              <label for="surname" class="control-label col-sm-5">Enter your surname</label>
              <div class="col-sm-6">

                <input type="text" name="surname" id="surname" class="form-control"
                       [(ngModel)]="registerDetails.surname"
                       #surname="ngModel"
                       placeholder="your surname"
                       autocomplete="family-name" autocorrect="off" autocapitalize="off" spellcheck="false"
                       required aria-required="true"
                       noWhiteSpace
                       maxlength="100"
                       pattern="^[a-zA-Z\s\-']{1,100}$">

                <div class="help-block" for="surname" *ngIf="surname.errors && surname.touched">
                  <span *ngIf="surname.errors?.required || surname.errors?.noWhiteSpace">Please enter your surname</span>
                  <span *ngIf="surname.errors?.pattern && !(surname.errors?.required || surname.errors?.noWhiteSpace)">Please enter a valid surname</span>
                </div>

              </div>
            </div>
            <!-- END: input / surname -->

            <!-- input / date of birth -->
            <div class="form-group" [ngClass]="{ 'has-error' : ((dobDay.errors && dobDay.touched) || (dobMonth.errors && dobMonth.touched) || (dobYear.errors && dobYear.touched)) }">
              <label for="dateOfBirth" class="control-label col-sm-5">Select your date of birth</label>

              <div class="col-sm-6">
                <div class="row">
                  <div class="col-xs-3 col-sm-4">

                    <!-- dob / day -->
                    <div class="form-group-select">
                      <select name="dobDay" id="dobDay" class="form-control"
                              [(ngModel)]="registerDetails.dateOfBirthDay"
                              #dobDay="ngModel"
                              required aria-required="true"
                              autocomplete="bday-day"
                              data-hj-masked>
                        <option [ngValue]="null" selected disabled="disabled">Day</option>
                        <option *ngFor="let day of calendarDays | slice:0:numberOfDays; let i=index" [ngValue]="day">{{ day }}</option>
                      </select>
                    </div>
                    <!-- END: dob / day -->

                  </div>
                  <div class="col-xs-5 col-sm-4 offset-pl-0">

                    <!-- dob / month -->
                    <div class="form-group-select">
                      <select name="dobMonth" id="dobMonth" class="form-control"
                              [(ngModel)]="registerDetails.dateOfBirthMonth"
                              #dobMonth="ngModel"
                              required aria-required="true"
                              autocomplete="bday-month"
                              data-hj-masked>
                        <option [ngValue]="null" selected disabled="disabled">Month</option>
                        <option *ngFor="let month of calendarMonths" [ngValue]="month.id">{{ month.name }}</option>
                      </select>
                    </div>
                    <!-- END: dob / month -->

                  </div>
                  <div class="col-xs-4 offset-pl-0">

                    <!-- dob / year -->
                    <div class="form-group-select">
                      <select name="dobYear" id="dobYear" class="form-control"
                              [(ngModel)]="registerDetails.dateOfBirthYear"
                              #dobYear="ngModel"
                              required aria-required="true"
                              autocomplete="bday-year"
                              data-hj-masked>
                        <option [ngValue]="null" selected disabled="disabled">Year</option>
                        <option *ngFor="let year of calendarYears" [ngValue]="year">{{ year }}</option>
                      </select>
                    </div>
                    <!-- END: dob / year -->

                  </div>
                </div>

                <!-- dob / help messages -->
                <div class="help-block" *ngIf="dobDay.errors || dobMonth.errors || dobYear.errors">
                  <span *ngIf="dobDay.errors?.required || dobMonth.errors?.required || dobYear.errors?.required">Please select your date of birth</span>
                </div>
                <!-- END: dob / help messages -->

              </div>
            </div>
            <!-- END: input / date of birth -->

            <!-- btn / register -->
            <div class="form-group">
              <div class="col-sm-6 col-sm-offset-5">
                <button type="submit" id="form-btn-submit" class="btn btn-block btn-primary fx-fade-color"
                        [disabled]="!registerAccountForm.valid"
                        [ngClass]="{ 'loading' : formSubmitted }">
                  <span class="loader loader-spinner"></span>
                  <span class="btn-label">Register</span>
                </button>
              </div>
            </div>
            <!-- END: btn / register -->

          </fieldset>
        </form>
        <!-- END: form / register -->

      </div>
      <!-- END: register / start -->

      <!-- register / success -->
      <div *ngSwitchCase="2">

        <p>You will now receive an activation email with a link and instructions to activate your online account.</p>
        <p>If you don't receive the email within a few minutes, please check your please check your spam/junk folder. Alternatively, you can contact our team on <a id="link-contact" href="tel:{{brandDetails.phoneNumber}}" title="contact {{ brandDetails.shortName }}">{{brandDetails.phoneNumber}}</a> for assistance.</p>

      </div>
      <!-- END: register / success -->

      <!-- register / contact -->
      <div *ngSwitchCase="3">

        <p>To protect your account, we just need a little more information to confirm it's really you.</p>
        <p>Please contact us on <a id="linkContact" href="tel:{{ brandDetails.phoneNumber }}" title="contact {{ brandDetails.shortName }}"> {{ brandDetails.phoneNumber }}</a> to complete. You may be required to supply some personal details to confirm your identity.</p>

      </div>
      <!-- END: register / contact -->

      <!-- register / already exists -->
      <div *ngSwitchCase="4">

        <p>Your online account has already been successfully activated. To access, click the link below.</p>
        <button type="button" id="btn-redirect-online" class="btn btn-primary offset-mt-10 fx-fade-color"
          (click)="redirectToOnlineSite();">
            <span class="btn-label">Access your Online Account</span>
        </button>

      </div>
      <!-- END: register / already exists -->

    </div>
    <!-- END: register states -->

  </div>
</div>
