<div class="panel panel-default">
  <div class="panel-heading">
    <h1 class="panel-title">Enter your Activation Code</h1>
  </div>
  <div class="panel-body">

    <!-- panel / resend code -->
    <div class="alert alert-danger" role="alert" [@fadeInOut]="resendVerificationCodeInvalid">
      <span>There was a problem resending your verification code. <a [routerLink]="" (click)="returnToVerifyMobile()">Try again.</a></span>
    </div>

    <div class="alert alert-info text-center" role="alert" [@fadeInOut]="showResendCode">
      <span>Haven't received your code? <a [routerLink]="" (click)="resendCode()">Resend activation code</a></span>
    </div>
    <!-- END: panel / resend code -->

    <!-- panel / incorrect code -->
    <div class="alert alert-danger" role="alert" [@fadeInOut]="invalidCodeSubmitted">
      <span>The activation code that was entered does not match or has expired. Please check you've entered the code correctly and try again.</span>
    </div>
    <!-- END: panel / incorrect code -->

    <p>A text message with the activation code has been sent to <strong><span data-hj-masked>{{ mobileNumber }}</span></strong>.</p>
    <p>Please note that activation codes are only valid for 20 minutes. After this time, you will need to request a new code.</p>

    <!-- form / verify -->
    <form id="form-verify-code" class="form-verify-code form-horizontal" role="form"
      (ngSubmit)="onSubmit()" #verifyCodeForm="ngForm" novalidate>

      <fieldset [disabled]="formSubmitted">

        <!-- input / verify code -->
        <div class="form-group" [ngClass]="{ 'has-error' : verificationCode.errors && verificationCode.touched }">

          <label for="verificationCode" class="control-label col-sm-5">Enter your activation code</label>
          <div class="col-sm-5">
            <input type="text" name="verificationCode" id="verificationCode" class="form-control"
              [(ngModel)]="enteredVerificationCode"
              #verificationCode="ngModel"
              [textMask]="{ mask: maskVerificationCode, guide: false }"
              placeholder="XXX-XXX"
              autocomplete="off" autocorrect="off" autocapitalize="characters" spellcheck="false"
              required aria-required="true"
              minlength="7"
              maxlength="7"
              pattern="[a-zA-Z0-9\-]{7}">

            <div class="help-block" for="verificationCode" *ngIf="verificationCode.errors && verificationCode.touched">
              <span *ngIf="verificationCode.errors?.required">Please enter your activation code.</span>
              <span *ngIf="verificationCode.errors?.maxlength">Activation code cannot be more than 6 characters long.</span>
              <span *ngIf="verificationCode.errors?.pattern || verificationCode.errors?.minlength">Please enter a valid activation code.</span>
            </div>

          </div>
        </div>
        <!-- END: input / verify code -->

        <!-- btn / verify -->
        <div class="form-group">
          <div class="col-sm-5 col-sm-offset-5">
            <button type="submit" id="form-btn-submit" class="btn btn-block btn-primary fx-fade-color"
              [disabled]="!verifyCodeForm.valid"
              [ngClass]="{ 'loading' : formSubmitted }">
                <span class="loader loader-spinner"></span>
                <span class="btn-label">Verify Activation Code</span>
            </button>
          </div>
        </div>
        <!-- END: btn / verify -->

      </fieldset>
    </form>
    <!-- END: form / verify -->

  </div>
</div>
