"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var activation_store_service_1 = require("../../store/activation-store.service");
var verify_code_service_1 = require("./verify-code.service");
var verify_code_types_1 = require("./verify-code.types");
var verify_code_types_2 = require("./verify-code.types");
var environment_1 = require("../../../environments/environment");
var VerifyCodeComponent = /** @class */ (function () {
    function VerifyCodeComponent(router, activationStore, verifyCodeService, changeDetector) {
        var _this = this;
        this.router = router;
        this.activationStore = activationStore;
        this.verifyCodeService = verifyCodeService;
        this.changeDetector = changeDetector;
        this.maskVerificationCode = [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, '-', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/];
        this.activationStore.changes
            .pipe(operators_1.pluck('activationId'))
            .subscribe(function (activationId) { return _this.activationId = activationId; });
        this.activationStore.changes
            .pipe(operators_1.pluck('mobileNumber'))
            .subscribe(function (mobileNumber) { return _this.mobileNumber = mobileNumber; });
        this.invalidCodeSubmitted = false;
        this.formSubmitted = false;
        this.showResendCode = false;
        this.resendVerificationCodeInvalid = false;
        setTimeout(function () {
            _this.showResendCode = true;
        }, environment_1.environment.resendShowAfterSeconds * 1000);
    }
    VerifyCodeComponent.prototype.ngOnInit = function () {
        if (!this.activationId) {
            this.router.navigate(['/error']);
        }
    };
    VerifyCodeComponent.prototype.onSubmit = function () {
        var _this = this;
        this.invalidCodeSubmitted = false;
        this.formSubmitted = true;
        this.changeDetector.detectChanges();
        var verificationCode = this.enteredVerificationCode.trim().replace(/\-+/g, '').toUpperCase();
        this.verifyCodeService.validateVerificationCode(this.activationId, verificationCode)
            .subscribe(function (validateResult) {
            var status = validateResult.ValidateResult;
            if (status === verify_code_types_1.ValidateResults.ValidVerificationCode) {
                _this.invalidCodeSubmitted = false;
                var currentState = _this.activationStore.getState();
                _this.activationStore.setState(Object.assign({}, currentState, { verificationCode: verificationCode }));
                _this.router.navigate(['/setup']);
            }
            else if (status === verify_code_types_1.ValidateResults.PrivacyCheckRequired) {
                _this.invalidCodeSubmitted = false;
                var currentState = _this.activationStore.getState();
                _this.activationStore.setState(Object.assign({}, currentState, { verificationCode: verificationCode }));
                _this.router.navigate(['/confirm']);
            }
            else if (status === verify_code_types_1.ValidateResults.InvalidVerificationCode) {
                _this.invalidCodeSubmitted = true;
                _this.formSubmitted = false;
            }
            else {
                _this.router.navigate(['/error']);
            }
        }, function (error) {
            _this.router.navigate(['/timeout']);
        });
    };
    VerifyCodeComponent.prototype.resendCode = function () {
        var _this = this;
        this.verifyCodeService.resendVerificationCode(this.activationId, this.mobileNumber)
            .subscribe(function (sendResult) {
            if (sendResult === verify_code_types_2.SendSmsResults.Success) {
                _this.showResendCode = false;
                setTimeout(function () {
                    _this.showResendCode = true;
                }, environment_1.environment.resendShowAfterSeconds * 1000);
            }
            else {
                _this.showResendCode = false;
                _this.resendVerificationCodeInvalid = true;
            }
        }, function (error) {
            _this.router.navigate(['/error']);
        });
    };
    VerifyCodeComponent.prototype.returnToVerifyMobile = function () {
        this.router.navigate(['/' + this.activationId]);
    };
    return VerifyCodeComponent;
}());
exports.VerifyCodeComponent = VerifyCodeComponent;
