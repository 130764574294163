﻿<div class="panel panel-default">
  <div class="panel-heading">
    <h1 class="panel-title">Set up your Account</h1>
  </div>
  <div class="panel-body">

    <p>You're almost there! To complete your online account set up, simply create a secure password for your account and register your security question.</p>

    <!-- form / setup -->
    <form id="form-setup-account" class="form-setup-account form-horizontal" role="form"
      (ngSubmit)="onSubmit()" #setupAccountForm="ngForm" novalidate>

      <fieldset [disabled]="formSubmitted">

        <!-- input / password -->
        <div class="form-group" [ngClass]="{ 'has-error' : password.errors && password.touched }">

          <label for="password" class="control-label col-xs-12 col-sm-5">
            Create a password
          </label>

          <div class="col-xs-12 col-sm-5">

            <div class="input-group">
              <input type="password" name="password" id="password" class="form-control"
                [(ngModel)]="enteredPassword"
                #password="ngModel"
                validateEqual="confirmPassword" reverse="true"
                placeholder="new password"
                autocomplete="off" autocorrect="off" autocapitalize="off"
                required aria-required="true"
                minlength="8"
                maxlength="50"
                pattern="((?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).{8,50})">

              <div class="input-group-addon password-helper">
                <span class="icon fa fa-info-circle cursor-help"
                  container="body" placement="left"
                  tooltip="Password must contain uppercase and lowercase letters, numbers and be at least 8 characters long">
                </span>
              </div>
            </div>

            <!-- strength bar -->
            <password-strength-bar [passwordToCheck]="enteredPassword" [showStrengthLabel]="false"></password-strength-bar>
            <!-- END: strength bar -->

            <div class="help-block" for="password" *ngIf="password.errors && password.touched">
              <span *ngIf="password.errors?.required">Please enter your password</span>
              <span *ngIf="password.errors?.minlength">Password must be more than 8 characters</span>
              <span *ngIf="password.errors?.maxlength">Password cannot be more than 50 characters</span>
              <span *ngIf="password.errors?.pattern && !password.errors?.minlength">A more complex password is required, include upper and lowercase letters, and at least one number.</span>
            </div>

          </div>
        </div>
        <!-- END: input / password -->

        <!-- input / confirm password -->
        <div class="form-group"  [ngClass]="{ 'has-error' : confirmPassword.errors && confirmPassword.touched && (confirmPassword.errors?.required || !password.errors?.required) }">

          <label for="confirmPassword" class="control-label col-sm-5">Confirm new password</label>
          <div class="col-sm-5">
            <input type="password" name="confirmPassword" id="confirmPassword" class="form-control"
              [(ngModel)]="enteredConfirmPassword"
              #confirmPassword="ngModel"
              validateEqual="password"
              paste-stop-propagation
              placeholder="confirm password"
              autocomplete="off" autocorrect="off" autocapitalize="off"
              required aria-required="true"
              maxlength="50">

            <div class="help-block" for="confirmPassword" *ngIf="confirmPassword.errors && confirmPassword.touched">
              <span *ngIf="confirmPassword.errors?.required || confirmPassword.value === ''">Re-enter your new password</span>
              <span *ngIf="confirmPassword.errors?.validateEqual && !password.errors?.required && (!confirmPassword.errors?.required && confirmPassword.value !== '')">Confirmation password does not match</span>
            </div>

          </div>
        </div>
        <!-- END: input / confirm password -->

        <!-- security question -->
        <p class="offset-pb-15">Please select and answer your security question.</p>

        <!-- input / security question -->
        <div class="form-group" [ngClass]="{ 'has-error' : securityQuestion.errors && securityQuestion.touched }">

          <label for="securityQuestion" class="control-label col-sm-5">Select a question</label>
          <div class="col-sm-5">

            <div class="form-group-select">
              <select name="securityQuestion" id="securityQuestion" class="form-control"
                [(ngModel)]="selectedSecurityQuestion"
                #securityQuestion="ngModel"
                required aria-required="true">
                  <option [ngValue]="null" disabled="disabled">Please select</option>
                  <option *ngFor="let question of securityQuestions" [value]="question.SecurityQuestion">{{question.Text}}</option>
              </select>
            </div>

            <div class="help-block" for="securityQuestion" *ngIf="securityQuestion.errors && securityQuestion.touched">
              <span *ngIf="securityQuestion.errors?.required">Please select a question</span>
            </div>

          </div>
        </div>
        <!-- END: input / security question -->

        <!-- input / security answer -->
        <div class="form-group" [ngClass]="{ 'has-error' : securityAnswer.errors && securityAnswer.touched }">
          <label for="securityAnswer" class="control-label col-sm-5">Enter security answer</label>
          <div class="col-sm-5">
            <input type="text" name="securityAnswer" id="securityAnswer" class="form-control"
              [(ngModel)]="enteredSecurityAnswer"
              #securityAnswer="ngModel"
              placeholder="your answer"
              autocomplete="off" autocorrect="off" autocapitalize="off"
              noWhiteSpace
              required aria-required="true">

            <div class="help-block" for="securityAnswer" *ngIf="securityAnswer.errors && securityAnswer.touched">
              <span *ngIf="securityAnswer.errors?.required || securityAnswer.errors?.noWhiteSpace">Please enter your answer</span>
            </div>

          </div>
        </div>
        <!-- END: input / security answer -->

        <!-- btn / activate -->
        <div class="form-group">
          <div class="col-sm-5 col-sm-offset-5">
            <button type="submit" id="form-btn-submit" class="btn btn-block btn-primary fx-fade-color"
              [disabled]="!setupAccountForm.valid"
              [ngClass]="{ 'loading' : formSubmitted }">
                <span class="loader loader-spinner"></span>
                <span class="btn-label">Activate Account</span>
            </button>
          </div>
        </div>
        <!-- END: btn / activate -->

      </fieldset>
    </form>
    <!-- END: form / setup -->

  </div>
</div>
