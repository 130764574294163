import { Injectable } from '@angular/core';
import { Mask, PhoneNumberHelper } from './base.helper';

@Injectable()
export class MobileNumberHandler {

  // clean
  public clean(mobileNumber: string) {
    return mobileNumber.trim().replace(/\s+/g, '');
  }

  // validate
  public validate(rawMobileNumber: string) {
    let mobileNumber = this.clean(rawMobileNumber);
    return mobileNumber.length === 10;
  }
}

export class AuMobileNumberHelper extends PhoneNumberHelper {
  constructor() {
    super(
      /^(?:\+?61|0|)4(?<freeDigits>\d{8})$/,
      '04XX XXX XXX',
      [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
    );
  }
  public format(num: string): string | null {
    const match = this.clean(num).match(this.pattern);
    if (!match) return null;

    const { freeDigits } = match.groups;
    const d01 = freeDigits.slice(0, 2);
    const d234 = freeDigits.slice(2, 5);
    const d567 = freeDigits.slice(5);
    return `04${d01} ${d234} ${d567}`;
  }
  public getMask(): Mask {
    const tail = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
    return val => {
      if (/^\+/.test(val)) { // International with plus
        return ['+', /\d/, /\d/, ' ', ...tail];
      } else if (/^6/.test(val)) { // International without plus
        return [/\d/, /\d/, ' ', ...tail];
      } else if (/^4/.test(val)) { // National number without trunk
        return tail;
      } else { // Default to national number with trunk
        return [/\d/, ...tail];
      }
    }
  }
}

export class NzMobileNumberHelper extends PhoneNumberHelper {
  constructor() {
    super(
      /^(?:\+?64|0|)2(?<freeDigits>\d{6,11})$/, '02X XXXX XXXX', [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
    );
  }

  public format(num: string): string | null {
    const match = this.clean(num).match(this.pattern);
    if (!match) return null;

    const { freeDigits } = match.groups;
    const d0 = freeDigits.slice(0, 1);
    if (freeDigits.length < 9) {
      const d123 = freeDigits.slice(1, 4);
      const d456opt7 = freeDigits.slice(4);
      return `02${d0} ${d123} ${d456opt7}`;
    } else {
      const d1234 = freeDigits.slice(1, 5);
      const d5678 = freeDigits.slice(5);
      return `02${d0} ${d1234} ${d5678}`;
    }
  }

  public getMask() {
    // For NZ phone numbers with 9 digits after the area code, split into 4+4 instead of 3+5.
    const shortTail = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/];
    const longTail = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
    return (val: string) => {
      let freeDigits
      if (this.clean(val).match(this.pattern)) {
        if (this.clean(val).match(this.pattern).groups) {
        freeDigits = this.clean(val).match(this.pattern).groups.freeDigits;
        }
      }

      const tail = freeDigits && freeDigits.length >= 9 ? longTail : shortTail;
      if (/^\+/.test(val)) { // International with plus
        return ['+', /\d/, /\d/, ' ', ...tail];
      } else if (/^6/.test(val)) { // International without plus
        return [/\d/, /\d/, ' ', ...tail];
      } else if (/^2/.test(val)) { // National number without trunk
        return tail;
      } else { // Default to national number with trunk
        return [/\d/, ...tail];
      }
    }
  }
}