import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { BrandDetails } from '../../brand/brand-details.model';
import { BrandDetailsService } from '../../brand/brand-details.service';

import { DemoApiService } from '../../shared/demo-api-service';
import { PrivacyCheckTypes } from '../confirm/confirm-account.types';
import { RegisterOutcomes } from '../register/register.types';

enum Action {
  Register = 'Register',
  Activate = 'Activate',
}

@Component({
  selector: 'app-demo-home',
  templateUrl: './demo-home.component.html',
  styleUrls: ['./demo-home.component.css']
})
export class DemoHomeComponent implements OnInit {
  public brandDetails: BrandDetails;
  readonly canReset$: Observable<boolean>;

  action: Action = Action.Activate;

  constructor(private router: Router,
              private api: DemoApiService,
              private brandDetailsService: BrandDetailsService,
            ) {
    this.canReset$ = api.isDirty$;
  }

  ngOnInit() {
    this.brandDetails = this.brandDetailsService.getBrandDetails();
  }

  get privacyCheck() {
    return this.api.privacyCheck ? PrivacyCheckTypes[this.api.privacyCheck] : 'None'
  }
  set privacyCheck(value: string) {
    this.api.privacyCheck = value === 'None' ? null : PrivacyCheckTypes[value];
  }

  get registerOutcome() {
    return RegisterOutcomes[this.api.registerOutcome];
  }
  set registerOutcome(value: string) {
    this.api.registerOutcome = RegisterOutcomes[value];
  }

  go() {
    switch (this.action) {
      case Action.Activate:
        this.router.navigate(['/IXi1asfcrk65OVFoer66LA']);
        return;
      case Action.Register:
        this.router.navigate(['/register']);
        return;
    }
  }

  reset() {
    this.api.reset();
  }
}
