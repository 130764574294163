"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".content{padding-top:1em;padding-bottom:3em;background-color:#fff}@media only screen and (max-width:767px){.content .container{padding-left:0;padding-right:0}}.panel .panel-heading{color:#fff;background-color:#1d76a2}.panel .panel-heading .panel-title{font-weight:400;font-size:1.6em;line-height:1em}.panel .panel-body{padding-bottom:2em;background-color:#d4e1f5}@media only screen and (min-width:768px){.panel .panel-body,.panel .panel-heading{padding-left:2em;padding-right:2em}}form{margin-top:1em}"];
exports.styles = styles;
