"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CheckPrivacyResponse = /** @class */ (function () {
    function CheckPrivacyResponse(Status) {
        this.Status = Status;
    }
    return CheckPrivacyResponse;
}());
exports.CheckPrivacyResponse = CheckPrivacyResponse;
var ClientPrivacyDetails = /** @class */ (function () {
    function ClientPrivacyDetails() {
        this.surname = null;
        this.driversLicence = null;
        this.streetName = null;
        this.suburb = null;
        this.postcode = null;
        this.dateOfBirthDay = null;
        this.dateOfBirthMonth = null;
        this.dateOfBirthYear = null;
    }
    return ClientPrivacyDetails;
}());
exports.ClientPrivacyDetails = ClientPrivacyDetails;
var CheckPrivacyStatuses;
(function (CheckPrivacyStatuses) {
    CheckPrivacyStatuses["Accepted"] = "Accepted";
    CheckPrivacyStatuses["Rejected"] = "Rejected";
    CheckPrivacyStatuses["ActivationIdInvalid"] = "ActivationIdInvalid";
    CheckPrivacyStatuses["VerificationCodeInvalid"] = "VerificationCodeInvalid";
})(CheckPrivacyStatuses = exports.CheckPrivacyStatuses || (exports.CheckPrivacyStatuses = {}));
var PrivacyCheckTypes;
(function (PrivacyCheckTypes) {
    PrivacyCheckTypes["DriversLicence"] = "DriversLicence";
    PrivacyCheckTypes["Address"] = "Address";
    PrivacyCheckTypes["RequiresContact"] = "RequiresContact";
})(PrivacyCheckTypes = exports.PrivacyCheckTypes || (exports.PrivacyCheckTypes = {}));
