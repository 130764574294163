"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MaskedMobileResult = /** @class */ (function () {
    function MaskedMobileResult(hasError, maskedMobile) {
        this.hasError = hasError;
        this.maskedMobile = maskedMobile;
    }
    return MaskedMobileResult;
}());
exports.MaskedMobileResult = MaskedMobileResult;
var SendSmsResults;
(function (SendSmsResults) {
    SendSmsResults["Success"] = "Success";
    SendSmsResults["IncorrectMobileNumber"] = "IncorrectMobileNumber";
    SendSmsResults["Error"] = "Error";
})(SendSmsResults = exports.SendSmsResults || (exports.SendSmsResults = {}));
var ActivationStatuses;
(function (ActivationStatuses) {
    ActivationStatuses["Pending"] = "Pending";
    ActivationStatuses["Completed"] = "Completed";
    ActivationStatuses["NotFound"] = "NotFound";
    ActivationStatuses["Locked"] = "Locked";
    ActivationStatuses["RequiresContact"] = "RequiresContact";
})(ActivationStatuses = exports.ActivationStatuses || (exports.ActivationStatuses = {}));
