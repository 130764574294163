<div class="panel panel-default">
  <div class="panel-heading">
    <h1 class="panel-title">Account Successfully Activated</h1>
  </div>
  <div class="panel-body">

    <p>Congratulations {{firstName}}, you have successfully activated your online account.</p>
    <p>Your email to access your online account is <strong><span data-hj-masked>{{ logonName }}</span></strong></p>
    <p>We have sent you an email confirming this information. If you have not received this email, please contact our team on <a id="link-contact" href="tel:{{ brandDetails.phoneNumber }}" title="contact {{ brandDetails.shortName }}">{{ brandDetails.phoneNumber }}</a>.</p>
    <p>To access your online account, click below to login.</p>

    <button type="button" id="btn-redirect-online" class="btn btn-primary offset-mt-10 fx-fade-color"
      (click)="redirectToOnlineSite();">
        <span class="btn-label">Access your Online Account</span>
    </button>

  </div>
</div>
