"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var activation_store_service_1 = require("../../store/activation-store.service");
var brand_details_service_1 = require("../../brand/brand-details.service");
var CompletedComponent = /** @class */ (function () {
    function CompletedComponent(activationStore, router, brandDetailsService) {
        var _this = this;
        this.activationStore = activationStore;
        this.router = router;
        this.brandDetailsService = brandDetailsService;
        this.activationStore.changes
            .pipe(operators_1.pluck('firstName'))
            .subscribe(function (firstName) { return _this.firstName = firstName; });
        this.activationStore.changes
            .pipe(operators_1.pluck('logonName'))
            .subscribe(function (logonName) { return _this.logonName = logonName; });
        this.onlineAnalyticsTracking = '?utm_source=liberty&utm_medium=website&utm_content=activatedbtn&utm_campaign=activate';
    }
    CompletedComponent.prototype.ngOnInit = function () {
        if (!this.logonName) {
            this.router.navigate(['/error']);
        }
        // get brand details per brand
        this.brandDetails = this.brandDetailsService.getBrandDetails();
    };
    CompletedComponent.prototype.redirectToOnlineSite = function () {
        this.router.ngOnDestroy();
        window.open(this.brandDetails.onlineSiteUrl + this.onlineAnalyticsTracking);
    };
    return CompletedComponent;
}());
exports.CompletedComponent = CompletedComponent;
