"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var PasswordStrengthBar = /** @class */ (function () {
    // initialise defaults
    function PasswordStrengthBar() {
        // strength bar colours
        this.barDefaultColour = '#fff';
        this.barStrengthColours = ['#d9534f', '#f0ad4e', '#fcd15e', '#8cc63e', '#68c63e'];
        this.showStrengthLabel = true;
        this.strengthLabel = 'Password Strength';
    }
    // strength value
    PasswordStrengthBar.measureStrength = function (p) {
        var e_1, _a;
        var force = 0;
        var regex = /[$-/:-?{-~!"^_`\[\]]/g;
        var lowerLetters = /[a-z]+/.test(p);
        var upperLetters = /[A-Z]+/.test(p);
        var numbers = /[0-9]+/.test(p);
        var symbols = regex.test(p);
        var flags = [lowerLetters, upperLetters, numbers, symbols];
        var passedMatches = 0;
        try {
            for (var flags_1 = tslib_1.__values(flags), flags_1_1 = flags_1.next(); !flags_1_1.done; flags_1_1 = flags_1.next()) {
                var flag = flags_1_1.value;
                passedMatches += flag === true ? 1 : 0;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (flags_1_1 && !flags_1_1.done && (_a = flags_1.return)) _a.call(flags_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
        force += passedMatches * 10;
        // penality (short password)
        force = (p.length <= 6) ? Math.min(force, 10) : force;
        // penality (poor variety of characters)
        force = (passedMatches === 1) ? Math.min(force, 10) : force;
        force = (passedMatches === 2) ? Math.min(force, 20) : force;
        force = (passedMatches === 3) ? Math.min(force, 40) : force;
        return force;
    };
    // identify index
    PasswordStrengthBar.prototype.getColor = function (s) {
        var idx = 0;
        if (s <= 10) {
            idx = 0;
        }
        else if (s <= 20) {
            idx = 1;
        }
        else if (s <= 30) {
            idx = 2;
        }
        else if (s <= 40) {
            idx = 3;
        }
        else {
            idx = 4;
        }
        return {
            idx: idx + 1,
            col: this.barStrengthColours[idx]
        };
    };
    // identify colour
    PasswordStrengthBar.prototype.getStrengthIndexAndColor = function (password) {
        return this.getColor(PasswordStrengthBar.measureStrength(password));
    };
    // watcher
    PasswordStrengthBar.prototype.ngOnChanges = function (changes) {
        var password = changes['passwordToCheck'].currentValue;
        this.setBarColors(5, this.barDefaultColour);
        if (password) {
            var c = this.getStrengthIndexAndColor(password);
            this.setBarColors(c.idx, c.col);
        }
    };
    // colourise strength bar
    PasswordStrengthBar.prototype.setBarColors = function (count, col) {
        for (var n = 0; n < count; n++) {
            this['bar' + n] = col;
        }
    };
    return PasswordStrengthBar;
}());
exports.PasswordStrengthBar = PasswordStrengthBar;
