"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var demo_api_service_1 = require("../../shared/demo-api-service");
var DemoClientGoalsService = /** @class */ (function () {
    function DemoClientGoalsService(api) {
        this.api = api;
    }
    DemoClientGoalsService.prototype.postGoal = function (activationShortId, request) {
        var response = this.api.postGoal(activationShortId, request);
        return rxjs_1.of(response, rxjs_1.asapScheduler);
    };
    return DemoClientGoalsService;
}());
exports.DemoClientGoalsService = DemoClientGoalsService;
