"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("../../../environments/environment");
var SetupAccountService = /** @class */ (function () {
    function SetupAccountService(http) {
        this.http = http;
    }
    SetupAccountService.prototype.activateIdentityAccount = function (activationShortId, verificationCode, password, securityQuestion, securityAnswer) {
        return this.http.post(environment_1.environment.apiUrl + "activate-account/b2c/" + activationShortId, {
            verificationCode: verificationCode,
            password: password,
            securityQuestion: securityQuestion,
            securityAnswer: securityAnswer
        });
    };
    SetupAccountService.prototype.getSecurityQuestions = function () {
        return this.http.get(environment_1.environment.apiUrl + "activate-account/security-questions");
    };
    return SetupAccountService;
}());
exports.SetupAccountService = SetupAccountService;
