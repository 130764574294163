"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var send_code_component_1 = require("./pages/send/send-code.component");
var verify_code_component_1 = require("./pages/verify/verify-code.component");
var confirm_account_component_1 = require("./pages/confirm/confirm-account.component");
var setup_account_component_1 = require("./pages/setup/setup-account.component");
var client_goals_component_1 = require("./pages/client-goals/client-goals.component");
var completed_component_1 = require("./pages/completed/completed.component");
var contact_component_1 = require("./pages/contact/contact.component");
var error_component_1 = require("./pages/error/error.component");
var timeout_component_1 = require("./pages/timeout/timeout.component");
var register_component_1 = require("./pages/register/register.component");
var demo_home_component_1 = require("./pages/demo-home/demo-home.component");
var appRoutes = [
    { path: '', component: demo_home_component_1.DemoHomeComponent, pathMatch: 'full' },
    { path: 'error', component: error_component_1.ErrorComponent },
    { path: 'timeout', component: timeout_component_1.TimeoutComponent },
    { path: 'contact', component: contact_component_1.ContactComponent },
    { path: 'verify', component: verify_code_component_1.VerifyCodeComponent },
    { path: 'confirm', component: confirm_account_component_1.ConfirmAccountComponent },
    { path: 'setup', component: setup_account_component_1.SetupAccountComponent },
    { path: 'goal', component: client_goals_component_1.ClientGoalsComponent },
    { path: 'complete', component: completed_component_1.CompletedComponent },
    { path: 'register', component: register_component_1.RegisterComponent },
    { path: ':id', component: send_code_component_1.SendCodeComponent },
    { path: '**', redirectTo: 'error' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
exports.AppRoutingModule = AppRoutingModule;
