"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".header[_ngcontent-%COMP%]{background-color:#fff}.header[_ngcontent-%COMP%]   .header-logo[_ngcontent-%COMP%]{width:250px;height:auto;margin:10px auto}@media only screen and (min-width:768px){.header[_ngcontent-%COMP%]   .header-logo[_ngcontent-%COMP%]{margin-top:20px;margin-left:15px}}"];
exports.styles = styles;
