"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var brand_details_service_1 = require("../../brand/brand-details.service");
var activation_store_service_1 = require("../../store/activation-store.service");
var client_goals_service_1 = require("./client-goals.service");
var client_goals_types_1 = require("./client-goals.types");
var ClientGoalsComponent = /** @class */ (function () {
    function ClientGoalsComponent(router, activationStore, clientGoalsService, changeDetector, brandDetailsService) {
        var _this = this;
        this.router = router;
        this.activationStore = activationStore;
        this.clientGoalsService = clientGoalsService;
        this.changeDetector = changeDetector;
        this.brandDetailsService = brandDetailsService;
        this.activationStore.changes
            .pipe(operators_1.pluck('activationId'))
            .subscribe(function (activationId) { return _this.activationId = activationId; });
        this.activationStore.changes
            .pipe(operators_1.pluck('verificationCode'))
            .subscribe(function (verificationCode) { return _this.verificationCode = verificationCode; });
        this.enteredGoal = '';
        this.formSubmitted = false;
    }
    ClientGoalsComponent.prototype.ngOnInit = function () {
        if (!this.activationId || !this.verificationCode) {
            this.router.navigate(['/error']);
        }
        ;
        this.brandDetails = this.brandDetailsService.getBrandDetails();
    };
    ClientGoalsComponent.prototype.onSubmit = function () {
        var _this = this;
        this.formSubmitted = true;
        this.changeDetector.detectChanges();
        var clientGoalsRequest = new client_goals_types_1.ClientGoalsRequest(this.verificationCode, this.enteredGoal);
        this.clientGoalsService.postGoal(this.activationId, clientGoalsRequest)
            .subscribe(function (clientGoalsResponse) {
            console.log(clientGoalsResponse.Success);
            if (clientGoalsResponse.Success === true) {
                _this.router.navigate(['/complete']);
            }
            else {
                _this.router.navigate(['/error']);
            }
        }, function (error) {
            _this.router.navigate(['/timeout']);
        });
    };
    return ClientGoalsComponent;
}());
exports.ClientGoalsComponent = ClientGoalsComponent;
