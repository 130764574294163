"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var brand_details_service_1 = require("../../brand/brand-details.service");
var ErrorComponent = /** @class */ (function () {
    function ErrorComponent(bandDetailsService) {
        this.bandDetailsService = bandDetailsService;
    }
    ErrorComponent.prototype.ngOnInit = function () {
        // get brand details per brand
        this.brandDetails = this.bandDetailsService.getBrandDetails();
    };
    return ErrorComponent;
}());
exports.ErrorComponent = ErrorComponent;
