"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ActivateAccountResponse = /** @class */ (function () {
    function ActivateAccountResponse(Status, LogonName) {
        this.Status = Status;
        this.LogonName = LogonName;
    }
    return ActivateAccountResponse;
}());
exports.ActivateAccountResponse = ActivateAccountResponse;
var ActivateAccountResponseStatuses;
(function (ActivateAccountResponseStatuses) {
    ActivateAccountResponseStatuses["Activated"] = "Activated";
    ActivateAccountResponseStatuses["ActivationIdInvalid"] = "ActivationIdInvalid";
    ActivateAccountResponseStatuses["VerificationCodeInvalid"] = "VerificationCodeInvalid";
    ActivateAccountResponseStatuses["ActivationFailedInB2C"] = "ActivationFailedInB2C";
})(ActivateAccountResponseStatuses = exports.ActivateAccountResponseStatuses || (exports.ActivateAccountResponseStatuses = {}));
var SecurityQuestions;
(function (SecurityQuestions) {
    SecurityQuestions["MothersMaidenName"] = "MothersMaidenName";
    SecurityQuestions["AnniversaryDate"] = "AnniversaryDate";
    SecurityQuestions["PetsName"] = "PetsName";
    SecurityQuestions["BestFriendsName"] = "BestFriendsName";
    SecurityQuestions["FavouriteMovie"] = "FavouriteMovie";
    SecurityQuestions["FavouriteColour"] = "FavouriteColour";
    SecurityQuestions["FathersName"] = "FathersName";
    SecurityQuestions["CityOfBirth"] = "CityOfBirth";
})(SecurityQuestions = exports.SecurityQuestions || (exports.SecurityQuestions = {}));
var SecurityQuestionSelection = /** @class */ (function () {
    function SecurityQuestionSelection() {
    }
    return SecurityQuestionSelection;
}());
exports.SecurityQuestionSelection = SecurityQuestionSelection;
