import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { Injectable, Inject } from '@angular/core';
import { Brand } from '../brand/brand';
import { defaultBrandDetails, detailsByBrand } from './brand-details.data';
import { BrandDetails } from './brand-details.model';



@Injectable()
export class BrandDetailsService {

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public getBrandDetails(): BrandDetails {
    let brand = Brand.LibertyAu

    if (environment.overrideBrand)
      brand = environment.overrideBrand;

    let details = detailsByBrand.get(brand);
    if (!details) {
      throw new Error(`Unrecognised brand ${brand}`);
    }

    for (const detailByBrand of detailsByBrand.values()) {
      if (this.document.location.hostname.endsWith(detailByBrand.hostName)) {
        return detailByBrand;
      }
    }

    return defaultBrandDetails;
  }
}

@Injectable()
export class BrandDetailsServiceAUStub {
  public getBrandDetails(brand: Brand): BrandDetails { return detailsByBrand.get(brand); }
}

@Injectable()
export class BrandDetailsServiceNZStub {
  public getBrandDetails(brand: Brand): BrandDetails { return detailsByBrand.get(brand); }
}
