"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var activation_store_service_1 = require("../../store/activation-store.service");
var confirm_account_service_1 = require("./confirm-account.service");
var confirm_account_types_1 = require("./confirm-account.types");
var confirm_account_types_2 = require("./confirm-account.types");
var confirm_account_types_3 = require("./confirm-account.types");
var ConfirmAccountComponent = /** @class */ (function () {
    function ConfirmAccountComponent(router, activationStore, confirmAccountService, changeDetector) {
        var _this = this;
        this.router = router;
        this.activationStore = activationStore;
        this.confirmAccountService = confirmAccountService;
        this.changeDetector = changeDetector;
        this.activationStore.changes
            .pipe(operators_1.pluck('activationId'))
            .subscribe(function (activationId) { return _this.activationId = activationId; });
        this.activationStore.changes
            .pipe(operators_1.pluck('verificationCode'))
            .subscribe(function (verificationCode) { return _this.verificationCode = verificationCode; });
        this.activationStore.changes
            .pipe(operators_1.pluck('firstName'))
            .subscribe(function (firstName) { return _this.firstName = firstName; });
        this.formSubmitted = false;
        this.hasDriversLicence = true;
        this.privacyCheckTypeLoaded = false;
        this.minimumAge = 18;
        this.maximumAge = 110;
        this.numberOfDays = 31;
        this.enteredPrivacyDetails = new confirm_account_types_3.ClientPrivacyDetails();
    }
    ConfirmAccountComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.verificationCode || !this.activationId) {
            this.router.navigate(['/error']);
        }
        else {
            this.confirmAccountService.getPrivacyCheckType(this.activationId)
                .subscribe(function (privacyCheckType) {
                switch (privacyCheckType) {
                    case confirm_account_types_2.PrivacyCheckTypes.DriversLicence:
                        _this.hasDriversLicence = true;
                        break;
                    case confirm_account_types_2.PrivacyCheckTypes.Address:
                        _this.hasDriversLicence = false;
                        break;
                    case confirm_account_types_2.PrivacyCheckTypes.RequiresContact:
                        _this.router.navigate(['/contact']);
                        break;
                    default:
                        _this.router.navigate(['/error']);
                        break;
                }
                _this.privacyCheckTypeLoaded = true;
            });
            this.calendarDays = this.calculateDays();
            this.calendarMonths = [
                { id: 1, name: 'January' },
                { id: 2, name: 'February' },
                { id: 3, name: 'March' },
                { id: 4, name: 'April' },
                { id: 5, name: 'May' },
                { id: 6, name: 'June' },
                { id: 7, name: 'July' },
                { id: 8, name: 'August' },
                { id: 9, name: 'September' },
                { id: 10, name: 'October' },
                { id: 11, name: 'November' },
                { id: 12, name: 'December' }
            ];
            this.calendarYears = this.calculateYears();
        }
    };
    ConfirmAccountComponent.prototype.onSubmit = function () {
        var _this = this;
        this.formSubmitted = true;
        this.changeDetector.detectChanges();
        this.confirmAccountService.postPrivacyCheck(this.activationId, this.verificationCode, this.enteredPrivacyDetails)
            .subscribe(function (response) {
            if (response.Status === confirm_account_types_1.CheckPrivacyStatuses.Accepted) {
                _this.router.navigate(['/setup']);
            }
            else if (response.Status === confirm_account_types_1.CheckPrivacyStatuses.Rejected) {
                _this.router.navigate(['/contact']);
            }
            else {
                _this.router.navigate(['/error']);
            }
        }, function (error) {
            _this.router.navigate(['/timeout']);
        });
    };
    ConfirmAccountComponent.prototype.calculateDays = function () {
        var days = [];
        for (var i = 1; i <= this.numberOfDays; i++) {
            days.push(i);
        }
        return days;
    };
    ConfirmAccountComponent.prototype.calculateYears = function () {
        var years = [];
        var date = new Date();
        var initialYear = date.getFullYear() - this.maximumAge;
        var endingYear = date.getFullYear() - this.minimumAge;
        for (var i = endingYear; i >= initialYear; i--) {
            years.push(i);
        }
        return years;
    };
    return ConfirmAccountComponent;
}());
exports.ConfirmAccountComponent = ConfirmAccountComponent;
