"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./contact.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./contact.component");
var i3 = require("../../brand/brand-details.service");
var styles_ContactComponent = [i0.styles];
var RenderType_ContactComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactComponent, data: {} });
exports.RenderType_ContactComponent = RenderType_ContactComponent;
function View_ContactComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "panel panel-default"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "panel-heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "panel-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirmation Required"])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "panel-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You\u2019re almost there! To protect your account, we just need a little more information to confirm it's really you."])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please contact us on "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["id", "link-contact"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", ""])), (_l()(), i1.ɵted(-1, null, [" to complete. You may be required to supply some personal details to confirm your identity."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "tel:", _co.brandDetails.phoneNumber, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "contact ", _co.brandDetails.shortName, ""); _ck(_v, 9, 0, currVal_0, currVal_1); var currVal_2 = _co.brandDetails.phoneNumber; _ck(_v, 10, 0, currVal_2); }); }
exports.View_ContactComponent_0 = View_ContactComponent_0;
function View_ContactComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ContactComponent_0, RenderType_ContactComponent)), i1.ɵdid(1, 114688, null, 0, i2.ContactComponent, [i3.BrandDetailsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ContactComponent_Host_0 = View_ContactComponent_Host_0;
var ContactComponentNgFactory = i1.ɵccf("ng-component", i2.ContactComponent, View_ContactComponent_Host_0, {}, {}, []);
exports.ContactComponentNgFactory = ContactComponentNgFactory;
