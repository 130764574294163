export class ActivateAccountResponse {
    constructor(
        public Status: ActivateAccountResponseStatuses,
        public LogonName: string
    ) {}
}

export enum ActivateAccountResponseStatuses {
    Activated = 'Activated',
    ActivationIdInvalid = 'ActivationIdInvalid',
    VerificationCodeInvalid = 'VerificationCodeInvalid',
    ActivationFailedInB2C = 'ActivationFailedInB2C',
}

export enum SecurityQuestions {
    MothersMaidenName = 'MothersMaidenName',
    AnniversaryDate = 'AnniversaryDate',
    PetsName = 'PetsName',
    BestFriendsName = 'BestFriendsName',
    FavouriteMovie = 'FavouriteMovie',
    FavouriteColour = 'FavouriteColour',
    FathersName = 'FathersName',
    CityOfBirth = 'CityOfBirth'
}

export class SecurityQuestionSelection {
    SecurityQuestion: SecurityQuestions;
    Text: string;
}
