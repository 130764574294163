"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ValidateVerificationCodeResult = /** @class */ (function () {
    function ValidateVerificationCodeResult(ValidateResult) {
        this.ValidateResult = ValidateResult;
    }
    return ValidateVerificationCodeResult;
}());
exports.ValidateVerificationCodeResult = ValidateVerificationCodeResult;
var ValidateResults;
(function (ValidateResults) {
    ValidateResults["ValidVerificationCode"] = "ValidVerificationCode";
    ValidateResults["InvalidVerificationCode"] = "InvalidVerificationCode";
    ValidateResults["InvalidActivationId"] = "InvalidActivationId";
    ValidateResults["PrivacyCheckRequired"] = "PrivacyCheckRequired";
})(ValidateResults = exports.ValidateResults || (exports.ValidateResults = {}));
var SendSmsResults;
(function (SendSmsResults) {
    SendSmsResults["Success"] = "Success";
    SendSmsResults["IncorrectMobileNumber"] = "IncorrectMobileNumber";
    SendSmsResults["Error"] = "Error";
})(SendSmsResults = exports.SendSmsResults || (exports.SendSmsResults = {}));
