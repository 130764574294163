<div id="js-header" class="header">
  <mat-toolbar color="accent">
    <mat-toolbar-row>
      <span><a style="color: white" [routerLink]="['/']">Demo</a></span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
        <div class="header-logo">
          <img src="../../../assets/images/logo-liberty-financial.png" alt="liberty financial" class="img-responsive">
        </div>
      </div>
    </div>
  </div>
</div>
