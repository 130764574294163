"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".footer[_ngcontent-%COMP%]{padding-top:2em;padding-bottom:2em;color:#fff;background-color:#0a3371}.footer[_ngcontent-%COMP%]   .footer-contact[_ngcontent-%COMP%], .footer[_ngcontent-%COMP%]   .footer-legal[_ngcontent-%COMP%]{text-align:center}.footer[_ngcontent-%COMP%]   .footer-legal[_ngcontent-%COMP%]{padding-top:1em}.footer[_ngcontent-%COMP%]   .footer-legal[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{padding:0;color:#fff;font-size:1rem}@media only screen and (min-width:768px){.footer[_ngcontent-%COMP%]   .footer-legal[_ngcontent-%COMP%]{padding-top:0;text-align:left}.footer[_ngcontent-%COMP%]   .footer-contact[_ngcontent-%COMP%]{text-align:right}}.footer[_ngcontent-%COMP%]   .footer-contact[_ngcontent-%COMP%]   .contact-number[_ngcontent-%COMP%]{padding:0;font-size:2.8rem;line-height:1;font-weight:400}.footer[_ngcontent-%COMP%]   .footer-contact[_ngcontent-%COMP%]   .contact-number[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{color:#fff;text-decoration:none}.footer[_ngcontent-%COMP%]   .footer-contact[_ngcontent-%COMP%]   .contact-icon[_ngcontent-%COMP%]{width:26px;height:auto;margin-top:-6px}"];
exports.styles = styles;
