"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function NoWhiteSpaceValidator() {
    return function (c) {
        var isWhitespace = (c.value || '').trim().length === 0;
        var isValid = !isWhitespace;
        return isValid ? null : { 'noWhiteSpace': 'value is only whitespace' };
    };
}
exports.NoWhiteSpaceValidator = NoWhiteSpaceValidator;
