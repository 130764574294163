import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { pluck } from 'rxjs/operators';

import { ActivationStore } from '../../store/activation-store.service';
import { VerifyCodeService } from './verify-code.service';
import { ValidateResults } from './verify-code.types';
import { SendSmsResults } from './verify-code.types';

import { fadeInOut } from '../../animations/fade-in-out.animation';
import { environment } from '../../../environments/environment';

@Component({
  templateUrl: './verify-code.component.html',
  styleUrls: [ './verify-code.component.less' ],
  providers: [ActivationStore],
  animations: [
    [fadeInOut]
  ]
})
export class VerifyCodeComponent implements OnInit {

  private activationId: string;
  public enteredVerificationCode: string;
  public invalidCodeSubmitted: boolean;
  public formSubmitted: boolean;
  public mobileNumber: string;
  public showResendCode: boolean;
  public resendVerificationCodeInvalid: boolean;

  public maskVerificationCode = [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, '-', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/];

  constructor(private router: Router,
              private activationStore: ActivationStore,
              private verifyCodeService: VerifyCodeService,
              private changeDetector: ChangeDetectorRef) {

    this.activationStore.changes
      .pipe(pluck('activationId'))
      .subscribe((activationId: string) => this.activationId = activationId);

    this.activationStore.changes
      .pipe(pluck('mobileNumber'))
      .subscribe((mobileNumber: string) => this.mobileNumber = mobileNumber);

    this.invalidCodeSubmitted = false;
    this.formSubmitted = false;
    this.showResendCode = false;
    this.resendVerificationCodeInvalid = false;

    setTimeout(() => {
            this.showResendCode = true;
    }, environment.resendShowAfterSeconds * 1000);
  }

  ngOnInit() {
    if (!this.activationId) {
      this.router.navigate(['/error']);
    }
  }

  onSubmit() {
        this.invalidCodeSubmitted = false;
        this.formSubmitted = true;
        this.changeDetector.detectChanges();
        let verificationCode = this.enteredVerificationCode.trim().replace(/\-+/g, '').toUpperCase();

        this.verifyCodeService.validateVerificationCode(this.activationId, verificationCode)
          .subscribe(validateResult => {
            let status = validateResult.ValidateResult;
            if (status === ValidateResults.ValidVerificationCode) {
                this.invalidCodeSubmitted = false;

                const currentState = this.activationStore.getState();
                this.activationStore.setState(Object.assign({}, currentState, { verificationCode: verificationCode }));

                this.router.navigate(['/setup']);

            } else if (status === ValidateResults.PrivacyCheckRequired) {
                this.invalidCodeSubmitted = false;

                const currentState = this.activationStore.getState();
                this.activationStore.setState(Object.assign({}, currentState, { verificationCode: verificationCode }));

                this.router.navigate(['/confirm']);

            } else if (status === ValidateResults.InvalidVerificationCode) {
                this.invalidCodeSubmitted = true;
                this.formSubmitted = false;
            } else {
                this.router.navigate(['/error']);
            }
        },
        error => {
          this.router.navigate(['/timeout']);
        });
    }

    resendCode() {
        this.verifyCodeService.resendVerificationCode(this.activationId, this.mobileNumber)
          .subscribe(sendResult => {
            if (sendResult === SendSmsResults.Success) {
                this.showResendCode = false;

                setTimeout(() => {
                        this.showResendCode = true;
                }, environment.resendShowAfterSeconds * 1000);
            } else {
                this.showResendCode = false;
                this.resendVerificationCodeInvalid = true;
            }
        },
        error => {
          this.router.navigate(['/error']);
        });
    }

    returnToVerifyMobile() {
        this.router.navigate(['/' + this.activationId]);
    }
}
