"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var forms_1 = require("@angular/forms");
var no_whitespace_validator_1 = require("./no-whitespace.validator");
var NoWhiteSpaceDirective = /** @class */ (function () {
    function NoWhiteSpaceDirective() {
        this.ValidatorResult = no_whitespace_validator_1.NoWhiteSpaceValidator();
    }
    NoWhiteSpaceDirective.prototype.validate = function (c) {
        return this.ValidatorResult(c);
    };
    return NoWhiteSpaceDirective;
}());
exports.NoWhiteSpaceDirective = NoWhiteSpaceDirective;
