"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var demo_api_service_1 = require("../../shared/demo-api-service");
var DemoRegisterService = /** @class */ (function () {
    function DemoRegisterService(api) {
        this.api = api;
    }
    DemoRegisterService.prototype.postRegister = function (registerDetails) {
        var response = this.api.postRegister(registerDetails);
        return rxjs_1.of(response, rxjs_1.asapScheduler);
    };
    return DemoRegisterService;
}());
exports.DemoRegisterService = DemoRegisterService;
