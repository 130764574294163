import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

interface State {
    activationId: string;
    firstName: string;
    mobileNumber: string;
    verificationCode: string;
    logonName: string;
}

const state = {
    activationId: '',
    firstName: '',
    mobileNumber: '',
    verificationCode: '',
    logonName: ''
};

const store = new BehaviorSubject<any>(state);

@Injectable()
export class ActivationStore {

    store = store;
    changes = store.asObservable()
      .pipe(distinctUntilChanged());

    getState() {
        return this.store.value;
    }

    setState(newState: State) {
        this.store.next(newState);
    }
}
