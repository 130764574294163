<div id="js-footer" class="footer" role="contentinfo">
  <div class="container">
    <div class="row">

      <!-- contact -->
      <div class="col-xs-12 col-sm-4 col-sm-push-7 col-md-4 col-md-push-6">
        <div class="footer-contact">
          <div class="contact-number">
            <img class="contact-icon" src="../../../assets/images/icon-phone-x2.png" alt="contact {{ brandDetails.shortName }}">
            <a href="tel:{{ brandDetails.phoneNumber }}" title="contact {{ brandDetails.shortName }}"> {{ brandDetails.phoneNumber }}</a>
          </div>
        </div>
      </div>
      <!-- END: contact -->

      <!-- legal -->
      <div class="col-xs-12 col-sm-6 col-sm-pull-4 col-sm-offset-1 col-md-5 col-md-pull-4 col-md-offset-2">
        <div class="footer-legal">
          <p>{{ brandDetails.footer }}</p>
        </div>
      </div>
      <!-- END: legal -->

    </div>
  </div>
</div>
