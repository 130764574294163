"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Sentry = require("@sentry/browser");
var environment_1 = require("../../environments/environment");
var app_version_1 = require("../app.version");
var SentryService = /** @class */ (function () {
    function SentryService() {
    }
    SentryService.prototype.init = function () {
        if (this.isInitialized || !this.isEnabled) {
            return;
        }
        Sentry.init({
            dsn: environment_1.environment.sentry.dsn,
            release: app_version_1.appVersion,
            environment: environment_1.environment.envName,
        });
        this.isInitialized = true;
    };
    Object.defineProperty(SentryService.prototype, "isEnabled", {
        get: function () {
            return environment_1.environment.sentry.dsn != null;
        },
        enumerable: true,
        configurable: true
    });
    return SentryService;
}());
exports.SentryService = SentryService;
