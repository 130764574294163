"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("../../../environments/environment");
var ClientGoalsService = /** @class */ (function () {
    function ClientGoalsService(http) {
        this.http = http;
    }
    ClientGoalsService.prototype.postGoal = function (activationShortId, clientGoalsRequest) {
        return this.http.post(environment_1.environment.apiUrl + "client-goals/" + activationShortId, clientGoalsRequest);
    };
    return ClientGoalsService;
}());
exports.ClientGoalsService = ClientGoalsService;
