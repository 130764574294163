<main>

  <demo-header></demo-header>

  <!-- content pane -->
  <div id="js-content-pane" class="content" role="main">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 ">

          <router-outlet></router-outlet>

        </div>
      </div>
    </div>
  </div>
  <!-- END: content pane -->

  <footer></footer>

</main>
