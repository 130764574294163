import * as Sentry from '@sentry/browser';

import { environment } from '../../environments/environment';
import { appVersion } from '../app.version';

export class SentryService {
  private isInitialized: boolean;

  constructor() {
  }

  init() {
    if (this.isInitialized || !this.isEnabled) {
      return;
    }

    Sentry.init({
      dsn: environment.sentry.dsn,
      release: appVersion,
      environment: environment.envName,
    });

    this.isInitialized = true;
  }

  get isEnabled(): boolean {
    return environment.sentry.dsn != null;
  }
}
