import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CheckPrivacyResponse } from './confirm-account.types';
import { ClientPrivacyDetails } from './confirm-account.types';
import { PrivacyCheckTypes } from './confirm-account.types';
import { environment } from '../../../environments/environment';

@Injectable()
export class ConfirmAccountService {

    constructor (private http: HttpClient) {}

    getPrivacyCheckType(activationShortId: string): Observable<PrivacyCheckTypes> {
        return this.http.get<PrivacyCheckTypes>(
            `${environment.apiUrl}check-privacy/${activationShortId}/type`
        );
    }

    postPrivacyCheck(activationShortId: string, verificationCode: string, privacyDetails: ClientPrivacyDetails):
      Observable<CheckPrivacyResponse> {
        return this.http.post<CheckPrivacyResponse>(
            `${environment.apiUrl}check-privacy/${activationShortId}`,
            {
                VerificationCode: verificationCode,
                PrivacyDetails: privacyDetails
            }
        );
    }
}
